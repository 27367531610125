import React, { FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { DocViewerContext } from "../store/DocViewerProvider";

interface Props {
  // Define your component props here
  // For example:
  children: React.ReactNode;
}
export const LoadingTimeout: FC<PropsWithChildren<Props>> = ({ children }) => {
  const { state } = useContext(DocViewerContext);
  const { config } = state;
  const [shouldLoadingRender, setShouldLoadingRender] = useState(
    config?.loadingRenderer?.showLoadingTimeout === false
  );

  useEffect(() => {
    setTimeout(
      () => {
        setShouldLoadingRender(true);
      },
      typeof config?.loadingRenderer?.showLoadingTimeout === "number"
        ? config.loadingRenderer.showLoadingTimeout
        : 500
    );
  }, [config?.loadingRenderer?.showLoadingTimeout]);

  if (!shouldLoadingRender) {
    return null;
  }

  return <>{children}</>;
};
