/* eslint-disable react/jsx-no-undef */
import { useMsal } from "@azure/msal-react";
import { Box, Button, IconButton, Snackbar, Typography } from "@mui/material";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import {
  MRT_ColumnDef,
  MRT_TableOptions,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import EditAction from "../Actions/EditAction";
import AddAction from "../Actions/AddAction";
import ConfirmDialog from "../../helpers/dialog/ConfirmDialog";
import { jsPDF } from "jspdf"; //or use your library of choice here
import autoTable from "jspdf-autotable";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import { ReactComponent as Export } from "../../assets/export_notes.svg";
import { ReactComponent as Filter } from "../../assets/filter_alt.svg";
import { ReactComponent as MoreOption } from "../../assets/more_vert.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink, CSVDownload } from "react-csv";
import ViewAction from "../Actions/ViewAction";
import { loginRequest, b2cPolicies, deployment } from "../../authConfig";

const queryClient = new QueryClient();
export const OrgList = () => {
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const appTenantId = (accounts[0].idTokenClaims as IdTokenClaims).appTenantId;
  const myUserId = (accounts[0].idTokenClaims as IdTokenClaims).sub;
  const [users, setUsers] = React.useState<Member[]>([]);
  const [selectedRowId, setSelectedRowId] = useState<string>("");
  const [selectedViewRowId, setSelectedViewRowId] = useState<string>("");
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(false);
  const isAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes("Tenant.admin");
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [deleteRowId, setDeleteRowId] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>("");
  const [viewActions, setViewActions] = useState<string>(isAdmin ? "A" : "M");
  const [adoptedPolicies, setAdoptedPolicies] = useState<any[]>([]);

  const handleItemClick = (index: string, name: string) => {
    // Toggle the selected item index
    setSelectedRowId(index);
    console.log("selectedRowId", index);
    instance.setActiveAccount(null);
    instance.loginRedirect({
      authority: b2cPolicies.authorities.signIn.authority,
      scopes: loginRequest.scopes,
      redirectUri: deployment.baseUrl + "/loggedin",
      account: accounts[0],
      extraQueryParameters: { tenant: name, tenantNameId: index },
    });
  };
  useEffect(() => {
    //refetchActions();
  }, []);
  // Function to parse the date string
  const parseDate = (dateString: string): Date => {
    const [datePart, timePart] = dateString.split(", ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [time, period] = timePart.split(" ");
    let [hours, minutes, seconds] = time.split(":").map(Number);

    // Convert 12-hour time to 24-hour time
    if (period.toLowerCase() === "pm" && hours !== 12) {
      hours += 12;
    } else if (period.toLowerCase() === "am" && hours === 12) {
      hours = 0;
    }

    return new Date(year, month - 1, day, hours, minutes, seconds);
  };
  function useAllActions() {
    return useQuery<Organization[]>({
      queryKey: ["actions", appTenantId, myUserId, viewActions],
      queryFn: async ({ queryKey }) => {
        //send api request here
        // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
        const [_, appTenantId, myUserId, viewActions] = queryKey;
        const orgListResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/organisation/all/getListOrganisations`
        );
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersAcrossOrg`
        );
        setUsers((prevValue: Member[]) => [...prevValue, ...userResponse.data]);

        // Convert creationDate to Date and sort
        const sortedOrganisations = orgListResponse.data
          .map((org: Organization) => ({
            ...org,
            creationDate: parseDate(org.creationDate),
          }))
          .sort((a: any, b: any) => a.creationDate.getTime() - b.creationDate.getTime());

        // Return the sorted list
        console.log("sortedOrganisations", sortedOrganisations);
        return sortedOrganisations;
      },
      refetchOnWindowFocus: true,
      staleTime: 0,
      refetchInterval: 0,
    });
  }

  const {
    data: fetchedActions = [],
    isError: isLoadingActionsError,
    isFetching: isFetchingActions,
    isLoading: isLoadingActions,
    refetch: refetchActions,
  } = useAllActions();

  console.log("fetchedActions", fetchedActions);

  const columns = useMemo<MRT_ColumnDef<Organization>[]>(
    () => [
      {
        accessorKey: "tenantId", //access nested data with dot notation
        header: "Tenant Id",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "pre-wrap",
            }}
            onClick={() => setSelectedViewRowId(row.original.tenantId)}
          >
            <span>{row.original.tenantId}</span>
          </Box>
        ),
      },
      {
        accessorKey: "name", //access nested data with dot notation
        header: "Name",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "pre-wrap",
            }}
          >
            <span>{row.original.name}</span>
          </Box>
        ),
      },
      {
        accessorKey: "mainContactName",
        header: "Main Contact",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>
              {row.original.mainContactName
                ? users?.find((user) => user.userId === row.original.mainContactName)?.givenName +
                  " " +
                  users?.find((user) => user.userId === row.original.mainContactName)?.surname
                : ""}
            </span>
          </Box>
        ),
      },
      {
        accessorKey: "creationDate",
        header: "Created ON",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>{new Date(row.original.creationDate).toLocaleDateString("en-AU")}</span>
          </Box>
        ),
      },
    ],
    [users]
  );
  const handleClose = () => {
    console.log("Called onclose");
    setSelectedRowId("");
    setSelectedViewRowId("");
    setShowAdd(false);
    refetchActions();
    //setAlertText("Action added/edited successfully");
    //setShowAlert(true);
  };

  // action filter
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const filterOpen = Boolean(anchorEl);
  const [selectedView, setSelectedView] = useState(isAdmin ? "All Actions" : "My Actions");
  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = (view: React.SetStateAction<string>) => {
    setSelectedView(view);

    if (view === "My Actions") {
      setViewActions("M");
    } else if (view === "All Actions") {
      setViewActions("A");
    }
    refetchActions();
    setAnchorEl(null);
  };

  // delete action
  const [anchordEl, setAnchordEl] = React.useState<null | HTMLElement>(null);
  const deleteOpen = Boolean(anchordEl);

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchordEl(event.currentTarget);
  };

  const handleDeleteClose = () => {
    setAnchordEl(null);
  };
  const table = useMaterialReactTable({
    columns,
    data: fetchedActions, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: true,
    positionActionsColumn: "last",

    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default,
      boxShadow: "none !important",
      borderRadius: "none !important",
    }),
    muiTableBodyRowProps: {
      hover: false,
      sx: {
        whiteSpace: "nowrap",
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "none !important",
        // width: "1187px",
        // minWidth: "1187px",
      },
    },
    muiTableProps: {
      sx: {
        // border: "1px solid rgba(81, 81, 81, .5)",
        boxShadow: "none !important",
        whiteSpace: "nowrap",
        borderRadius: "none !important",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        border: "1px solid #EDEDED",
        // borderLeft: "1px solid rgba(81, 81, 81, .5)",
        // borderTop: "1px solid rgba(81, 81, 81, .5)",
        // fontStyle: "italic",
        fontWeight: "bold",
        fontSize: "14px",
        whiteSpace: "nowrap",
        textAlign: "center",
        boxShadow: "none",
        paddingLeft: "20px",
        backgroundColor: "#FBFBFF",
        color: "#111827 60%",
        height: "50px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderBottom: "1px solid rgba(81, 81, 81, .5)",
        // whiteSpace: "pre-wrap",
        // paddingLeft: "20px",
        // paddingRight: "20px",
        paddingTop: "10px",
        paddingBottom: "10px",
        border: "1px solid #EDEDED",
        // borderLeft: columns.accessorKey === "status" ? "none" : "1px solid #EDEDED",
      },
    },
    state: {
      isLoading: isLoadingActions,
      showAlertBanner: isLoadingActionsError,
      showProgressBars: isFetchingActions,
    },
    initialState: {
      sorting: [
        {
          id: "creationDate", //sort by age by default on page load
          desc: true,
        },
      ],
      density: "compact",
    },
    enableRowActions: true,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "Actions", //change "Actions" to "Edit"
        size: 10,
        //use a text button instead of a icon button
        Cell: ({ row, table }) => (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Button
              variant="contained"
              onClick={() => {
                handleItemClick(row.original.tenantId, row.original.name);
              }}
              sx={{ width: "122px", height: "35px" }}
            >
              Switch
            </Button>
          </div>
        ),
      },
    },
  });

  const account = instance.getActiveAccount();
  return (
    <div>
      {showAlert && (
        <Snackbar
          open={showAlert}
          autoHideDuration={5000}
          onClose={() => setShowAlert(false)}
          message={alertText}
        />
      )}
      <MaterialReactTable table={table} />
    </div>
  );
};

const OrgListProvider: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <OrgList />
    </QueryClientProvider>
  );
};

export default OrgListProvider;
