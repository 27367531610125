import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { ReactComponent as ArrowUp } from "../../assets/keyboard_arrow_up.svg";
import { ReactComponent as ArrowDown } from "../../assets/keyboard_arrow_down.svg";
import { ReactComponent as Calendar } from "../../assets/calendar_month.svg";
import { ReactComponent as Filter } from "../../assets/filter_alt.svg";
import "./workflow.css";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import { useMsal } from "@azure/msal-react";
import ViewAction from "../Actions/ViewAction";

const queryClient = new QueryClient();
interface Filter {
  name: string;
  color: string;
}

interface Status {
  code: string;
  label: string;
  color: string;
}
const status: Status[] = [
  { code: "N", label: "Not Started", color: "#C2CAFF" },
  /* { code: "P", label: "Pending", color: "#FF9800" }, */
  { code: "U", label: "Not Assigned", color: "#FF9900" },
  { code: "I", label: "In Progress", color: "#49AEEE" },
  { code: "C", label: "Cancelled", color: "#555555" },
  { code: "O", label: "Overdue", color: "#FF6666" },
  { code: "H", label: "On Hold", color: "#B9B9B9" },
  { code: "D", label: "Done", color: "#23AF00" },
];
const categories: Status[] = [
  { code: "G", label: "Govern", color: "#0263FF" },
  { code: "P", label: "Protect", color: "#00108B" },
  { code: "D", label: "Detect", color: "#49AEEE" },
  { code: "R", label: "Respond", color: "#3D74A7" },
  { code: "N", label: "Manual", color: "#8c9eff" },
];
export const ActionChartDetail = () => {
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const appTenantId = (accounts[0].idTokenClaims as IdTokenClaims).appTenantId;
  const myUserId = (accounts[0].idTokenClaims as IdTokenClaims).sub;
  const isAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes("Tenant.admin");
  const isGlobalAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles[0] === "GlobalAdmin";
  const [viewActions, setViewActions] = useState<string>(isAdmin || isGlobalAdmin ? "A" : "M");
  const [quarterActions, setQuarterActions] = useState<
    { month: string; actions: PolicyActionEntity[] }[]
  >([]);
  const [selectedRowId, setSelectedRowId] = useState("");
  const handleClose = () => {
    setSelectedRowId(""); // Reset selectedRowId when the EditAction component is closed
  };
  function useAllActions() {
    return useQuery<PolicyActionEntity[]>({
      queryKey: ["actions", appTenantId, myUserId, viewActions],
      queryFn: async ({ queryKey }) => {
        //send api request here
        // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
        const [_, appTenantId, myUserId, viewActions] = queryKey;
        const policyActionsResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/actions/${appTenantId}`
        );
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/` + appTenantId
        );
        // setUsers((prevValue: Member[]) => [...prevValue, ...userResponse.data]);

        const userId = userResponse.data?.find(
          (user: { userId: string }) => user.userId === myUserId
        )?.userId;
        //if view my action is M then filter the actions by owner
        console.log("my user id", userId, viewActions);
        // Assuming policyActionsResponse.data is an array of objects containing actions
        policyActionsResponse.data.forEach((action: PolicyActionEntity) => {
          // Check if action due date is less than today and status is not 'D' or 'C'
          if (
            new Date(action.dueDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) &&
            action.status !== "D" &&
            action.status !== "C"
          ) {
            // Update status to 'O'
            action.status = "O";
          }
        });

        // Now policyActionsResponse.data contains the updated actions with status updated to 'O' as per the condition

        if (viewActions === "M") {
          return (
            policyActionsResponse.data
              //.filter((action: PolicyActionEntity) => action.isActive)
              .filter((action: { owner: string | undefined; manager: string | undefined }) => {
                return action.owner === userId || action.manager === userId;
              })
          );
        }

        //return policyActionsResponse.data.filter((action: PolicyActionEntity) => action.isActive);
        return policyActionsResponse.data.filter((action: PolicyActionEntity) => {
          return action.isActive || action.status === "D";
        });
      },
      refetchOnWindowFocus: true,
      staleTime: 0,
      refetchInterval: 0,
    });
  }
  const {
    data: fetchedActions = [],
    // isError: isLoadingActionsError,
    // isFetching: isFetchingActions,
    // isLoading: isLoadingActions,
    refetch: refetchActions,
  } = useAllActions();

  // category filter
  const [isCategoryExpanded, setIsCategoryExpanded] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState(
    categories.map((category) => category.code)
  );
  const handleCategoryToggle = () => {
    setIsCategoryExpanded(!isCategoryExpanded);
  };

  const handleCategoryChange = (categoryName: string) => {
    if (selectedCategories.includes(categoryName)) {
      setSelectedCategories(selectedCategories.filter((name) => name !== categoryName));
    } else {
      setSelectedCategories([...selectedCategories, categoryName]);
    }
  };

  // expand collapse of status filter
  const [isStatusExpanded, setIsStatusExpanded] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(status.map((category) => category.code));
  const handleStatusChange = (statusName: string) => {
    if (selectedStatus.includes(statusName)) {
      setSelectedStatus(selectedStatus.filter((name) => name !== statusName));
    } else {
      setSelectedStatus([...selectedStatus, statusName]);
    }
  };

  const handleStatusToggle = () => {
    setIsStatusExpanded(!isStatusExpanded);
  };

  // view action filter
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const filterOpen = Boolean(anchorEl);
  const [selectedView, setSelectedView] = useState(
    isAdmin || isGlobalAdmin ? "All Actions" : "My Actions"
  );

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = (view: React.SetStateAction<string>) => {
    setSelectedView(view);

    if (view === "My Actions") {
      setViewActions("M");
    } else if (view === "All Actions") {
      setViewActions("A");
    }
    refetchActions();
    setAnchorEl(null);
  };

  // quarter filter
  const [anchorQEl, setAnchorQEl] = useState<null | HTMLElement>(null);
  const quarterOpen = Boolean(anchorQEl);
  const currentYear = new Date().getFullYear();
  const [selectedQuarter, setSelectedQuarter] = useState<string>("");
  const [selectedQuarterMonths, setSelectedQuarterMonths] = useState<string[]>([]);
  useEffect(() => {
    // Function to get the quarter from the current month
    const getCurrentQuarter = (): string => {
      const currentMonth = new Date().getMonth() + 1; // Month is 0-indexed
      if (currentMonth >= 1 && currentMonth <= 3) {
        return `Q1`;
      } else if (currentMonth >= 4 && currentMonth <= 6) {
        return `Q2`;
      } else if (currentMonth >= 7 && currentMonth <= 9) {
        return `Q3`;
      } else {
        return `Q4`;
      }
    };

    // Set the default selected quarter based on the current date
    setSelectedQuarter(getCurrentQuarter());
  }, []);
  // quarter data
  const quartersData = [
    {
      quarter: `Q1`,
      months: ["January", "February", "March"],
    },
    {
      quarter: `Q2`,
      months: ["April", "May", "June"],
    },
    {
      quarter: `Q3`,
      months: ["July", "August", "September"],
    },
    {
      quarter: `Q4`,
      months: ["October", "November", "December"],
    },
  ];

  const handleQuarterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorQEl(event.currentTarget);
  };
  const handleQuarterClose = (q: React.SetStateAction<string>) => {
    setSelectedQuarter(q);
    setAnchorQEl(null);
  };
  // Update selected quarter months when selectedQuarter changes
  useEffect(() => {
    console.log("Selected Quarter", selectedQuarter);
    const quarterData = quartersData.find((q) => q.quarter === selectedQuarter);
    setSelectedQuarterMonths(quarterData ? quarterData.months : []);
  }, [selectedQuarter]);

  // Update quarter actions when fetchedActions or selectedQuarterMonths change
  useEffect(() => {
    const selectedQuarterData = quartersData.find((quarter) => quarter.quarter === selectedQuarter);

    if (!selectedQuarterData) return; // Handle case when selected quarter data is not found

    const actionsInQuarter: { month: string; actions: PolicyActionEntity[] }[] =
      selectedQuarterData.months.map((month) => ({ month, actions: [] }));

    fetchedActions.forEach((action: PolicyActionEntity) => {
      let dueDate: Date;
      if (action.dueDate !== null) dueDate = new Date(action.dueDate);
      else dueDate = new Date(action.lastUpdateDate);
      const monthName = dueDate.toLocaleString("default", { month: "long" });
      const monthIndex = actionsInQuarter.findIndex(({ month }) => month === monthName);
      if (monthIndex !== -1) {
        actionsInQuarter[monthIndex].actions.push(action);
      }
    });

    // Filter actions based on selected categories if selectedCategories is not empty
    const filteredActionsInQuarter =
      selectedCategories.length > 0
        ? actionsInQuarter.map(({ month, actions }) => ({
            month,
            actions: actions.filter((action) => selectedCategories.includes(action.principle)),
          }))
        : actionsInQuarter;

    const filteredStatusInQuarter =
      selectedStatus.length > 0
        ? filteredActionsInQuarter.map(({ month, actions }) => ({
            month,
            actions: actions.filter((action) => selectedStatus.includes(action.status)),
          }))
        : filteredActionsInQuarter;

    setQuarterActions(filteredStatusInQuarter);
  }, [selectedQuarterMonths, selectedCategories, selectedStatus, fetchedActions]);

  // console.log("quarter actions", quarterActions);
  function getBackgroundColor(referenceNumber: string) {
    const categoryLetter = referenceNumber.charAt(0); // Get the first letter of the reference number
    const category = categories.find((cat) => cat.code === categoryLetter); // Find the corresponding category
    return category ? category.color : "#8c9eff"; // Return the category color or transparent if not found
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "30px",
          marginRight: "20px",
          position: "relative",
          top: "-35px",
          marginLeft: "428px",
        }}
      >
        {/* view quarter filter */}
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Typography fontSize="12px" color="#66696C">
            Quarter:
          </Typography>
          <Typography fontSize="14px" color="#1E2429">
            {selectedQuarter}
          </Typography>
          <IconButton sx={{ cursor: "pointer", padding: 0 }} onClick={handleQuarterClick}>
            <Calendar />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorQEl}
            open={quarterOpen}
            onClose={() => handleQuarterClose(selectedQuarter)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {new Date().getMonth() + 1 > 3 ? (
              <MenuItem onClick={() => handleQuarterClose(`Q1`)}>Q1 {currentYear + 1}</MenuItem>
            ) : (
              <MenuItem onClick={() => handleQuarterClose(`Q1`)}>Q1 {currentYear}</MenuItem>
            )}

            {new Date().getMonth() + 1 > 6 ? (
              <MenuItem onClick={() => handleQuarterClose(`Q2`)}>Q3 {currentYear + 1}</MenuItem>
            ) : (
              <MenuItem onClick={() => handleQuarterClose(`Q2`)}>Q2 {currentYear}</MenuItem>
            )}

            {new Date().getMonth() + 1 > 9 ? (
              <MenuItem onClick={() => handleQuarterClose(`Q3`)}>Q3 {currentYear + 1}</MenuItem>
            ) : (
              <MenuItem onClick={() => handleQuarterClose(`Q3`)}>Q3 {currentYear}</MenuItem>
            )}

            <MenuItem onClick={() => handleQuarterClose(`Q4`)}>Q4 {currentYear}</MenuItem>
          </Menu>
        </Box>
        {/* view action filter */}
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <Typography fontSize="12px" color="#66696C">
            View:
          </Typography>
          <Typography fontSize="14px" color="#1E2429">
            {" "}
            {selectedView}
          </Typography>
          <IconButton sx={{ cursor: "pointer", padding: 0 }} onClick={handleFilterClick}>
            <Filter />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={filterOpen}
            onClose={() => handleFilterClose(selectedView)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {(isAdmin || isGlobalAdmin) && (
              <MenuItem onClick={() => handleFilterClose("All Actions")}>All Actions</MenuItem>
            )}
            <MenuItem onClick={() => handleFilterClose("My Actions")}>My Actions</MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box className="action-chart-box">
        <Box className="filter-box">
          <Box className="filter-title-box">
            <Typography fontSize="14px" fontWeight="600" color="#1E2429">
              Filters
            </Typography>
          </Box>
          {/* category filter */}
          <Box className="filter-category-box">
            <Box className="filter-category">
              <Typography fontSize="14px" fontWeight="600" color="#66696C">
                Categories
              </Typography>
              <IconButton onClick={handleCategoryToggle}>
                {isCategoryExpanded ? <ArrowUp /> : <ArrowDown />}
              </IconButton>
            </Box>
            <Collapse in={isCategoryExpanded}>
              {categories.map((category, index) => (
                // <Box key={index}>
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={selectedCategories.includes(category.code)}
                      onChange={() => handleCategoryChange(category.code)}
                      sx={{
                        color: category.color,
                        "&.Mui-checked": { color: category.color },
                        "& .MuiSvgIcon-root": { width: 18, height: 18 },
                      }}
                    />
                  }
                  label={
                    <Typography fontSize="14px" color="#1E2429">
                      {category.label}
                    </Typography>
                  }
                  // sx={{
                  //   alignItems: "center",
                  //   textAlign: "center",
                  //   display: "flex",
                  //   justifyContent: "center",
                  // }}
                />
                // </Box>
              ))}
            </Collapse>
          </Box>

          {/* status filter */}
          <Box className="filter-category-box">
            <Box className="filter-category">
              <Typography fontSize="14px" fontWeight="600" color="#66696C">
                Status
              </Typography>
              <IconButton onClick={handleStatusToggle}>
                {isStatusExpanded ? <ArrowUp /> : <ArrowDown />}
              </IconButton>
            </Box>
            <Collapse in={isStatusExpanded}>
              {status.map((status, index) => (
                // <Box key={index}>
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={selectedStatus.includes(status.code)}
                      onChange={() => handleStatusChange(status.code)}
                      sx={{
                        color: status.color,
                        "&.Mui-checked": { color: status.color },
                        "& .MuiSvgIcon-root": { width: 18, height: 18 },
                      }}
                    />
                  }
                  label={
                    <Typography fontSize="14px" color="#1E2429">
                      {status.label}
                    </Typography>
                  }
                  // sx={{
                  //   alignItems: "center",
                  //   textAlign: "center",
                  //   display: "flex",
                  //   justifyContent: "center",
                  // }}
                />
                // </Box>
              ))}
            </Collapse>
          </Box>
        </Box>
        <Box className="month-main-box">
          {quarterActions.map((monthData, index) => (
            <Box key={index} className="month-box">
              <Box className="month-title-box">
                <Typography fontSize="14px" fontWeight="600" color="#66696C">
                  {monthData.month}
                </Typography>
              </Box>
              <Box style={{ maxHeight: "580px", overflowY: "hidden" }}>
                <Box
                  style={{
                    overflowY: "auto",
                    height: "100%",
                    width: "100%",
                    scrollbarWidth: "none",
                  }}
                >
                  {monthData.actions.map((action, actionIndex) => (
                    <Box
                      key={actionIndex}
                      className="month-content-box"
                      onClick={() => setSelectedRowId(action.id)}
                    >
                      <Box className="month-title">
                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                          <Box
                            className="month-circle"
                            style={{ backgroundColor: getBackgroundColor(action.referenceNumber) }}
                          ></Box>
                          <Typography fontWeight="500" fontSize="14px" color="#1E2429">
                            {Math.floor(parseFloat(action.actionRefId)).toString()}
                          </Typography>
                        </Box>
                        <Typography
                          fontSize="12px"
                          color={
                            status.find((item) => item.code === action.status)?.color || "#66696C"
                          }
                          fontWeight="600"
                        >
                          {status.find((item) => item.code === action.status)?.label ||
                            "Unknown Status"}
                        </Typography>
                      </Box>
                      <Typography fontSize="14px" color="#66696C" fontWeight="500">
                        {action.title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                {selectedRowId !== "" && (
                  <ViewAction
                    actionId={selectedRowId}
                    isSystemAction={true}
                    onClose={handleClose}
                  />
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

const ActionChart: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ActionChartDetail />
    </QueryClientProvider>
  );
};

export default ActionChart;
