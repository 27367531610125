export type DropdownOption =
  | {
      label?: string;
      /**
       * @deprecated use `label` instead
       */
      text?: string;
      value: any;
    }
  | string;
export const parseFromValuesOrFunc = <T, U>(
  fn: ((arg: U) => T) | T | undefined,
  arg: U
): T | undefined => (fn instanceof Function ? fn(arg) : fn);

export const getValueAndLabel = (option?: DropdownOption): { label: string; value: string } => {
  let label: string = "";
  let value: string = "";
  if (option) {
    if (typeof option !== "object") {
      label = option;
      value = option;
    } else {
      label = option.label ?? option.text ?? option.value;
      value = option.value ?? label;
    }
  }
  return { label, value };
};
