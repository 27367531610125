function base64ToBlob(base64String: string, contentType = "") {
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }

  const byteArray = new Uint8Array(byteArrays);
  return new Blob([byteArray], { type: contentType });
}
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-undef */
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import axios, { AxiosError } from "axios";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { act } from "react-dom/test-utils";
import { useMsal } from "@azure/msal-react";
import { DateField, DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import Alert from "@mui/material/Alert";
import { useFormik } from "formik";
import * as Yup from "yup";
import Dropzone, { useDropzone } from "react-dropzone";
import CancelIcon from "@mui/icons-material/Cancel";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import { ReactComponent as Close } from "../../assets/close.svg";
import { ReactComponent as Upload } from "../../assets/cloud_upload.svg";
import { ReactComponent as Download } from "../../assets/download.svg";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { ReactComponent as ArrowDown } from "../../assets/keyboard_arrow_down.svg";

interface EditActionProps {
  actionId: string;
  isSystemAction: boolean;
  onClose: () => void;
}
type PolicyActionEntity = {
  referenceNumber: string;
  id: string;

  policyId: string;

  tenantId: string;

  code: string;

  title: string;

  description: string;

  importance: string;

  suggestedTimePeriod: Map<number, string>;

  owner: string;

  manager: string;

  status: string;

  frequency: string | null;

  // priority: number;

  comments: string;

  dueDate: Date;

  nextDueDate: Date;

  lastUpdateDate: Date;

  isRecurring: boolean;

  source: string;

  isResolved: boolean;

  costExternal: string;

  staffHours: string;

  // dependencies: string[];

  attachmentDocIds: string[];

  assignedActioner: string;
  actionRefId: string;
  principle: string;
  references: string[];
  isTechAssistanceRequired: boolean;

  [key: string]: any; // Index signature allowing dynamic access to string properties
};
const initialPolicyActionEntity: PolicyActionEntity = {
  referenceNumber: "",
  id: "",
  policyId: "",
  // ... other properties with appropriate initial values
  assignedActioner: "",
  staffHours: "",
  costExternal: "",
  isResolved: false,
  source: "",
  isRecurring: false,
  lastUpdateDate: new Date(),
  nextDueDate: new Date(),
  dueDate: new Date(),
  comments: "",
  // priority: 0,
  frequency: "",
  tenantId: "",
  code: "",
  title: "",
  description: "",
  importance: "",
  suggestedTimePeriod: new Map<number, string>(),
  owner: "",
  manager: "",
  status: "",
  // dependencies: [],
  attachmentDocIds: [],
  principle: "",
  actionRefId: "0",
  isTechAssistanceRequired: false,
  references: [],
};
type IdTokenClaims = {
  roles: string[];
  idp: string;
  email: string;
  signInName: string;
  appTenantName: string;
  sub: string;
  appTenantId: string;
  QuestionId: string;
  acr: any;
};
//example data type
type Member = {
  id: string;
  roles: string;
  tenantId: string;
  userId: string;
  givenName: string;
  surname: string;
  email: string;
  status: string;
};
// const status: Record<string, string> = {
//   P: "PENDING",
//   I: "IN PROGRESS",
//   D: "DONE",
//   C: "CANCELLED",
//   H: "ON HOLD",
// };
enum status {
  N = "Not Started",
  U = "Not Assigned",
  I = "In Progress",
  D = "Done",
  C = "Cancelled",
  H = "On Hold",
}
// const frequency: Record<string, string> = {
//   // D: "Daily",
//   // W: "Weekly",
//   // F: "Fortnightly",
//   //  M: "Monthly",
//   Q: "Quarterly",
//   H: "Half Yearly",
//   S: "Semi Annually",
//   Y: "Yearly",
//   A: "Annually",
//   N: "Not Applicable",
// };
enum frequency {
  Q = "Quarterly",
  S = "Semi Annually",
  A = "Annually",
}

// const priority: Record<number, string> = {
//   0: "Low",
//   1: "Medium",
//   2: "High",
// };
// const importance = ["High", "Critical"];
enum importance {
  C = "Critical",
  H = "High",
  //N = "None",
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const EditAction: React.FC<EditActionProps> = ({ actionId, isSystemAction, onClose }) => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<PolicyActionEntity>();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const [action, setAction] = React.useState("");
  const [values, setValues] = useState<PolicyActionEntity>(initialPolicyActionEntity);
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const [users, setUsers] = useState<Member[]>([]);
  const [allActions, setAllActions] = useState<PolicyActionEntity[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [existingFiles, setExistingFiles] = useState<any>([]);
  const [adoptedPolicies, setAdoptedPolicies] = useState<any[]>([]);

  // const onDrop = async (acceptedFiles: any) => {
  //   console.log(acceptedFiles);
  //   /* const formData = new FormData();
  //   formData.append("file", acceptedFiles[0]);

  //   try {
  //     await axios.post(`${process.env.REACT_APP_API_SERVER}/rm/b2cpolicies/uploadFiles`, formData);
  //     alert("File uploaded successfully!");
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   } */
  //   // add acceped file to the files array
  //   setFiles([...files, acceptedFiles[0]]);
  // };
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onDrop = async (acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      // Check if the file with the same name already exists
      const isDuplicate = files.some((file: any) => file.name === acceptedFiles[0].name);
      if (!isDuplicate) {
        // If it's not a duplicate, add the file to the files array
        setFiles([...files, acceptedFiles[0]]);
      } else {
        console.log("File with the same name already exists!");
        // If it's a duplicate, set the error message
        setErrorMessage("File with the same name already exists!");
      }
    }
  };

  const handleCancelClick = (index: number) => {
    // Create a new array excluding the canceled file
    const updatedFiles = [...files.slice(0, index), ...files.slice(index + 1)];
    setFiles(updatedFiles);
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  const handleDownloadClick = async (filename: string) => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_SERVER
        }/rm/b2cpolicies/getFile?tenantId=${appTenantId}&referenceNumber=${
          filename.split("/")[0]
        }&fileName=${encodeURIComponent(filename.split("/")[1])}`
      );
      const parts = response.data.split(",");
      const contentType = parts[0].split(":")[1].split(";")[0];
      const base64String = parts[1];
      const blob = base64ToBlob(base64String, contentType);
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename.split("/")[1];
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const validationSchema = Yup.object({
    referenceNumber: Yup.string().required("Reference number is required"),
    title: Yup.string().required("Action is required"),
    description: Yup.string().required("Action Description is required"),
    importance: Yup.string().required("Priority Level is required"),
    manager: Yup.string().test({
      name: "requiredIfNotN",
      test: function (value) {
        const status = this.parent.status;
        // Check if manager is required when status is not "N"
        return status === "U" || !!value;
      },
      message: "Manager is required",
    }),
    owner: Yup.string().test({
      name: "requiredIfNotN",
      test: function (value) {
        const status = this.parent.status;
        // Check if owner is required when status is not "N"
        return status === "U" || !!value;
      },
      message: "Action Owner is required",
    }),
    dueDate: Yup.date().required("Due Date is required"),
    isRecurring: Yup.string().required("Recurring Action is required"),
  });
  const formik = useFormik({
    initialValues: initialPolicyActionEntity,
    validationSchema: validationSchema,
    enableReinitialize: true, // Set enableReinitialize to true
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        await formik.validateForm(); // Ensure the form is validated
        // do validation that is isRecurring is true then frequency should not be null
        console.log("isRecurring", values["isRecurring"], "frequency", values["frequency"]);
        if (
          values["isRecurring"] &&
          (values["frequency"] === null ||
            values["frequency"] === "" ||
            values["frequency"] === undefined ||
            values["frequency"] === "N")
        ) {
          setAlertMessage("Frequency is required for recurring actions");
          setShowAlert(true);
          return;
        }
        await Promise.all(
          files.map(async (file: any) => {
            const fileFormData = new FormData();
            fileFormData.append("file", file);
            fileFormData.append("referenceNumber", values["id"]);

            try {
              await axios.post(
                `${process.env.REACT_APP_API_SERVER}/rm/b2cpolicies/uploadFiles/${values["tenantId"]}`,
                fileFormData
              );
              //alert("File uploaded successfully!");
            } catch (error: any) {
              console.error(
                "Error uploading file:",
                error.response ? error.response.data : error.message
              );
              alert(
                "Error uploading file: " + error.response ? error.response.data : error.message
              );
            }
          })
        );

        formik.values["nextDueDate"] = formik.values["dueDate"];
        if (formik.values["isRecurring"] === false) {
          formik.values["frequency"] = null;
        }

        const {
          dependencies,
          priority,
          isResolved,
          isActive,
          //isRecurring,
          lastUpdateDate,
          ...newFormik
        } = formik.values;
        let response = await axios.put(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/action/${formik.values["controlId"]}/${actionId}`,
          newFormik
        );

        if (response.status === 200) {
          setShowAlert(false);
          handleClose();
        } else {
          console.error("Error updating action", response.data.message);
          setAlertMessage("Error updating action" + response.data.message);
          setShowAlert(true);
        }
      } catch (err: any) {
        if (err.response && err.response.data && err.response.data.message) {
          console.error("Error submitting form:", err.response.data.message);
          setAlertMessage("Error submitting form: " + err.response.data.message);
        } else {
          console.error("Error submitting form:", err.message);
          setAlertMessage("Error submitting form: " + err.message);
        }
        setShowAlert(true);
        setSubmitting(false);
      } finally {
        // Set isSubmitting back to false after form submission
        setSubmitting(false);
      }
    },
  });
  const [oldValues, setOldValues] = useState(formik.values);
  const appTenantId = (accounts[0]?.idTokenClaims as IdTokenClaims)?.appTenantId;
  useEffect(() => {
    setOpen(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/` + appTenantId
        );
        setUsers(response.data);
        const response2 = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/actions/${appTenantId}`
        );
        setAllActions(response2.data);

        const response1 = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/action/${appTenantId}/${actionId}`
        ); // Replace with your API endpoint
        setValues(response1.data);
        formik.setValues(response1.data);
        setOldValues(response1.data);
        const response3 = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/b2cpolicies/files?tenantId=${appTenantId}&referenceNumber=${response1.data.id}`
        );
        const response4 = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/${appTenantId}`
        );
        setAdoptedPolicies(response4.data);
        setExistingFiles(response3.data);
        setLoaded(true);
      } catch (error) {
        // setError("Error fetching data");
        setAdoptedPolicies([]);
      }
    };

    fetchData();
  }, []);
  function isValidUrl(string: any): string | undefined {
    try {
      console.log("url", string);
      const url = new URL(string);
      return url.href;
    } catch (_) {
      return undefined;
    }
  }
  // Define a state to manage the error message visibility
  const [statusChangeError, setStatusChangeError] = useState(false);
  // const role = accounts[0]?.idTokenClaims?.roles[0];

  const isAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes("Tenant.admin");
  const isGlobalAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles[0] === "GlobalAdmin";

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openFile = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeFiles = () => {
    setAnchorEl(null);
  };
  const handleFormSubmit = () => {
    formik.handleSubmit(); // Call the form submission handler
  };

  const handleControlChange = (e: any) => {
    console.log("contol===========", e);
    formik.setFieldValue("controlId", e);
    const control = adoptedPolicies.find((item) => item.id === e);
  };

  return (
    <div>
      <Dialog
        //fullScreen
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        // onClose={handleClose}
        open={open}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography fontSize="16px" fontWeight="600">
                  Edit Action:
                </Typography>
                <Typography fontSize="16px" fontWeight="600" color="primary">
                  {formik.values && Math.floor(parseFloat(formik.values["actionRefId"])).toString()}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                <Typography fontSize="12px" fontWeight="600" color="#66696C">
                  {dayjs(formik.values["lastUpdateDate"]).format("DD/MMM/YY - hh:mmA")} (Last
                  Updated)
                </Typography>

                <Button type="submit" variant="contained">
                  Save Action
                </Button>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            {showAlert && <Alert severity="error">{alertMessage}</Alert>}
            {loaded ? (
              <>
                {isSystemAction && (
                  <>
                    <Stack direction="column" spacing={3}>
                      {/* action title */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          fontSize="14px"
                          color="rgba(17, 24, 39, 0.6)"
                          fontWeight="600"
                          sx={{ marginLeft: "5px" }}
                        >
                          Action Title <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          // label="Action Title"
                          name="title"
                          type="text" // You may need to determine the type dynamically based on your data
                          value={formik.values["title"] || ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(formik.errors.title)}
                          helperText={formik.errors.title}
                          sx={{ height: "40px", borderRadius: "5px", paddingY: "0px" }}
                          InputProps={{
                            style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                          }}
                        />
                      </Box>

                      {/* description */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          fontSize="14px"
                          color="rgba(17, 24, 39, 0.6)"
                          fontWeight="600"
                          sx={{ marginLeft: "5px" }}
                        >
                          Action Description <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          // label="Action Description"
                          placeholder="Action Description"
                          name="description"
                          multiline
                          type="text" // You may need to determine the type dynamically based on your data
                          value={formik.values["description"]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          sx={{
                            borderRadius: "5px",
                            paddingY: "0px",
                          }}
                          InputProps={{
                            style: { fontSize: "14px", borderRadius: "5px" }, // Set font size here
                          }}
                          error={Boolean(formik.errors.description)}
                          helperText={formik.errors.description}
                        />
                      </Box>
                      <Stack
                        direction="row"
                        spacing={4.8}
                        style={{ marginBottom: "10px", padding: "0px" }}
                      >
                        {/* control value  */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Control
                          </Typography>
                          {/* <TextField
                            fullWidth
                            select
                            variant="outlined"
                            disabled={formik.values["source"] !== "M"}
                            // label="Priority"
                            placeholder="Importance"
                            name="controlId"
                            value={formik.values["controlId"]}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            sx={{
                              height: "44px",
                              width: "180px",
                              borderRadius: "5px",
                              paddingY: "0px",
                            }}
                            InputProps={{
                              style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                            }}
                          >
                            {adoptedPolicies.map((value, index) => (
                              <MenuItem key={index} value={value.id}>
                                {`${value.controlRefId} - ${value.control}`}
                              </MenuItem>
                            ))}
                          </TextField> */}
                          <Autocomplete
                            fullWidth
                            disabled={formik.values["source"] !== "M"}
                            options={adoptedPolicies}
                            getOptionLabel={(option) =>
                              `${option.controlRefId} - ${option.control}`
                            }
                            value={
                              adoptedPolicies.find(
                                (option) => option.id === formik.values["controlId"]
                              ) || null
                            }
                            // value={formik.values["controlId"]}
                            onChange={(event, selectedOption) => {
                              if (selectedOption) {
                                handleControlChange(selectedOption.id);
                              } else {
                                // Handle the case when no option is selected
                                handleControlChange(null); // Pass null or any appropriate value
                              }
                            }}
                            onBlur={() => formik.setFieldTouched("control", true)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="control"
                                name="control"
                                // value={formik.values["control"] || ""}
                                // onChange={handleControlChange}
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                  height: "44px",
                                  width: "180px",
                                  borderRadius: "5px",
                                  paddingY: "0px",
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    fontSize: "14px",
                                    height: "100%", // Set height to 100% to occupy full height of container
                                    borderRadius: "5px",
                                    display: "flex", // Use flexbox for container
                                    alignItems: "center", // Center-align text vertically
                                    textAlign: "center", // Center-align text horizontally
                                  },
                                }}
                              />
                            )}
                          />
                        </Box>
                        {/* Priority  */}

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Priority Level <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            // label="Priority"
                            placeholder="Importance"
                            name="importance"
                            value={formik.values["importance"]}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            sx={{
                              height: "44px",
                              width: "180px",
                              borderRadius: "5px",
                              paddingY: "0px",
                            }}
                            InputProps={{
                              style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                            }}
                          >
                            {/* {importance.map((value) => (
                        <MenuItem key={value} value={value}>
                          {`${value}`}
                        </MenuItem>
                      ))} */}
                            {Object.entries(importance).map(([key, value]) => (
                              <MenuItem key={key} value={key} style={{ fontSize: "14px" }}>
                                {value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>

                        {/* status */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Action Status <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            // label="Action Status"
                            placeholder="Action Status"
                            name="status"
                            required
                            value={formik.values["status"]}
                            disabled={oldValues["status"] === "D" || oldValues["status"] === "C"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            sx={{
                              height: "44px",
                              width: "180px",
                              borderRadius: "5px",
                              paddingY: "0px",
                            }}
                            InputProps={{
                              style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                            }}
                          >
                            {Object.entries(status).map(([key, value]) => (
                              <MenuItem key={key} value={key}>
                                {value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>
                        {statusChangeError && (
                          <Typography variant="body2" color="error">
                            Status must be changed before saving.
                          </Typography>
                        )}

                        {/* manager */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Manager <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <TextField
                            fullWidth
                            select
                            // required
                            variant="outlined"
                            // label="Manager"
                            placeholder="Manager"
                            name="manager"
                            value={formik.values["manager"]}
                            disabled={!(isAdmin || isGlobalAdmin)}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            sx={{
                              height: "44px",
                              width: "180px",
                              borderRadius: "5px",
                              paddingY: "0px",
                            }}
                            InputProps={{
                              style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                            }}
                            error={Boolean(formik.errors.manager)}
                            helperText={formik.errors.manager}
                          >
                            {users
                              .filter(
                                (user) =>
                                  user.status === "Active" &&
                                  (user.roles.includes("Tenant.admin") ||
                                    user.roles.includes("Tenant.manager"))
                              ) // Filter users where status is Active
                              .map((user) => (
                                <MenuItem key={user.id} value={user.userId}>
                                  {`${user.givenName} ${user.surname}`}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Box>

                        {/*owner  */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Action Owner <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <TextField
                            fullWidth
                            select
                            //required={formik.values["status"] !== "N"}
                            variant="outlined"
                            // label="Action Owner"
                            placeholder="Owner"
                            name="owner"
                            value={formik.values["owner"]}
                            disabled={!(isAdmin || isGlobalAdmin)}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            sx={{
                              height: "44px",
                              width: "260px",
                              borderRadius: "5px",
                              paddingY: "0px",
                            }}
                            InputProps={{
                              style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                            }}
                            error={Boolean(formik.errors.owner)}
                            helperText={formik.errors.owner}
                          >
                            {users
                              .filter((user) => user.status === "Active") // Filter users where status is Active
                              .map((user) => (
                                <MenuItem key={user.id} value={user.userId}>
                                  {`${user.givenName} ${user.surname}`}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Box>
                      </Stack>
                      <Stack direction="row" spacing={4.8}>
                        {/*due date */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            // gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Due By <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateField", "DateField"]}>
                              <div style={{ width: "180px" }}>
                                <DatePicker
                                  // label="Due Date"
                                  value={dayjs(formik.values["dueDate"]) as Dayjs}
                                  onChange={(date) => formik.setFieldValue("dueDate", date)}
                                  //minDate={dayjs().startOf("day")}
                                  format="DD/MMM/YY"
                                  sx={{
                                    root: {
                                      paddingTop: "0px",
                                    },
                                    // Apply styles to the DatePicker component itself
                                    "& input": {
                                      // Target the input element within the DatePicker
                                      fontSize: "14px",
                                      height: "15px",
                                      borderRadius: "5px",
                                    },
                                  }}
                                />
                              </div>
                            </DemoContainer>
                          </LocalizationProvider>
                        </Box>

                        {/* isRecurring */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <FormControl component="fieldset">
                            {/* <FormLabel component="legend">Recurring Action</FormLabel> */}
                            <Typography
                              fontSize="14px"
                              color="rgba(17, 24, 39, 0.6)"
                              fontWeight="600"
                              sx={{ marginLeft: "5px" }}
                            >
                              Recurring Action <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <RadioGroup
                              row
                              aria-label="Recurring Action"
                              name="isRecurring"
                              value={formik.values["isRecurring"]}
                              onChange={(e) => {
                                formik.setFieldValue("isRecurring", e.target.value === "true");
                              }}
                              sx={{
                                height: "44px",
                                width: "180px",
                                paddingTop: "9px",
                                paddingLeft: "6px",
                              }}
                            >
                              <FormControlLabel value="false" control={<Radio />} label="No" />
                              <FormControlLabel value="true" control={<Radio />} label="Yes" />
                            </RadioGroup>
                          </FormControl>
                        </Box>

                        {/* frequency */}
                        {/* {formik.values["isRecurring"] && ( */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Action Frequency <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            // label="Frequency"
                            placeholder="Frequency"
                            name="frequency"
                            value={formik.values["frequency"] || ""}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={!formik.values["isRecurring"]}
                            sx={{
                              height: "44px",
                              width: "180px",
                              borderRadius: "5px",
                              paddingY: "0px",
                            }}
                            InputProps={{
                              style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                            }}
                            error={formik.values["isRecurring"] && Boolean(formik.errors.frequency)}
                            helperText={formik.values["isRecurring"] && formik.errors.frequency}
                          >
                            {Object.entries(frequency).map(([key, value]) => (
                              <MenuItem key={key} value={key}>
                                {value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>
                        {/* )} */}

                        {/* relevant priciple  */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Relevant Principle
                          </Typography>
                          <TextField
                            fullWidth
                            // select
                            variant="outlined"
                            // label="Frequency"
                            type="text"
                            disabled
                            placeholder="principle"
                            name="principle"
                            value={
                              formik.values["principle"] === "G"
                                ? "Govern"
                                : formik.values["principle"] === "P"
                                ? "Protect"
                                : formik.values["principle"] === "D"
                                ? "Detect"
                                : formik.values["principle"] === "R"
                                ? "Respond"
                                : formik.values["principle"] === "N"
                                ? "Manual"
                                : ""
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // disabled={!formik.values["isRecurring"]}
                            sx={{
                              height: "44px",
                              width: "180px",
                              borderRadius: "5px",
                              paddingY: "0px",
                            }}
                            InputProps={{
                              readOnly: true,

                              style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                            }}
                          ></TextField>
                        </Box>
                      </Stack>

                      {/* comments  and notes */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          fontSize="14px"
                          color="rgba(17, 24, 39, 0.6)"
                          fontWeight="600"
                          sx={{ marginLeft: "5px" }}
                        >
                          Comments/Notes
                        </Typography>
                        <TextField
                          fullWidth
                          multiline
                          variant="outlined"
                          // label="Comments and Notes"
                          placeholder="Comments"
                          name="notes"
                          disabled={
                            formik.values["status"] === "D" || formik.values["status"] === "C"
                          }
                          type="text" // You may need to determine the type dynamically based on your data
                          value={formik.values["notes"] || ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          InputProps={{
                            style: { fontSize: "14px", minHeight: "44px", borderRadius: "5px" }, // Set font size here
                          }}
                        />
                      </Box>
                      <Stack direction="row" spacing={4.8}>
                        {/* file upload */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Attachments
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            // label="Action Description"
                            placeholder="Attachments"
                            name="Attachments"
                            multiline
                            type="text" // You may need to determine the type dynamically based on your data
                            value={
                              existingFiles || files
                                ? `${files.length + existingFiles.length} attached`
                                : ""
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            sx={{
                              height: "44px",
                              width: "400px",
                              borderRadius: "5px",
                              paddingY: "0px",
                            }}
                            InputProps={{
                              style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                              endAdornment: (
                                <InputAdornment position="end">
                                  {files.length + existingFiles.length > 0 && (
                                    <IconButton onClick={handleClick}>
                                      <ArrowDown />
                                    </IconButton>
                                  )}
                                  <Menu
                                    id="file-menu"
                                    anchorEl={anchorEl}
                                    open={openFile}
                                    onClose={closeFiles}
                                  >
                                    {files &&
                                      files.map(
                                        (
                                          file: {
                                            name:
                                              | boolean
                                              | React.ReactChild
                                              | React.ReactFragment
                                              | React.ReactPortal
                                              | null
                                              | undefined;
                                          },
                                          index: number
                                        ) => (
                                          <MenuItem key={index}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                              <Typography>{file.name}</Typography>
                                              <IconButton onClick={() => handleCancelClick(index)}>
                                                <Delete />
                                              </IconButton>
                                              {/* <IconButton>
                                                <Download />
                                              </IconButton> */}
                                            </div>
                                          </MenuItem>
                                        )
                                      )}
                                    {existingFiles &&
                                      existingFiles.map((file: string, index: number) => (
                                        <MenuItem key={index}>
                                          <div style={{ display: "flex", alignItems: "center" }}>
                                            <Typography>{file.split("/")[1]}</Typography>
                                            {/* <IconButton onClick={() => handleCancelClick(index)}>
                                              <Delete />
                                            </IconButton> */}
                                            <IconButton onClick={() => handleDownloadClick(file)}>
                                              <Download />
                                            </IconButton>
                                          </div>
                                        </MenuItem>
                                      ))}
                                  </Menu>
                                  <Dropzone
                                    onDrop={onDrop}
                                    accept={{
                                      "application/pdf": [".pdf"],
                                      "image/*": [],
                                      "application/msword": [],
                                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                        [],
                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                        [],
                                      "application/vnd.ms-excel": [],
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div {...getRootProps()}>
                                        <input
                                          {...getInputProps()}
                                          id="fileInput"
                                          // onChange={handleFileInputChange}
                                          style={{ display: "none" }}
                                        />
                                        <IconButton
                                        // onClick={handleUploadClick}
                                        >
                                          <Upload />
                                        </IconButton>
                                      </div>
                                    )}
                                  </Dropzone>

                                  {/* <IconButton>
                                    <Download />
                                  </IconButton>
                                  <IconButton>
                                    <Delete />
                                  </IconButton> */}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>

                        {/* source */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Action Source
                          </Typography>

                          <TextField
                            fullWidth
                            variant="outlined"
                            // label="Action Source"
                            placeholder="source"
                            name="source"
                            type="text" // You may need to determine the type dynamically based on your data
                            value={formik.values["source"] === "M" ? "Manual" : "RRM"}
                            //onChange={(e) => setValues({ ...values, assignedActioner: e.target.value })}
                            disabled
                            sx={{
                              height: "44px",
                              width: "180px",
                              borderRadius: "5px",
                              paddingY: "0px",
                            }}
                            InputProps={{
                              style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                            }}
                          />
                        </Box>

                        {/* technical action */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <FormControl component="fieldset">
                            {/* <FormLabel component="legend">Recurring Action</FormLabel> */}
                            <Typography
                              fontSize="14px"
                              color="rgba(17, 24, 39, 0.6)"
                              fontWeight="600"
                              sx={{ marginLeft: "5px" }}
                            >
                              Technical Action? <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <RadioGroup
                              row
                              aria-label="isTechAssistanceRequired"
                              name="isTechAssistanceRequired"
                              value={formik.values["isTechAssistanceRequired"]}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "isTechAssistanceRequired",
                                  e.target.value === "true"
                                );
                              }}
                              sx={{
                                height: "44px",
                                width: "180px",
                                paddingTop: "6px",
                                paddingLeft: "6px",
                              }}
                            >
                              <FormControlLabel value="false" control={<Radio />} label="No" />
                              <FormControlLabel value="true" control={<Radio />} label="Yes" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Stack>

                      {/*  Useful Reference Information */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          fontSize="14px"
                          color="rgba(17, 24, 39, 0.6)"
                          fontWeight="600"
                          sx={{ marginLeft: "5px" }}
                        >
                          Useful Reference Information
                        </Typography>
                        {formik.values["references"].map(
                          (
                            reference: { [s: string]: unknown } | ArrayLike<unknown>,
                            index: React.Key | null | undefined
                          ) => (
                            <Typography
                              key={index}
                              fontSize="14px"
                              color="rgba(17, 24, 39, 1)"
                              fontWeight="400"
                            >
                              {Object.entries(reference).map(([key, value]) => (
                                <div key={key}>
                                  {(value as string).includes("Policy Manager") ? (
                                    <>
                                      <span style={{ fontWeight: "600" }}>
                                        {key}
                                        {value === "N/A" ? "" : ":"}{" "}
                                      </span>
                                      <a
                                        href="/PolicyBuilder"
                                        style={{ color: "blue", textDecoration: "underline" }}
                                      >
                                        Policy Manager
                                      </a>
                                    </>
                                  ) : (value as string) ===
                                    "<link to Third Party Supplier Register>" ? (
                                    <>
                                      <span style={{ fontWeight: "600" }}>
                                        {key}
                                        {value === "N/A" ? "" : ":"}{" "}
                                      </span>
                                      <a
                                        href="/AssetManagement"
                                        style={{ color: "blue", textDecoration: "underline" }}
                                      >
                                        Registers
                                      </a>
                                    </>
                                  ) : (value as string).includes("Systems Register") ? (
                                    <>
                                      <span style={{ fontWeight: "600" }}>
                                        {key}
                                        {value === "N/A" ? "" : ":"}{" "}
                                      </span>
                                      <a
                                        href="/AssetManagement"
                                        style={{ color: "blue", textDecoration: "underline" }}
                                      >
                                        Registers
                                      </a>
                                    </>
                                  ) : (value as string).includes("Hardware and Device Register") ? (
                                    <>
                                      <span style={{ fontWeight: "600" }}>
                                        {key}
                                        {value === "N/A" ? "" : ":"}{" "}
                                      </span>
                                      <a
                                        href="/AssetManagement"
                                        style={{ color: "blue", textDecoration: "underline" }}
                                      >
                                        Registers
                                      </a>
                                    </>
                                  ) : (value as string).includes("link to Dashboard") ? (
                                    <>
                                      <span style={{ fontWeight: "600" }}>
                                        {key}
                                        {value === "N/A" ? "" : ":"}{" "}
                                      </span>
                                      <a
                                        href="/Dashboard"
                                        style={{ color: "blue", textDecoration: "underline" }}
                                      >
                                        Dashboard
                                      </a>
                                    </>
                                  ) : isValidUrl(value) ? (
                                    <span style={{ fontWeight: "600" }}>
                                      <a
                                        href={value as string}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "blue", textDecoration: "underline" }}
                                      >
                                        {key}
                                      </a>
                                    </span>
                                  ) : (
                                    <span>{value === "N/A" ? "" : value}</span>
                                  )}

                                  {/* {value} */}
                                </div>
                              ))}
                            </Typography>
                          )
                        )}
                      </Box>
                    </Stack>
                  </>
                )}
              </>
            ) : (
              <div> Loading...</div>
            )}
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
};
export default EditAction;
