import React, { useEffect, useState } from "react";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import { loginRequest, b2cPolicies, deployment } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";

interface NewTenantSignUpProps {
  questionId: string; // or number, depending on what type questionId is supposed to be
}

export const NewTenantSignUp = () => {
  const { questionId } = useParams();
  const { instance } = useMsal();
  const [redirecting, setRedirecting] = useState(true);

  useEffect(() => {
    const redirectPage = async () => {
      if (redirecting) {
        sessionStorage.clear();
        await instance.loginRedirect({
          authority: b2cPolicies.authorities.newTenant.authority,
          scopes: loginRequest.scopes,
          redirectUri: deployment.baseUrl + "/loggedin",
          state: "Test State",
          extraQueryParameters: {
            questionId: questionId ?? "",
            //option: "signup",
          },
        });
        instance
          .handleRedirectPromise()
          .then((res) => {
            console.log("in Sign Up", res);
          })
          .catch((err) => {
            console.error(err);
          });

        setRedirecting(false); // Stop redirecting after the initial call
      }
    };
    redirectPage();
  }, [redirecting, instance]);

  return <div>{redirecting && <p>Loading...</p>}</div>;
};
