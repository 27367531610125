// DynamicForm.tsx
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Autocomplete,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  List,
  ListItem,
  Paper,
  Modal,
  TableHead,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useMsal } from "@azure/msal-react";
import { b2cPolicies, loginRequest } from "../../authConfig";
import axios from "axios";

import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";

import { StepIconProps } from "@mui/material/StepIcon";
import { ReactComponent as BackIcon } from "../../assets/arrow_back_ios.svg";
import { ReactComponent as TableIcon } from "../../assets/fact_check (1).svg";
import { ReactComponent as CompleteTable } from "../../assets/fact_check.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
export interface Question {
  questionId: string;
  question: string;
  options: string[]; // Dropdown options
  dependsOn: string; // ID of the question that this question depends on
  references?: Record<string, string[]>; // References linked to each option
  obligations?: Record<string, string[]>; // Obligations linked to each option
  conditionalValues?: Record<string, Question[]>; // Map of possible values based on conditions
}

interface DynamicFormProps {
  originalQuestions: Question[];
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Adjusted to a percentage to be responsive
  maxWidth: "600px", // Added maximum width for larger screens
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  minHeight: "400px",
};
const DynamicForm: React.FC<DynamicFormProps> = ({ originalQuestions }) => {
  const { instance } = useMsal();
  const [responseId, setResponseId] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingIframe, setLoadingIframe] = useState(true);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 600);

  const validationSchema = Yup.object().shape(
    originalQuestions.reduce((acc, question) => {
      (acc as Record<string, any>)[question.questionId] = Yup.string().required(
        `Please select a value for ${question.question}`
      );
      return acc;
    }, {} as Record<string, any>)
  );
  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth >= 600);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //  formik object
  const formik = useFormik({
    initialValues: originalQuestions
      .filter((question) => question.dependsOn === "")
      .reduce((acc, question) => {
        (acc as Record<string, any>)[question.questionId] = "";
        return acc;
      }, {} as Record<string, any>),
    // validationSchema,
    onSubmit: async (values) => {
      console.log("Form submitted with values:", values);
      const transformedArray = Object.keys(formik.values).map((key, index) => ({
        question: key,
        responses: formik.values[key],
        references: selectedAnswers[index].references,
        obligations: selectedAnswers[index].obligations,
      }));
      console.log("transformedArray", transformedArray);

      let response;
      response = await axios.post(`${process.env.REACT_APP_API_SERVER}/rm/org/onboarding/qa`, {
        assessments: transformedArray,
      });
      console.log("response", response);
      setResponseId(response.data.id);
    },
  });

  // signup button click
  const handleSignUp = async () => {
    // try {
    // Your custom logic
    await formik.handleSubmit(); // Ensure handleSubmit is awaited
    console.log("Custom logic executed");

    /* await instance.loginRedirect({
        authority: b2cPolicies.authorities.newTenant.authority,
        scopes: loginRequest.scopes,
        state: "Test State",
        extraQueryParameters: {
          questionId: responseId,
          option: "signup",
        },
      });
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    } */
    //setOpen(true);
  };

  useEffect(() => {
    if (responseId) {
      setOpen(true);
    }
  }, [responseId]);
  // check if dependent question is to be rendered
  const evaluateCondition = (dependsOnValue: string, options: any[]) => {
    // Split the dependsOnValue to get the key and expected value
    dependsOnValue = dependsOnValue.replace(/"/g, ""); // Remove double quotes
    const key = dependsOnValue?.split("=")[0].trim();
    const expectedValue = dependsOnValue?.split("=")[1]?.trim();
    // Find the corresponding option
    const sanitizedExpectedValue = expectedValue?.split(",")[0].replace(/['"]/g, "");
    const nextKeyValue = formik.values[(parseInt(key, 10) + 1).toString()];
    if ((formik.values[key] as string) === sanitizedExpectedValue) {
      return true;
    }
    return false;
  };

  // store answers
  // const [selectedAnswers, setSelectedAnswers] = useState<
  //   Array<{ question: string; answer: string; references: string[]; obligations: string[] }>
  // >([]);
  const [selectedAnswers, setSelectedAnswers] = useState<
    Array<{ question: string; references: string[]; obligations: string[] }>
  >([]);

  const questions = originalQuestions
    // Modify this part of the filtering condition
    .filter(
      (question) =>
        question.dependsOn === "" || evaluateCondition(question.dependsOn, question.options)
    );

  // handle answer selection
  const handleAnswerSelect = (questionId: string, answerId: string) => {
    formik.setValues((prev) => ({ ...prev, [questionId]: answerId }));
  };

  console.log("formik.values", formik.values);

  // show references and obligations
  const [showReferencesObligations, setShowReferencesObligations] = useState(false);
  type Answer = {
    question: string;
    references: string[];
    obligations: string[];
  };
  function removeDuplicate(selectedAnswersArray: any[]) {
    const uniqueArray = selectedAnswersArray.filter((element: any, index: any) => {
      const isDuplicate = selectedAnswersArray.some(
        (otherElement: any, otherIndex: any) =>
          index !== otherIndex && compareAnswers(element, otherElement)
      );
      return !isDuplicate;
    });
    return uniqueArray;
  }

  function compareAnswers(answer1: any, answer2: any) {
    // Implement logic to compare references and obligations arrays, ignoring whitespaces and formatting
    // You can use string manipulation functions here
    return /* true if duplicate, false otherwise */;
  }
  interface Option {
    [key: string]: string;
  }
  const renderReferencesObligationsTable = () => {
    const selectedAnswersArray = questions.map((question) => {
      let selectedKey = formik.values[question.questionId];
      console.log("selectedKey", selectedKey);

      // If the selected key is an array, check if it contains "No"
      if (Array.isArray(selectedKey) && selectedKey.includes("None")) {
        selectedKey = "None";
      } else if (Array.isArray(selectedKey)) {
        selectedKey = "ACR";
      }

      // Find the corresponding code based on the selected option string
      // let selectedOptionCode = null;
      let selectedOptionCode: string | null = null;
      if (question.questionId === "4") {
        question.options.forEach((option: any) => {
          const keys: string[] = Object.keys(option);
          const code: string = keys[0]; // Get the key of the option object
          const value: string = option[code]; // Get the value associated with the key

          if (value === selectedKey) {
            selectedOptionCode = code;
            selectedKey = code;
          }
        });
      }

      // Check if the selectedKey exists in references and obligations
      const references = question.references?.[selectedKey] || [];
      const obligations = question.obligations?.[selectedKey] || [];

      return {
        question: question.question,
        // answer: formik.values[question.questionId],
        references: references,
        obligations: obligations,
      };
    });

    // Move question (refernce- as detailed above) to the end
    const question2Index = selectedAnswersArray.findIndex(
      (answer: { references: string[] }) => answer.references[0] === "As detailed above"
    );
    const question2 = selectedAnswersArray.splice(question2Index, 1)[0];
    selectedAnswersArray.push(question2);

    const updatedSelectedAnswersArray = removeDuplicate(selectedAnswersArray);
    setSelectedAnswers(updatedSelectedAnswersArray);

    // setSelectedAnswers(selectedAnswersArray);

    setShowReferencesObligations(true);
  };

  console.log("selectedAnswers", selectedAnswers);
  console.log(
    "filtered answer",
    selectedAnswers
      .filter((answer, index, self) => {
        // Check if there is no other object with the same references and obligations
        return (
          index ===
          self.findIndex(
            (other) =>
              arraysEqual(other.references, answer.references) &&
              arraysEqual(other.obligations, answer.obligations)
          )
        );
      })
      .filter((obj) => {
        // Check if the object has both references and obligations properties
        return obj.references.length > 0 && obj.obligations.length > 0;
      })
  );
  // render options
  const renderOptions = (question: Question) => {
    const selectedOptions = formik.values[question.questionId as keyof typeof formik.values] || [];

    const handleCheckboxChange = (option: string) => {
      let newSelectedOptions: string[];

      if (option === "None") {
        // If "No" is selected, disable other options
        newSelectedOptions = ["None"];
      } else {
        // If any other option is selected, disable "No"
        newSelectedOptions = selectedOptions.includes(option)
          ? selectedOptions.filter((item: string) => item !== option)
          : selectedOptions.includes("None")
          ? [option]
          : [...selectedOptions, option];
      }

      formik.setFieldValue(question.questionId, newSelectedOptions);
    };

    console.log("showReferencesObligations", showReferencesObligations);
    console.log("selectedAnswers", selectedAnswers);

    return (
      <div>
        {/* Use a ternary operator to conditionally render either RadioGroup or Select */}
        {question.questionId === "9" ? (
          // Render Checkboxes
          <div style={{ columnCount: 2 }}>
            {question.options.map((option) => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    color="primary"
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                    value={option}
                    size="small"
                  />
                }
                label={
                  <Typography
                    fontSize="14px"
                    style={{
                      fontWeight:
                        formik.values[question.questionId as keyof typeof formik.values] === option
                          ? "bold" // Bold font when selected
                          : "normal", // Normal font weight when not selected
                    }}
                  >
                    {option}
                  </Typography>
                }
                style={{
                  // margin: "5px", // Adjust margin as needed
                  // padding: "5px", // Adjust padding as needed
                  display: "flex",
                  alignItems: "center",

                  color:
                    formik.values[question.questionId as keyof typeof formik.values] === option
                      ? "#233DFF" // Font color when selected
                      : "black",
                  fontWeight:
                    formik.values[question.questionId as keyof typeof formik.values] === option
                      ? "bold" // Bold font when selected
                      : "normal", // Normal font weight when not selected
                }}
              />
            ))}
          </div>
        ) : question.options.length > 10 ? (
          <Autocomplete
            id={question.questionId}
            options={question.options}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option; // Return the string directly
              } else if (typeof option === "object" && option !== null) {
                const key = Object.keys(option)[0]; // Extract the key
                return option[key]; // Return the value associated with the key
              }
              return ""; // Default return value if option is neither string nor object
            }}
            value={formik.values[question.questionId as keyof typeof formik.values]}
            onChange={(e, newValue) =>
              handleAnswerSelect(
                question.questionId,
                newValue !== null && typeof newValue === "object"
                  ? Object.values(newValue)[0]
                  : newValue
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Start typing main activity"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: "14px" },
                }}
              />
            )}
            sx={{
              width: "247px",
              marginTop: "18px",
              height: { md: "30px", xs: "70px" },
              fontSize: "14px",
            }}
            // style={{ width: "247px", marginTop: "18px", height: "30px", fontSize: "14px" }}
            ListboxComponent={(props) => (
              <Paper
                {...props}
                style={{
                  ...props.style,
                  fontSize: "14px",
                }}
              />
            )}
          />
        ) : (
          <div
            style={{
              ...(isDesktop && {
                columnCount: question.options.length === 3 ? 3 : 2,
                columnGap: "30px",
              }),
            }}
          >
            <RadioGroup
              name={question.questionId}
              defaultValue={undefined}
              value={formik.values[question.questionId as keyof typeof formik.values]}
              onChange={(e) => handleAnswerSelect(question.questionId, e.target.value)}
              key={question.questionId}
              row={!isDesktop} // Set row to true only when not on a desktop
            >
              {question.options.map((option) => (
                // eslint-disable-next-line react/jsx-key
                <FormControlLabel
                  value={option}
                  control={<Radio color="primary" size="small" />}
                  label={
                    <Typography
                      fontSize="14px"
                      style={{
                        fontWeight:
                          formik.values[question.questionId as keyof typeof formik.values] ===
                          option
                            ? "bold" // Bold font when selected
                            : "500", // Normal font weight when not selected
                      }}
                      key={option}
                    >
                      {option}
                    </Typography>
                  }
                  style={{
                    color:
                      formik.values[question.questionId as keyof typeof formik.values] === option
                        ? "#233DFF" // Font color when selected
                        : "black",
                    fontWeight:
                      formik.values[question.questionId as keyof typeof formik.values] === option
                        ? "bold" // Bold font when selected
                        : "normal", // Normal font weight when not selected
                  }}
                />
              ))}
            </RadioGroup>
          </div>
        )}
      </div>
    );
  };

  // render question
  const renderQuestion = (question: Question) => {
    const conditionalValues = question.conditionalValues || {};
    const hasError = !!formik.errors[question.questionId];

    return (
      <div>
        <Typography fontSize="14px" fontWeight="500">
          {question.question}
        </Typography>

        <div style={{ width: "100%", marginTop: "10px" }}>
          {renderOptions(question)}
          {Object.keys(conditionalValues).map((dependentValue) => {
            const dependentQuestions = conditionalValues[dependentValue];
            return dependentValue === formik.values[question.dependsOn || ""]
              ? dependentQuestions.map((dependentQuestion: Question) => (
                  <React.Fragment key={question.questionId}>
                    <ListItem>{renderQuestion(dependentQuestion)}</ListItem>
                    {formik.errors[dependentQuestion.questionId] && (
                      <div style={{ color: "red" }}>
                        {/* {formik.errors[dependentQuestion.questionId]} */}
                      </div>
                    )}
                  </React.Fragment>
                ))
              : null;
          })}
        </div>
      </div>
    );
  };

  // =====================new stepper====================
  const [activeStep, setActiveStep] = React.useState(0);
  // Calculate the number of steps based on the number of questions
  const numQuestions = questions.length;
  const numSteps = Math.ceil(numQuestions / 3) + 1;

  // Generate the steps array with empty labels
  const steps = Array.from({ length: numSteps }, (_, index) => ``);

  const currentStepQuestions = questions.slice(activeStep * 3, activeStep * 3 + 3);
  const isButtonDisabled = currentStepQuestions.some(
    (question) => !formik.values[question.questionId]
  );

  // handle next and back
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // customised stepper connector
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#233DFF",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#233DFF",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,

      backgroundColor: "#EDEDED",
      borderRadius: 1,
    },
  }));

  // customised step icon
  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean; isLastStep?: boolean };
    isLastStep?: boolean;
  }>(({ theme, ownerState, isLastStep }) => ({
    border: "1px solid #D4D4D4",
    zIndex: 1,
    color: "#66696C",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      border: "2px solid #233DFF",
    }),

    ...(ownerState.completed || isLastStep
      ? {
          background: "#233DFF",
          border: "2px solid #233DFF",
          color: "#fff",
        }
      : {}),
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className, icon } = props;
    const isLastStep = icon === numSteps && active === true;

    const icons: { [index: string]: React.ReactElement } = {
      1: <span>1</span>,
      2: <span>2</span>,
      3: <span>3</span>,
      4: <span>4</span>,

      // 5: numSteps === 5 ? <TableIcon /> : <span>5</span>,
      5: isLastStep ? <CompleteTable /> : numSteps === 5 ? <TableIcon /> : <span>5</span>,
      // 6: numSteps === 5 ? <span></span> : <TableIcon />, // Only include if numSteps is not 5
      6: isLastStep ? <CompleteTable /> : numSteps === 5 ? <span></span> : <TableIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
        isLastStep={isLastStep}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  function arraysEqual(arr1: any[], arr2: any[]) {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
      return false;
    }

    if (arr1.length !== arr2.length) {
      return false;
    }

    if (arr1.length !== arr2.length) {
      return false;
    }

    const arr1Str = arr1.join(",").toUpperCase().trim();
    const arr2Str = arr2.join(",").toUpperCase().trim();

    return arr1Str === arr2Str;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: { xs: "30px", md: "45px" },
      }}
      // style={{ padding: "45px", display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div>
        {activeStep !== numSteps - 1 && (
          <Typography fontSize="16px" fontWeight="500" color="#66696C">
            Identify key regulatory & legal obligations currently applicable to your Organisation.
          </Typography>
        )}
      </div>
      <div
        style={{
          marginTop: activeStep !== numSteps - 1 ? "40px" : "0px",
          width: "375px",
          marginBottom: "40px",
        }}
      >
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      {activeStep === steps.length - 1 ? (
        <div>
          {!(
            showReferencesObligations &&
            selectedAnswers.every(
              (answer) => answer.references.length === 0 && answer.obligations.length === 0
            )
          ) && (
            <div style={{ textAlign: "center", marginBottom: "40px" }}>
              <Typography fontSize="16px" fontWeight="600" color="#1E2429">
                Based on your responses these are likely regulatory & legal obligations currently
                applicable to your Organisation.
              </Typography>
            </div>
          )}
          <div>
            <TableContainer
              sx={{ border: "1px solid #D4D4D4", display: { xs: "none", md: "block" } }}
            >
              <Table>
                {showReferencesObligations &&
                  selectedAnswers.length > 0 &&
                  selectedAnswers.some(
                    (answer) => answer.obligations.length > 0 || answer.references.length > 0
                  ) && (
                    <>
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#F7F7F7" }}>
                          <TableCell
                            style={{
                              width: "30%",
                              border: "1px solid #D4D4D4",
                              fontWeight: "600",
                              textAlign: "center",
                            }}
                          >
                            Reference
                          </TableCell>
                          <TableCell
                            style={{
                              width: "70%",
                              border: "1px solid #D4D4D4",
                              fontWeight: "600",
                              textAlign: "center",
                            }}
                          >
                            Obligation Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedAnswers
                          .filter((answer, index, self) => {
                            // Check if there is no other object with the same references and obligations
                            return (
                              index ===
                              self.findIndex(
                                (other) =>
                                  arraysEqual(other.references, answer.references) &&
                                  arraysEqual(other.obligations, answer.obligations)
                              )
                            );
                          })
                          .filter((obj) => {
                            // Check if the object has both references and obligations properties
                            return obj.references.length > 0 && obj.obligations.length > 0;
                          })
                          .map((answer, index) => (
                            <React.Fragment key={index}>
                              {answer.references.length === 1 ? (
                                <TableRow
                                  key={index}
                                  sx={{ height: "70px", border: "1px solid #D4D4D4" }}
                                >
                                  <TableCell
                                    style={{
                                      width: "30%",
                                      whiteSpace: "pre-wrap",
                                      wordWrap: "break-word",
                                      // textAlign: "center",
                                      border: "1px solid #D4D4D4",
                                    }}
                                  >
                                    {answer.references.join(", ") || "None"}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: "70%",
                                      whiteSpace: "pre-wrap",
                                      // wordWrap: "break-word",
                                      border: "1px solid #D4D4D4",
                                    }}
                                  >
                                    {answer.obligations.join(", ") || "None"}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                answer.references.map((reference, index) => (
                                  // eslint-disable-next-line react/jsx-key
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        width: "30%",
                                        whiteSpace: "pre-wrap",
                                        // wordWrap: "break-word",
                                        border: "1px solid #D4D4D4",
                                      }}
                                    >
                                      {reference}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        width: "70%",
                                        whiteSpace: "pre-wrap",
                                        // wordWrap: "break-word",
                                        border: "1px solid #D4D4D4",
                                      }}
                                    >
                                      {answer.obligations[index]}
                                    </TableCell>
                                  </TableRow>
                                ))
                              )}
                            </React.Fragment>
                          ))}
                      </TableBody>
                    </>
                  )}
              </Table>
            </TableContainer>

            {showReferencesObligations &&
              selectedAnswers.every(
                (answer) => answer.references.length === 0 && answer.obligations.length === 0
              ) && (
                <div>
                  <Typography fontSize="15px">
                    Based on the inputs you have provided, there appears to be no applicable
                    regulations or legislation requiring data security and/or reporting obligations
                    for your Organisation <b>currently</b>.
                  </Typography>
                  <br />
                  <Typography fontSize="15px">
                    However, rules and regulation change, and there may be other agreements that
                    have data security-related requirements, such as commercial contracts.
                  </Typography>
                  <br />
                  <Typography fontSize="15px">
                    In order to be sure, speak to a qualified legal advisor.
                  </Typography>
                  <br />
                  <Typography fontSize="14px">
                    Note- it is good commercial risk management practice to protect commercially
                    sensitive data, so even if there is no regulatory requirement, it is still
                    prudent to put in place a cyber security plan.
                  </Typography>
                </div>
              )}
          </div>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            {showReferencesObligations &&
              selectedAnswers.length > 0 &&
              selectedAnswers.some(
                (answer) => answer.obligations.length > 0 || answer.references.length > 0
              ) && (
                <>
                  {selectedAnswers
                    .filter((answer, index, self) => {
                      // Check if there is no other object with the same references and obligations
                      return (
                        index ===
                        self.findIndex(
                          (other) =>
                            arraysEqual(other.references, answer.references) &&
                            arraysEqual(other.obligations, answer.obligations)
                        )
                      );
                    })
                    .filter((obj) => {
                      // Check if the object has both references and obligations properties
                      return obj.references.length > 0 && obj.obligations.length > 0;
                    })
                    .map((answer, index) => (
                      <React.Fragment key={index}>
                        {answer.references.length === 1 ? (
                          <Accordion sx={{ mb: 0 }} disableGutters>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              {answer.references.join(", ") || "None"}
                            </AccordionSummary>
                            <AccordionDetails>
                              {answer.obligations.join(", ") || "None"}
                            </AccordionDetails>
                          </Accordion>
                        ) : (
                          answer.references.map((reference, index) => (
                            <Accordion key={index}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                {reference}
                              </AccordionSummary>
                              <AccordionDetails>{answer.obligations[index]}</AccordionDetails>
                            </Accordion>
                          ))
                        )}
                      </React.Fragment>
                    ))}
                </>
              )}
          </Box>
          <div
            style={{
              marginTop: "50px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack direction="column" spacing={2}>
              <Typography fontSize="14px">
                Time to put your cyber security strategy in place.
              </Typography>
              <Stack
                direction={{ md: "row", xs: "column-reverse" }}
                sx={{ alignItems: { xs: "center" } }}
                spacing={{ md: 5, xs: 3 }}
              >
                {activeStep !== 0 && (
                  <Button
                    onClick={handleBack}
                    startIcon={<BackIcon />}
                    style={{ color: "#66696C" }}
                  >
                    Previous Step
                  </Button>
                )}
                <Button
                  variant="contained"
                  sx={{ width: { xs: "max-content" } }}
                  onClick={handleSignUp}
                >
                  Sign Up
                </Button>
                <Modal
                  open={open}
                  //onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <>
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <iframe
                        src={`/account/NewTenantSignUp/${responseId}`}
                        width="100%"
                        height="500px"
                        onLoad={() => setLoadingIframe(false)}
                        loading="lazy"
                        scrolling="no"
                        frameBorder="0"
                      ></iframe>
                      {loadingIframe ? <p> Loading ...</p> : null}
                    </>
                  </Box>
                </Modal>
              </Stack>
            </Stack>
          </div>
        </div>
      ) : (
        <>
          <form onSubmit={formik.handleSubmit}>
            {steps.map(
              (label, index) =>
                activeStep === index && (
                  <Box
                    key={label}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: { xs: "100%", md: "1240px" },
                    }}
                    // style={{
                    //   display: "flex",
                    //   flexDirection: "row",
                    //   width: "1240px",
                    // }}
                  >
                    {/* <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel> */}
                    <List
                      sx={{
                        display: "flex",
                        flexDirection: {
                          xs: "column", // For extra small screens
                          md: "row", // For medium screens
                        },
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                      // style={{
                      //   display: "flex",
                      //   flexDirection: "row",
                      //   alignItems: "flex-start",
                      //   width: "100%",
                      // }}
                    >
                      {questions.slice(index * 3, index * 3 + 3).map((question, questionIndex) => (
                        <React.Fragment key={question.questionId}>
                          <ListItem>{renderQuestion(question)}</ListItem>
                          {questionIndex !==
                            questions.slice(index * 3, index * 3 + 3).length - 1 && (
                            <Box
                              sx={{
                                borderRight: { xs: "none", md: "1px solid #D4D4D4" },
                                borderBottom: { md: "none", xs: "1px solid #D4D4D4" },
                                height: {
                                  md: "180px",
                                  xs: "0px",
                                } /* Adjust this according to your layout */,
                                width: {
                                  md: "0px",
                                  xs: "100%",
                                },
                                margin: "0 10px",
                              }}
                            ></Box>
                          )}
                        </React.Fragment>
                      ))}
                    </List>
                  </Box>
                )
            )}
          </form>
          <Stack
            direction={{ md: "row", xs: "row" }}
            spacing={{ md: 5, xs: 3 }}
            sx={{ marginTop: "30px" }}
          >
            {activeStep !== 0 && (
              <Button onClick={handleBack} startIcon={<BackIcon />} style={{ color: "#66696C" }}>
                Previous Step
              </Button>
            )}
            <Button
              variant="contained"
              type="submit"
              onClick={async () => {
                try {
                  if (activeStep === numSteps - 2) {
                    renderReferencesObligationsTable();
                    //await formik.handleSubmit(); // Ensure handleSubmit is awaited
                    handleNext();
                  } else {
                    handleNext();
                  }
                } catch (error) {
                  console.error("Error:", error);
                  // Handle error
                }
              }}
              disabled={isButtonDisabled}
            >
              {activeStep === numSteps - 2 ? "Identify Obligations" : "Next Step"}
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default DynamicForm;
