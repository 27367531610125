/* eslint-disable react/jsx-no-undef */
import { useMsal } from "@azure/msal-react";
import { Box, Button, IconButton, Snackbar, Typography } from "@mui/material";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import {
  MRT_ColumnDef,
  MRT_TableOptions,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import EditAction from "../Actions/EditAction";
import AddAction from "../Actions/AddAction";
import ConfirmDialog from "../../helpers/dialog/ConfirmDialog";
import { jsPDF } from "jspdf"; //or use your library of choice here
import autoTable from "jspdf-autotable";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import { ReactComponent as Export } from "../../assets/export_notes.svg";
import { ReactComponent as Filter } from "../../assets/filter_alt.svg";
import { ReactComponent as MoreOption } from "../../assets/more_vert.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink, CSVDownload } from "react-csv";
import ViewAction from "../Actions/ViewAction";

const queryClient = new QueryClient();
const status: Record<string, string> = {
  N: "Not Started",
  P: "Not Assigned",
  U: "Not Assigned",
  I: "In Progress",
  D: "Done",
  C: "Cancelled",
  H: "On Hold",
  O: "Overdue",
};
const importance: Record<string, string> = {
  C: "Critical",
  H: "High",
  N: "None",
};
const frequency: Record<string, string> = {
  Q: "Quarterly",
  S: "Semi Annually",
  A: "Annually",
  N: "Not Applicable",
};
const extractedHeaders = {
  "Ref No": "actionRefId",
  Action: "title",
  "Action Description": "description",
  Control: "controlId",
  Principle: "principle",
  "Priority Level": "importance",
  "Action Status": "status",
  Manager: "manager",
  "Action Owner": "owner",
  "Due By": "dueDate",
  "Recurring Action": "isRecurring",
  "Action Frequency": "frequency",
  Comments: "notes",
  "Action Source": "source",
  "Technical Action": "isTechAssistanceRequired",
  "Useful Reference Information": "references",
};
export const DetailWorkflow = () => {
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const appTenantId = (accounts[0].idTokenClaims as IdTokenClaims).appTenantId;
  const myUserId = (accounts[0].idTokenClaims as IdTokenClaims).sub;
  const [users, setUsers] = React.useState<Member[]>([]);
  const [selectedRowId, setSelectedRowId] = useState<string>("");
  const [selectedViewRowId, setSelectedViewRowId] = useState<string>("");
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(false);
  const isAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes("Tenant.admin");
  const isGlobalAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles[0] === "GlobalAdmin";
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [deleteRowId, setDeleteRowId] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>("");
  const [viewActions, setViewActions] = useState<string>(isAdmin || isGlobalAdmin ? "A" : "M");
  const [adoptedPolicies, setAdoptedPolicies] = useState<any[]>([]);
  const handleItemClick = (index: string) => {
    // Toggle the selected item index
    setSelectedRowId(index);
    console.log("selectedRowId", index);
  };
  const handleAddClick = () => {
    setShowAdd(true);
  };
  useEffect(() => {
    refetchActions();
  }, [selectedRowId, showAdd]);

  function useAllActions() {
    return useQuery<PolicyActionEntity[]>({
      queryKey: ["actions", appTenantId, myUserId, viewActions],
      queryFn: async ({ queryKey }) => {
        //send api request here
        // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
        const [_, appTenantId, myUserId, viewActions] = queryKey;
        const policyActionsResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/actions/${appTenantId}`
        );
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/` + appTenantId
        );
        setUsers((prevValue: Member[]) => [...prevValue, ...userResponse.data]);
        const response4 = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/${appTenantId}`
        );
        setAdoptedPolicies(response4.data);
        //for the current user find the user id
        console.log("users", userResponse.data, myUserId);
        const userId = userResponse.data?.find(
          (user: { userId: string }) => user.userId === myUserId
        )?.userId;
        //if view my action is M then filter the actions by owner
        console.log("my user id", userId, viewActions);
        policyActionsResponse.data.forEach((action: PolicyActionEntity) => {
          // Check if action due date is less than today and status is not 'D' or 'C'
          if (
            new Date(action.dueDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) &&
            action.status !== "D" &&
            action.status !== "C"
          ) {
            // Update status to 'O'
            action.status = "O";
          }
        });
        if (viewActions === "M") {
          return policyActionsResponse.data
            .filter((action: PolicyActionEntity) => {
              return action.isActive || action.status === "D" || action.status === "C";
            })
            .filter((action: { owner: string | undefined; manager: string | undefined }) => {
              return action.owner === userId || action.manager === userId;
            });
        }

        return policyActionsResponse.data.filter((action: PolicyActionEntity) => {
          return action.isActive || action.status === "D" || action.status === "C";
        });
        //return policyActionsResponse.data;
      },
      refetchOnWindowFocus: true,
      staleTime: 0,
      refetchInterval: 0,
    });
  }

  const {
    data: fetchedActions = [],
    isError: isLoadingActionsError,
    isFetching: isFetchingActions,
    isLoading: isLoadingActions,
    refetch: refetchActions,
  } = useAllActions();

  console.log("fetchedActions", fetchedActions);

  const getStatusColor = (status: string, dueDate: Date) => {
    if (
      new Date(dueDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) &&
      status !== "D" &&
      status !== "C"
    ) {
      return "#FF6666";
    }
    switch (status) {
      case "U":
        return "#FF9900";
      case "I":
        return "#49AEEE";
      case "N":
        return "#C2CAFF";
      case "C":
        return "#555555";
      case "H":
        return "#B9B9B9";
      case "D":
        return "#23AF00";
      case "O": //overdue
        return "#FF6666";
      default:
        return "transparent"; // Default color
    }
  };

  const columns = useMemo<MRT_ColumnDef<PolicyActionEntity>[]>(
    () => [
      {
        accessorKey: "actionRefId", //access nested data with dot notation
        header: "Ref. No.",
        size: 10,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "10vh",
              whiteSpace: "pre-wrap",
            }}
          >
            <span>{Math.floor(parseFloat(row.original.actionRefId)).toString()}</span>
          </Box>
        ),
      },
      {
        accessorKey: "principle", //access nested data with dot notation
        header: "Category",
        size: 10,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "10vh",
              whiteSpace: "pre-wrap",
            }}
          >
            <span>
              {row.original.principle === "G"
                ? "Govern"
                : row.original.principle === "D"
                ? "Detect"
                : row.original.principle === "P"
                ? "Protect"
                : row.original.principle === "R"
                ? "Respond"
                : row.original.principle === "N"
                ? "Manual"
                : row.original.principle}
            </span>
          </Box>
        ),
      },
      {
        accessorKey: "title", //access nested data with dot notation
        header: "Action",
        size: 10,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "60vh",
              whiteSpace: "pre-wrap",
              cursor: "pointer", // Add cursor pointer to indicate it's clickable
              textDecoration: "underline", // Add underline to indicate it's a hyperlink
              color: "blue", // Add color to indicate it's a hyperlink
            }}
            onClick={() => setSelectedViewRowId(row.original.id)}
          >
            <span>{row.original.title}</span>
          </Box>
        ),
      },
      {
        accessorKey: "manager",
        header: "Manager",
        size: 10,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>
              {row.original.manager
                ? users?.find((user) => user.userId === row.original.manager)?.givenName +
                  " " +
                  users?.find((user) => user.userId === row.original.manager)?.surname
                : ""}
            </span>
          </Box>
        ),
      },
      {
        accessorKey: "owner",
        header: "Owner",
        size: 10,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>
              {row.original.owner
                ? users?.find((user) => user.userId === row.original.owner)?.givenName +
                  " " +
                  users?.find((user) => user.userId === row.original.owner)?.surname
                : ""}
            </span>
          </Box>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 10,
        Cell: ({ renderedCellValue, row }) => (
          // <Box
          //   sx={{
          //     display: "flex",
          //     alignItems: "center",
          //     gap: "1rem",
          //     textTransform: "none",
          //     width: "10vh",
          //     whiteSpace: "pre-wrap",
          //     // borderLeft: `4px solid ${getStatusColor(row.original.status)}`,
          //   }}
          // >
          //   <div
          //     style={{
          //       position: "absolute",
          //       width: "5px",
          //       height: "100px", // Adjust height as needed
          //       backgroundColor: getStatusColor(row.original.status),
          //       top: "50%",
          //       transform: "translateY(-50%)",
          //       left: "-8px", // Adjust position of the line
          //     }}
          //   />
          //   <span>{status[row.original.status]}</span>
          // </Box>
          <div style={{ position: "relative", display: "inline-block" }}>
            <div
              style={{
                position: "absolute",
                width: "5px",
                height: "100px", // Adjust height as needed
                backgroundColor: getStatusColor(row.original.status, row.original.dueDate),
                top: "50%",
                transform: "translateY(-50%)",
                left: "-8px", // Adjust position of the line
              }}
            />
            <span>{status[row.original.status]}</span>
          </div>
        ),
      },
      {
        accessorKey: "dueDate",
        header: "Due On",
        size: 10,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>
              {row.original.dueDate
                ? new Date(row.original.dueDate).toLocaleDateString("en-AU")
                : ""}
            </span>
          </Box>
        ),
      },
    ],
    [users]
  );
  const handleExportRows = (rows: any) => {
    const doc = new jsPDF();
    //derive column headers and accesorKey from the columns array
    const tableHeaders = columns.map((column) => column.header);
    console.log("rows", rows);
    //create a new structure from columns accessorKey
    const colStruct = [
      { accessorKey: "referenceNumber", header: "Action REF" },
      { accessorKey: "owner", header: "Owner" },
      { accessorKey: "manager", header: "Manager" },
      { accessorKey: "action", header: "Action" },
      { accessorKey: "dueDate", header: "Due Date" },
      { accessorKey: "status", header: "Status" },
    ];

    //from rows.original get the values for each column using colStruct
    const tableData = rows.map((row: any) =>
      colStruct.map((col) => {
        if (col.accessorKey === "dueDate") {
          return new Date(row.original[col.accessorKey]).toLocaleDateString("en-AU");
        }
        if (col.accessorKey === "status") {
          return status[row.original[col.accessorKey]];
        }
        if (col.accessorKey === "owner" || col.accessorKey === "manager") {
          return (
            users?.find((user) => user.id === row.original[col.accessorKey])?.givenName +
            " " +
            users?.find((user) => user.id === row.original[col.accessorKey])?.surname
          );
        }
        return row.original[col.accessorKey];
      })
    );

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save("detail-actions.pdf");
    console.log("tableHeaders", tableHeaders);
    console.log("tableData", tableData);
  };
  //column definitions...
  const handleSaveAction: MRT_TableOptions<PolicyActionEntity>["onEditingRowSave"] = async ({
    values,
    table,
    row,
  }) => {
    console.log("values", values);
    await updateAction(values);
    table.setEditingRow(null); //exit editing mode
  };
  function useUpdateAction() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (action: PolicyActionEntity) => {
        //send api update request here
        console.log("here in mutation Fn");
        await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
        return Promise.resolve();
      },
      //client side optimistic update
      onMutate: (newActionInfo: PolicyActionEntity) => {
        console.log("newActionInfo", newActionInfo);
        queryClient.setQueryData(["actions"], (prevUsers: any) =>
          prevUsers?.map((prevUser: PolicyActionEntity) =>
            prevUser.id === newActionInfo.id ? newActionInfo : prevUser
          )
        );
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
    });
  }
  const { mutateAsync: updateAction, isPending: isUpdatingAction } = useUpdateAction();
  const handleClose = () => {
    console.log("Called onclose");
    setSelectedRowId("");
    setSelectedViewRowId("");
    setShowAdd(false);
    refetchActions();
    //setAlertText("Action added/edited successfully");
    //setShowAlert(true);
  };
  const handleDelete = async () => {
    console.log("deleteRowId", deleteRowId);
    const id = deleteRowId;
    //get adopted policies
    try {
      // const appTenantId = (accounts[0]?.idTokenClaims as IdTokenClaims)?.appTenantId;
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/rm/policy/control/${appTenantId}`
      );

      console.log("id", id);
      //find control actions that are adopted
      const control = response.data.find((policy: { actionIds: string | string[] }) =>
        policy.actionIds.includes(id)
      );

      let request = await axios.delete(
        `${process.env.REACT_APP_API_SERVER}/rm/policy/control/action/${appTenantId}/${control.id}/${id}`
      );
      setDeleted(true);
      refetchActions();

      setAlertText("Action deleted successfully");
      setShowAlert(true);
    } catch (error) {
      // setError("Error fetching data");
    }
  };

  // action filter
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const filterOpen = Boolean(anchorEl);
  const [selectedView, setSelectedView] = useState(
    isAdmin || isGlobalAdmin ? "All Actions" : "My Actions"
  );
  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = (view: React.SetStateAction<string>) => {
    setSelectedView(view);

    if (view === "My Actions") {
      setViewActions("M");
    } else if (view === "All Actions") {
      setViewActions("A");
    }
    refetchActions();
    setAnchorEl(null);
  };

  // delete action
  const [anchordEl, setAnchordEl] = React.useState<null | HTMLElement>(null);
  const deleteOpen = Boolean(anchordEl);

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchordEl(event.currentTarget);
  };

  const handleDeleteClose = () => {
    setAnchordEl(null);
  };
  const headers = Object.keys(extractedHeaders);

  // Convert data to the required format (array of arrays) with dynamic column mapping
  const convertedData = [
    headers,
    ...fetchedActions.map((record: PolicyActionEntity) =>
      headers.map((header: string) => {
        const key = extractedHeaders[header as keyof typeof extractedHeaders];
        if (key === "owner" || key === "manager") {
          if (
            record[key as keyof PolicyActionEntity] === null ||
            record[key as keyof PolicyActionEntity] === undefined
          )
            return "";
          return (
            users?.find(
              (user) => user.userId === (record[key as keyof PolicyActionEntity] as string)
            )?.givenName +
            " " +
            users?.find(
              (user) => user.userId === (record[key as keyof PolicyActionEntity] as string)
            )?.surname
          );
        } else if (key === "status") {
          return status[record[key as keyof PolicyActionEntity] as string];
        } else if (key === "frequency") {
          return frequency[record[key as keyof PolicyActionEntity] as string] ?? "Not Applicable";
        } else if (key === "actionRefId") {
          return Math.floor(
            parseFloat(record[key as keyof PolicyActionEntity] as string)
          ).toString();
        } else if (key === "principle") {
          return (record[key as keyof PolicyActionEntity] as string) === "G"
            ? "Govern"
            : (record[key as keyof PolicyActionEntity] as string) === "D"
            ? "Detect"
            : (record[key as keyof PolicyActionEntity] as string) === "P"
            ? "Protect"
            : (record[key as keyof PolicyActionEntity] as string) === "R"
            ? "Respond"
            : (record[key as keyof PolicyActionEntity] as string) === "N"
            ? "Manual"
            : (record[key as keyof PolicyActionEntity] as string);
        } else if (key === "importance") {
          return importance[record[key as keyof PolicyActionEntity] as string];
        } else if (key === "dueDate") {
          return new Date(record[key as keyof PolicyActionEntity] as Date).toLocaleDateString(
            "en-AU"
          );
        } else if (key === "source") {
          return record[key as keyof PolicyActionEntity] === "M" ? "Manual" : "System";
        } else if (key === "isTechAssistanceRequired") {
          return record[key as keyof PolicyActionEntity] ? "Yes" : "No";
        } else if (key === "isRecurring") {
          return record[key as keyof PolicyActionEntity] ? "Yes" : "No";
        } else if (key === "references") {
          const referencesString = Object.entries(record[key as keyof PolicyActionEntity])
            .map(([key, value]) => {
              // If value is an object, destructure its key-value pairs into strings
              const innerString = Object.entries(value)
                .map(([innerKey, innerValue]) => `${innerKey}: ${innerValue}`)
                .join(", ");
              return innerString;
            })
            .join(", ");
          return referencesString;
        } else if (key === "controlId") {
          if (record["source"] === "M") {
            return "None";
          } else
            return (
              adoptedPolicies.find(
                (policy) => policy.id === (record[key as keyof PolicyActionEntity] as String)
              )?.control || ""
            );
        } else {
          return record[key as keyof PolicyActionEntity] as string;
        }
      })
    ),
  ];
  console.log("convertedData", convertedData);
  const table = useMaterialReactTable({
    columns,
    data: fetchedActions, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: true,
    positionActionsColumn: "last",

    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default,
      boxShadow: "none !important",
      borderRadius: "none !important",
    }),
    muiTableBodyRowProps: {
      hover: false,
      sx: {
        whiteSpace: "nowrap",
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "none !important",
        // width: "1187px",
        // minWidth: "1187px",
      },
    },
    muiTableProps: {
      sx: {
        // border: "1px solid rgba(81, 81, 81, .5)",
        boxShadow: "none !important",
        whiteSpace: "nowrap",
        borderRadius: "none !important",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        border: "1px solid #EDEDED",
        // borderLeft: "1px solid rgba(81, 81, 81, .5)",
        // borderTop: "1px solid rgba(81, 81, 81, .5)",
        // fontStyle: "italic",
        fontWeight: "bold",
        fontSize: "14px",
        whiteSpace: "nowrap",
        textAlign: "center",
        boxShadow: "none",
        paddingLeft: "20px",
        backgroundColor: "#FBFBFF",
        color: "#111827 60%",
        height: "50px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderBottom: "1px solid rgba(81, 81, 81, .5)",
        // whiteSpace: "pre-wrap",
        // paddingLeft: "20px",
        // paddingRight: "20px",
        paddingTop: "10px",
        paddingBottom: "10px",
        border: "1px solid #EDEDED",
        // borderLeft: columns.accessorKey === "status" ? "none" : "1px solid #EDEDED",
      },
    },
    state: {
      isLoading: isLoadingActions,
      showAlertBanner: isLoadingActionsError,
      showProgressBars: isFetchingActions,
    },
    initialState: {
      sorting: [
        {
          id: "dueDate", //sort by age by default on page load
          desc: false,
        },
        {
          id: "status", //sort by age by default on page load
          desc: true,
        },
      ],
      density: "compact",
    },
    enableRowActions: true,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "Actions", //change "Actions" to "Edit"
        size: 10,
        //use a text button instead of a icon button
        Cell: ({ row, table }) => (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Button
              variant="contained"
              onClick={() => {
                console.log("e here", row);
                handleItemClick(row.original.id);
              }}
              disabled={row.original.status === "D" || row.original.status === "C"}
              sx={{ width: "122px", height: "35px" }}
            >
              Edit Action
            </Button>
            <IconButton
              sx={{ padding: "2px" }}
              onClick={(e) => {
                console.log("e here", row);
                setDeleteRowId(row.original.id);
                handleDeleteClick(e);
              }}
            >
              <MoreOption />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchordEl}
              open={deleteOpen}
              onClose={() => handleDeleteClose()}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={(e) => {
                  //console.log("e here", row);
                  //setDeleteRowId(row.original.id);
                  setConfirmOpen(true);
                  handleDeleteClose();
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </div>
        ),
      },
    },

    // renderToolbarInternalActions: ({ table }) => (
    //   <Box sx={{ display: "flex", gap: "20px", alignItems: "center", paddingRight: "10px" }}>
    //     <Button
    //       variant="contained"
    //       onClick={() => {
    //         handleAddClick();
    //       }}
    //     >
    //       Add Action
    //     </Button>
    //     {/* add custom button to print table  */}
    //     <Button startIcon={<Export />}>
    //       <CSVLink data={convertedData} filename={"ActionsList.csv"}>
    //         Export Data
    //       </CSVLink>
    //     </Button>
    //     <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
    //       <Typography fontSize="12px" color="#66696C">
    //         View:
    //       </Typography>
    //       <Typography fontSize="14px" color="#1E2429">
    //         {" "}
    //         {selectedView}
    //       </Typography>
    //       <IconButton sx={{ cursor: "pointer", padding: 0 }} onClick={handleFilterClick}>
    //         <Filter />
    //       </IconButton>
    //       <Menu
    //         id="basic-menu"
    //         anchorEl={anchorEl}
    //         open={filterOpen}
    //         onClose={() => handleFilterClose(selectedView)}
    //         MenuListProps={{
    //           "aria-labelledby": "basic-button",
    //         }}
    //       >
    //         {isAdmin && (
    //           <MenuItem onClick={() => handleFilterClose("All Actions")}>All Actions</MenuItem>
    //         )}
    //         <MenuItem onClick={() => handleFilterClose("My Actions")}>My Actions</MenuItem>
    //       </Menu>
    //     </Box>
    //   </Box>
    // ),
  });
  const handleChange = (event: React.MouseEvent<HTMLElement>, newView: string) => {
    setViewActions(newView);
  };

  const account = instance.getActiveAccount();
  return (
    <div>
      {showAlert && (
        <Snackbar
          open={showAlert}
          autoHideDuration={5000}
          onClose={() => setShowAlert(false)}
          message={alertText}
        />
      )}
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingRight: "10px",
          position: "relative",
          top: "-35px",
          marginLeft: "428px",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            handleAddClick();
          }}
        >
          Add Action
        </Button>
        {/* add custom button to print table  */}
        <Button startIcon={<Export />}>
          <CSVLink data={convertedData} filename={"ActionsList.csv"}>
            Export Data
          </CSVLink>
        </Button>
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <Typography fontSize="12px" color="#66696C">
            View:
          </Typography>
          <Typography fontSize="14px" color="#1E2429">
            {" "}
            {selectedView}
          </Typography>
          <IconButton sx={{ cursor: "pointer", padding: 0 }} onClick={handleFilterClick}>
            <Filter />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={filterOpen}
            onClose={() => handleFilterClose(selectedView)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {(isAdmin || isGlobalAdmin) && (
              <MenuItem onClick={() => handleFilterClose("All Actions")}>All Actions</MenuItem>
            )}
            <MenuItem onClick={() => handleFilterClose("My Actions")}>My Actions</MenuItem>
          </Menu>
        </Box>
      </Box>

      <MaterialReactTable table={table} />

      {selectedRowId !== "" && (
        <EditAction actionId={selectedRowId} isSystemAction={true} onClose={handleClose} />
      )}
      {selectedViewRowId !== "" && (
        <ViewAction actionId={selectedViewRowId} isSystemAction={true} onClose={handleClose} />
      )}
      {showAdd && <AddAction isSystemAction={true} onClose={handleClose} />}
      <ConfirmDialog
        title="Delete Action?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}
      >
        Are you sure you want to delete this action?
      </ConfirmDialog>
    </div>
  );
};

const ActionList: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <DetailWorkflow />
    </QueryClientProvider>
  );
};

export default ActionList;
