import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";

const JwtInterceptor = () => {
  const { instance } = useMsal();
  const oldXHROpen = window.XMLHttpRequest.prototype.open;

  const interceptor = function (this: any, ...args: any[]): void {
    // do something with the method, url and etc.
    this.addEventListener("readystatechange", () => {
      // do something with the response text
      const isLoggedIn = sessionStorage.getItem("accessToken") !== null;
      if (isLoggedIn && this.readyState === XMLHttpRequest.OPENED) {
        this.setRequestHeader("Accept", "application/json");
        // this.headers.Authorization = [];
        this.setRequestHeader("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
      }
      if (isLoggedIn && this.readyState === XMLHttpRequest.DONE && this.status === 403) {
        instance.logoutRedirect({
          postLogoutRedirectUri: `${process.env.REACT_APP_MAIN_WEBSITE_URL}` + "/",
        });
      }
      if (isLoggedIn && this.readyState === XMLHttpRequest.DONE && this.status === 401) {
        instance.logoutRedirect({
          postLogoutRedirectUri: `${process.env.REACT_APP_MAIN_WEBSITE_URL}` + "/",
        });
      }
    });

    return oldXHROpen.apply(this, [...args] as [string, string | URL, boolean, string?, string?]);
  };

  useEffect(() => {
    window.XMLHttpRequest.prototype.open = interceptor;

    // Cleanup function
    return () => {
      window.XMLHttpRequest.prototype.open = oldXHROpen;
    };
  }, []); // Empty dependency array ensures the effect runs only once after initial render

  // Return an empty function since useEffect requires a return value
  return <div></div>; // Render your application content here
};

export default JwtInterceptor;
