import { type ChangeEvent, type FocusEvent, type KeyboardEvent, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { type TextFieldProps } from "@mui/material/TextField";
import {
  type MRT_Row,
  type MRT_Cell,
  type MRT_RowData,
  type MRT_TableInstance,
} from "material-react-table";
import { getValueAndLabel, parseFromValuesOrFunc } from "../utils/utils";
import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import { ReactComponent as Upload } from "../../assets/cloud_upload.svg";
import { ReactComponent as ArrowDown } from "../../assets/keyboard_arrow_down.svg";
import Dropzone from "react-dropzone";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

// customised tooltip
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F8F8FF",
    color: "#000706",
    fontSize: 14,
    width: "270px",
    maxWidth: "none",
    // height: "40px",
    maxHeight: "none",
    boxShadow: "0px 4px 16px 0px #0000004D",
    padding: "8px",
  },
}));
export interface RRM_EditCellTextFieldProps<TData extends MRT_RowData>
  extends TextFieldProps<"standard"> {
  table: MRT_TableInstance<TData>;
  row: MRT_Row<TData>;
  accessKey: string;
  labelName?: string;
  disabled?: boolean;
  tooltip?: string;
  editable?: boolean;
}

export const RRM_EditCellTextField = <TData extends MRT_RowData>({
  table,
  row,
  accessKey,
  labelName,
  tooltip,
  disabled,
  editable,
  ...rest
}: RRM_EditCellTextFieldProps<TData>) => {
  const {
    getState,
    options: { createDisplayMode, editDisplayMode, muiEditTextFieldProps },
    refs: { editInputRefs },
    setCreatingRow,
    setEditingCell,
    setEditingRow,
  } = table;
  const cell = row
    .getAllCells()
    .filter((cell) => cell.column.columnDef.accessorKey === accessKey)[0];
  const { column } = cell;
  const { columnDef } = column;
  const { creatingRow, editingRow } = getState();
  const { editSelectOptions, editVariant } = columnDef;

  const isCreating = creatingRow?.id === row.id;
  const isEditing = editingRow?.id === row.id;

  const [value, setValue] = useState(() => cell.getValue<string>());
  const [fileValue, setFileValue] = useState(() => cell.getValue<string>());

  const textFieldProps: TextFieldProps = {
    ...parseFromValuesOrFunc(muiEditTextFieldProps, {
      cell,
      column,
      row,
      table,
    }),
    ...parseFromValuesOrFunc(columnDef.muiEditTextFieldProps, {
      cell,
      column,
      row,
      table,
    }),
    ...rest,
  };

  const selectOptions = parseFromValuesOrFunc(editSelectOptions, {
    cell,
    column,
    row,
    table,
  });

  const isSelectEdit = editVariant === "select" || textFieldProps?.select;

  const saveInputValueToRowCache = (newValue: string) => {
    //@ts-ignore
    row._valuesCache[column.id] = newValue;
    if (isCreating) {
      setCreatingRow(row);
    } else if (isEditing) {
      setEditingRow(row);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    textFieldProps.onChange?.(event);
    setValue(event.target.value);
    setFileValue(event.target.value);
    if (isSelectEdit) {
      saveInputValueToRowCache(event.target.value);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    textFieldProps.onBlur?.(event);
    saveInputValueToRowCache(value);
    setEditingCell(null);
  };

  const handleEnterKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    textFieldProps.onKeyDown?.(event);
    if (event.key === "Enter" && !event.shiftKey) {
      editInputRefs.current[column.id]?.blur();
    }
  };

  if (columnDef.Edit) {
    return <>{columnDef.Edit?.({ cell, column, row, table })}</>;
  }
  const onDrop = async (acceptedFiles: any) => {
    // console.log(acceptedFiles);
    // // Check if the file with the same name already exists
    // const isDuplicate = files.some((file: any) => file.name === acceptedFiles[0].name);
    // if (!isDuplicate) {
    //   // If it's not a duplicate, add the file to the files array
    //   setFiles([...files, acceptedFiles[0]]);
    // } else {
    //   console.log("File with the same name already exists!");
    //   // If it's a duplicate, set the error message
    //   setErrorMessage("File with the same name already exists!");
    // }
  };

  // console.log("file value", value);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        gap: "5px",
        width: "100%",
        // display: disabled ? "none" : undefined,
      }}
      style={{ display: disabled ? "none" : undefined }}
    >
      {!disabled && (
        <LightTooltip
          title={tooltip}
          arrow
          // placement="bottom"
        >
          <Typography
            fontSize="14px"
            color="rgba(17, 24, 39, 0.6)"
            fontWeight="600"
            sx={{ marginLeft: "5px" }}
            style={{ cursor: "pointer" }}
          >
            {["custom", "modal"].includes(
              (isCreating ? createDisplayMode : editDisplayMode) as string
            )
              ? labelName || columnDef.header
              : undefined}

            {textFieldProps?.required ? <span style={{ color: "red" }}>*</span> : <span></span>}
          </Typography>
        </LightTooltip>
      )}
      {accessKey === "fileUpload" ? (
        <TextField
          disabled={parseFromValuesOrFunc(columnDef.enableEditing, row) === false}
          fullWidth
          inputRef={(inputRef) => {
            if (inputRef) {
              editInputRefs.current[column.id] = inputRef;
              if (textFieldProps.inputRef) {
                textFieldProps.inputRef = inputRef;
              }
            }
          }}
          // label={
          //   ["custom", "modal"].includes((isCreating ? createDisplayMode : editDisplayMode) as string)
          //     ? columnDef.header
          //     : undefined
          // }
          margin="none"
          name={column.id}
          placeholder={
            !["custom", "modal"].includes(
              (isCreating ? createDisplayMode : editDisplayMode) as string
            )
              ? columnDef.header
              : undefined
          }
          select={isSelectEdit}
          size="small"
          value={value ?? ""}
          // variant="standard"
          variant="outlined"
          {...textFieldProps}
          InputProps={{
            ...(textFieldProps.variant !== "outlined"
              ? { disableUnderline: editDisplayMode === "table" }
              : {}),
            ...textFieldProps.InputProps,
            sx: (theme) => ({
              mb: 0,
              ...(parseFromValuesOrFunc(textFieldProps?.InputProps?.sx, theme) as any),
            }),
          }}
          SelectProps={{
            MenuProps: { disableScrollLock: true },
            style: { fontSize: "14px", borderRadius: "5px" },
          }}
          inputProps={{
            autoComplete: "new-password", //disable autocomplete and autofill
            ...textFieldProps.inputProps,
            // style: { fontSize: "14px", height: "44px", borderRadius: "5px" },
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={(e) => {
            e.stopPropagation();
            textFieldProps?.onClick?.(e);
          }}
          onKeyDown={handleEnterKeyDown}
        >
          {textFieldProps.children ??
            selectOptions?.map((option) => {
              const { label, value } = getValueAndLabel(option);
              return (
                <MenuItem
                  key={value}
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    gap: "0.5rem",
                    m: 0,
                  }}
                  value={value}
                >
                  {label}
                </MenuItem>
              );
            })}
        </TextField>
      ) : (
        <TextField
          disabled={
            parseFromValuesOrFunc(columnDef.enableEditing, row) === false || editable === false
          }
          fullWidth
          inputRef={(inputRef) => {
            if (inputRef) {
              editInputRefs.current[column.id] = inputRef;
              if (textFieldProps.inputRef) {
                textFieldProps.inputRef = inputRef;
              }
            }
          }}
          // label={
          //   ["custom", "modal"].includes((isCreating ? createDisplayMode : editDisplayMode) as string)
          //     ? columnDef.header
          //     : undefined
          // }
          margin="none"
          name={column.id}
          placeholder={
            !["custom", "modal"].includes(
              (isCreating ? createDisplayMode : editDisplayMode) as string
            )
              ? columnDef.header
              : undefined
          }
          select={isSelectEdit}
          size="small"
          value={value ?? ""}
          // variant="standard"
          variant="outlined"
          {...textFieldProps}
          InputProps={{
            ...(textFieldProps.variant !== "outlined"
              ? { disableUnderline: editDisplayMode === "table" }
              : {}),
            ...textFieldProps.InputProps,
            sx: (theme) => ({
              mb: 0,
              ...(parseFromValuesOrFunc(textFieldProps?.InputProps?.sx, theme) as any),
            }),
          }}
          SelectProps={{
            MenuProps: { disableScrollLock: true },
            style: { fontSize: "14px", borderRadius: "5px" },
          }}
          inputProps={{
            autoComplete: "new-password", //disable autocomplete and autofill
            ...textFieldProps.inputProps,
            // style: { fontSize: "14px", height: "44px", borderRadius: "5px" },
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={(e) => {
            e.stopPropagation();
            textFieldProps?.onClick?.(e);
          }}
          onKeyDown={handleEnterKeyDown}
          multiline={
            accessKey === "otherVendorInformation" ||
            accessKey === "serviceDetails" ||
            accessKey === "notes" ||
            accessKey === "otherVendorInformation" ||
            accessKey === "systemSummary"
          }
        >
          {textFieldProps.children ??
            selectOptions?.map((option) => {
              const { label, value } = getValueAndLabel(option);
              return (
                <MenuItem
                  key={value}
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    gap: "0.5rem",
                    m: 0,
                  }}
                  value={value}
                >
                  {label}
                </MenuItem>
              );
            })}
        </TextField>
      )}
    </Box>
  );
};
