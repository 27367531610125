import { useEffect, useState } from "react";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import { loginRequest, b2cPolicies, deployment } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

export const SignUpSignIn = () => {
  const { instance } = useMsal();
  const [redirecting, setRedirecting] = useState(true);

  useEffect(() => {
    const redirectPage = async () => {
      if (redirecting) {
        sessionStorage.clear();
        await instance
          .loginRedirect({
            scopes: loginRequest.scopes,
            redirectUri: deployment.baseUrl + "/loggedin",
            prompt: "login", // will need to check on mobile
          })
          .then(() => {
            console.log("Redirected to login page");
            setRedirecting(false);
          })
          .catch((err) => {
            console.log("Error in redirecting to login page", err);
            console.error(err);
          });
        await instance
          .handleRedirectPromise()
          .then((res) => {
            console.log("in Sign Up", res);
          })
          .catch((err) => {
            console.log("Error in redirect promise to login page", err);
            console.error(err);
          });

        setRedirecting(false); // Stop redirecting after the initial call
      }
    };
    redirectPage();
  }, [redirecting, instance]);

  return <div>{redirecting && <p>Loading...</p>}</div>;
};
