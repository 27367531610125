import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";

const LogoutTimer = () => {
  const { instance } = useMsal();
  const [logoutTimestamp, setLogoutTimestamp] = useState<number | null>(null);
  const TIMEOUT_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

  useEffect(() => {
    const resetTimer = () => {
      const storedTimestamp = sessionStorage.getItem("logoutTimestamp");
      const currentTime = new Date().getTime();

      if (storedTimestamp) {
        const timeElapsed = currentTime - parseInt(storedTimestamp, 10);
        if (timeElapsed >= TIMEOUT_DURATION) {
          // User session has expired
          logout();
        } else {
          const remainingTime = TIMEOUT_DURATION - timeElapsed;
          setLogoutTimestamp(currentTime);
          setTimeout(logout, remainingTime);
        }
      } else {
        // Start the timer
        setLogoutTimestamp(currentTime);
        setTimeout(logout, TIMEOUT_DURATION);
      }
    };

    const logout = () => {
      console.log("User logged out due to inactivity");
      sessionStorage.clear();
      instance.logoutRedirect({
        postLogoutRedirectUri: `${process.env.REACT_APP_MAIN_WEBSITE_URL}/`,
      });
    };

    const handleUserActivity = () => {
      // Reset the timer on user activity
      const currentTime = new Date().getTime();
      setLogoutTimestamp(currentTime);
    };

    // Initialize the timer
    resetTimer();

    // Add event listeners to track user activity
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
    };
  }, []);

  return <div></div>; // Render your application content here
};

export default LogoutTimer;
