// CyberQADynamicForm.tsx
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stepper,
  Step,
  StepLabel,
  Stack,
  List,
  ListItem,
  CircularProgress,
} from "@mui/material";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import MainDashboardWithProviders from "./MainDashboard";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import { StepIconProps } from "@mui/material/StepIcon";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { ReactComponent as BackIcon } from "../../assets/arrow_back_ios.svg";
import { ReactComponent as QuestionIcon } from "../../assets/help.svg";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

// customised tooltip for question and answer options
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#000706",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));
export interface Question {
  subPrincipleCode: string;
  subPrinciple: string;
  questionId: string;
  questionOrder: string | null;
  question: string;
  options: Array<any>; // Dropdown options
  dependsOn: string; // ID of the question that this question depends on
  textForQuestion: string; // References linked to each option
  conditionalValues?: Record<string, Question[]>; // Map of possible values based on conditions
}

interface CyberQADynamicFormProps {
  orginalQuestions: Question[];
}
type iData = {
  isCyberSecurityQACompleted: boolean;
  cyberSecurityQA: [];
  lastUpdateDate: string;
};
type IdTokenClaims = {
  roles: string[];
  idp: string;
  email: string;
  signInName: string;
  appTenantName: string;
  sub: string;
  appTenantId: string;
  questionId: string;
  acr: any;
  allTenants: any[];
};
const CyberQADynamicForm: React.FC<CyberQADynamicFormProps> = ({ orginalQuestions }) => {
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());

  // check depending questions
  const evaluateCondition = (dependsOnValue: string, options: any[]) => {
    // Split the dependsOnValue to get the key and expected value
    const key = dependsOnValue;
    const expectedValue = dependsOnValue;
    // console.log("key", key);

    // Find the corresponding option
    const sanitizedExpectedValue = expectedValue;
    if ((formik.values[key] as string) === "Yes") {
      return true;
    }
    return false;
  };
  const validationSchema = Yup.object().shape(
    orginalQuestions.reduce((acc, question) => {
      (acc as Record<string, any>)[question.questionId] = Yup.string().required(
        `Please select a value for ${question.question}`
      );
      return acc;
    }, {} as Record<string, any>)
  );

  const formik = useFormik({
    initialValues: orginalQuestions
      .filter((question) => question.dependsOn === "")
      .reduce((acc, question) => {
        (acc as Record<string, any>)[question.questionId] = "";
        return acc;
      }, {} as Record<string, any>),
    // validationSchema,
    onSubmit: (values) => {
      console.log("Form submitted with values:", values);
    },
  });

  // check to add dependOn question
  const questions = orginalQuestions
    // Modify this part of the filtering condition
    .filter(
      (question) =>
        question.dependsOn === "" || evaluateCondition(question.dependsOn, question.options)
    );

  const appTenantId = (accounts[0].idTokenClaims as IdTokenClaims).appTenantId;
  const appTenantName = (accounts[0].idTokenClaims as IdTokenClaims).appTenantName;

  const [loading, setLoading] = useState(true);
  const [showQandA, setShowQandA] = useState(false);

  const [cyberSecurityQAexists, setCyberSecurityQAexists] = useState(false);
  const [error, setError] = useState("");
  const [assessmentTriggered, setAssessmentTriggered] = useState(false);

  // Function to find the option id based on questionId and option value
  function findOptionId(questionId: string, optionValue: string) {
    const question = questions.find((q) => q.questionId === questionId);

    if (question) {
      const option = question.options.find((o) => o.value === optionValue);

      if (option) {
        return option.id;
      } else {
        return null; // Option not found for the provided value
      }
    } else {
      return null; // Question not found for the provided questionId
    }
  }

  // =====================new stepper====================
  const [activeStep, setActiveStep] = React.useState(0);
  // Calculate the number of steps based on the number of questions
  const numQuestions = questions.length;
  const numSteps = Math.ceil(numQuestions / 3) + 1;

  // Generate the steps array with empty labels
  const steps = Array.from({ length: numSteps }, (_, index) => ``);

  const currentStepQuestions = questions.slice(activeStep * 3, activeStep * 3 + 3);
  const isButtonDisabled = currentStepQuestions.some(
    (question) => !formik.values[question.questionId]
  );

  // run assessment
  const runAssesment = async () => {
    await saveSession(); //save session first before running assessment

    // Create a new array of objects with selected answers and their references and obligations
    const transformedArray = Object.keys(formik.values).map((questionId) => ({
      questionId: questionId,
      responses: {
        [questionId]: [
          {
            [findOptionId(questionId, formik.values[questionId])]: formik.values[questionId],
          },
        ],
      },
    }));

    try {
      let response;
      response = await axios.post(
        `${process.env.REACT_APP_API_SERVER}/rm/org/cybersecurity/qa/assessment`,
        {
          assessments: transformedArray,
          orgName: appTenantName,
          tenantId: appTenantId,
          isCyberSecurityQAComplete: true,
        }
      );

      let response1;
      response1 = await axios.post(
        `${process.env.REACT_APP_API_SERVER}/rm/policy/control/actions/initial/${appTenantId}`,
        {
          assessments: transformedArray,
          orgName: appTenantName,
          tenantId: appTenantId,
          isCyberSecurityQAComplete: true,
        }
      );
      if (response.status === 201 && response1.status === 201) {
        window.location.reload();
      }
    } catch (error) {
      // Handle errors if any of the API calls fail
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/org/cybersecurity/qa/${appTenantId}`
        ); // Replace with your API endpoint

        if (response.data) {
          if (response.data?.isCyberSecurityQAComplete) {
            setShowQandA(false);
          } else {
            // Update the form with data from the API response
            formik.setValues({
              ...formik.values,
              ...response.data.assessments.reduce(
                (
                  acc: { [x: string]: any },
                  assessment: {
                    questionId: string;
                    responses: { [key: string]: [{ [key: string]: string }] };
                  }
                ) => {
                  const questionId = assessment.questionId;
                  const responseArray = assessment.responses[questionId];
                  if (responseArray && responseArray.length > 0) {
                    const optionId = Object.keys(responseArray[0])[0];
                    const optionResponse = responseArray[0][optionId];
                    // console.log("optionResponse", optionResponse);
                    acc[questionId] = optionResponse;
                  }
                  return acc;
                },
                {} as Record<string, any>
              ),
            });
            setShowQandA(true);
            setCyberSecurityQAexists(true);

            // set question
            const answeredQuestions = response.data.assessments.reduce(
              (acc: any[], assessment: { questionId: any; responses: any }) => {
                const { questionId, responses } = assessment;
                const responseKeys = Object.keys(responses[questionId][0]);
                const isAnswered = responseKeys.some(
                  (key) =>
                    responses[questionId][0][key] !== null && responses[questionId][0][key] !== ""
                );

                if (isAnswered) {
                  acc.push(questionId);
                }

                return acc;
              },
              []
            );

            const firstUnansweredQuestionIndex = questions.findIndex(
              (question) => !answeredQuestions.includes(question.questionId)
            );

            // start from the step with the first unanswered question
            const currentStep = Math.floor(firstUnansweredQuestionIndex / 3);
            setActiveStep(currentStep);
          }
        } else {
          setShowQandA(true);
          setCyberSecurityQAexists(false);
        }
      } catch (error) {
        setError("Error fetching data");
        setShowQandA(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // to store answers
  const handleAnswerSelect = (questionId: string, answerId: string) => {
    formik.setValues((prev) => ({ ...prev, [questionId]: answerId }));
    // formik.setFieldValue(questionId, answerId);
  };

  const checkIfAllQuestionsAnswered = async () => {
    //check if the cyber security assessment is already completed
    try {
      let completedResponse = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/rm/org/cybersecurity/qa/${appTenantId}`
      );
      console.log("completedResponse", JSON.stringify(completedResponse));
      if (completedResponse.data.isCyberSecurityQAComplete) {
        alert(" You have already completed the assesment. Navigating to the Dashboard.");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  const saveSession = async () => {
    const transformedArray = Object.keys(formik.values).map((questionId) => ({
      questionId: questionId,
      responses: {
        [questionId]: [
          {
            [findOptionId(questionId, formik.values[questionId])]: formik.values[questionId],
          },
        ],
      },
    }));

    let response;
    if (cyberSecurityQAexists) {
      //check if cyber security assesment exists before putting
      let exists = false;
      try {
        const responseExists = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/org/cybersecurity/qa/${appTenantId}`
        ); // Replace with your API endpoint
        exists = true;
      } catch (error) {
        exists = false;
      }

      if (exists) {
        response = await axios.put(`${process.env.REACT_APP_API_SERVER}/rm/org/cybersecurity/qa`, {
          assessments: transformedArray,
          orgName: appTenantName,
          tenantId: appTenantId,
        });
      } else {
        alert(
          "You have commenced the assessment in another session, kindly complete the assessment in the other session. Reloading."
        );
        window.location.reload();
      }
    } else {
      let exists = false;
      try {
        const responseExists = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/org/cybersecurity/qa/${appTenantId}`
        ); // Replace with your API endpoint
        exists = true;
      } catch (error) {
        exists = false;
      }

      if (!exists) {
        response = await axios.post(`${process.env.REACT_APP_API_SERVER}/rm/org/cybersecurity/qa`, {
          assessments: transformedArray,
          orgName: appTenantName,
          tenantId: appTenantId,
        });
        setCyberSecurityQAexists(true);
      } else {
        alert(
          "You have commenced the assessment in another session, kindly complete the assessment in the other session. Navigating to the Dashboard."
        );
        window.location.reload();
      }
    }
  };

  // handle next and back
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // customised stepper connector
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#233DFF",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#233DFF",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,

      backgroundColor: "#EDEDED",
      borderRadius: 1,
    },
  }));

  // customised step icon
  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean; isLastStep?: boolean };
    isLastStep?: boolean;
  }>(({ theme, ownerState, isLastStep }) => ({
    border: "1px solid #D4D4D4",
    zIndex: 1,
    color: "#66696C",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      border: "2px solid #233DFF",
    }),

    ...(ownerState.completed || isLastStep
      ? {
          background: "#233DFF",
          border: "2px solid #233DFF",
          color: "#fff",
        }
      : {}),
  }));

  // customised step icon
  const ColorlibStepIconRootSmall = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean; isLastStep?: boolean };
    isLastStep?: boolean;
  }>(({ theme, ownerState, isLastStep }) => ({
    border: "1px solid #D4D4D4",
    zIndex: 1,
    color: "#66696C",
    width: 18,
    height: 18,
    fontSize: "8px",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      border: "2px solid #233DFF",
    }),

    ...(ownerState.completed || isLastStep
      ? {
          background: "#233DFF",
          border: "2px solid #233DFF",
          color: "#fff",
        }
      : {}),
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className, icon } = props;
    const isLastStep = icon === numSteps && active === true;

    // Generate spans dynamically based on numSteps
    const icons: { [index: string]: React.ReactElement } = {};
    for (let i = 1; i <= numSteps; i++) {
      icons[i] = <span key={i}>{i}</span>;
    }

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
        isLastStep={isLastStep}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  function ColorlibStepIconSmall(props: StepIconProps) {
    const { active, completed, className, icon } = props;
    const isLastStep = icon === numSteps && active === true;

    // Generate spans dynamically based on numSteps
    const icons: { [index: string]: React.ReactElement } = {};
    for (let i = 1; i <= numSteps; i++) {
      icons[i] = <span key={i}>{i}</span>;
    }

    return (
      <ColorlibStepIconRootSmall
        ownerState={{ completed, active }}
        className={className}
        isLastStep={isLastStep}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRootSmall>
    );
  }

  // render options
  const renderOptions = (question: Question) => {
    const selectedOptions = formik.values[question.questionId as keyof typeof formik.values] || [];

    return (
      <div>
        <div>
          <RadioGroup
            name={question.questionId}
            defaultValue={undefined}
            value={formik.values[question.questionId as keyof typeof formik.values]}
            onChange={(e) => handleAnswerSelect(question.questionId, e.target.value)}
            key={question.questionId}
          >
            {question.options.map((option) => (
              // eslint-disable-next-line react/jsx-key
              <LightTooltip
                // title={option.textForAnswer}
                title={
                  option.textForAnswer && (
                    <>
                      <Typography fontSize="14px" color="#66696C">
                        Explanation:
                      </Typography>
                      {/* <br /> */}

                      {option.textForAnswer}
                    </>
                  )
                }
                key={option.id}
                placement="bottom"
                style={{ backgroundColor: "white" }}
              >
                <FormControlLabel
                  value={option.value}
                  control={<Radio color="primary" size="small" />}
                  label={
                    <Typography
                      fontSize="14px"
                      style={{
                        fontWeight:
                          formik.values[question.questionId as keyof typeof formik.values] ===
                          option
                            ? "bold" // Bold font when selected
                            : "500", // Normal font weight when not selected
                      }}
                      key={option.id}
                    >
                      {option.value}
                    </Typography>
                  }
                  style={{
                    color:
                      formik.values[question.questionId as keyof typeof formik.values] ===
                      option.value
                        ? "#233DFF" // Font color when selected
                        : "black",
                    fontWeight:
                      formik.values[question.questionId as keyof typeof formik.values] ===
                      option.value
                        ? "bold" // Bold font when selected
                        : "normal", // Normal font weight when not selected
                  }}
                />
              </LightTooltip>
            ))}
          </RadioGroup>
        </div>
      </div>
    );
  };

  // render question
  const renderQuestion = (question: Question) => {
    const conditionalValues = question.conditionalValues || {};
    const hasError = !!formik.errors[question.questionId];

    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
          <Typography fontSize="14px" fontWeight="500" width="81%" color="#1E2429">
            {question.question}
          </Typography>
          <LightTooltip
            title={
              question.textForQuestion && (
                <>
                  <Typography fontSize="14px" color="#66696C">
                    Explanation:
                  </Typography>
                  {/* <br /> */}

                  {question.textForQuestion}
                </>
              )
            }
            key={question.questionId}
            placement="bottom"
            style={{ backgroundColor: "white" }}
          >
            <QuestionIcon style={{ width: "24px", height: "24px" }} />
          </LightTooltip>
        </div>
        <div style={{ width: "100%", marginTop: "10px" }}>
          {renderOptions(question)}
          {Object.keys(conditionalValues).map((dependentValue) => {
            const dependentQuestions = conditionalValues[dependentValue];
            return dependentValue === formik.values[question.dependsOn || ""]
              ? dependentQuestions.map((dependentQuestion: Question) => (
                  <div key={dependentQuestion.questionId}>
                    {renderQuestion(dependentQuestion)}
                    {formik.errors[dependentQuestion.questionId] && (
                      <div style={{ color: "red" }}></div>
                    )}
                  </div>
                ))
              : null;
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      {loading && <div>Loading...</div>}

      {!loading && showQandA ? (
        <div
          style={{ margin: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}
        >
          <div style={{ textAlign: "center", marginBottom: "10px", marginTop: "10px" }}>
            <Typography fontSize="20px" fontWeight="600" color="#1E2429" sx={{ mb: 1 }}>
              Cyber Security Q&A
            </Typography>
            <Typography fontSize="14px" fontWeight="500" color="#66696C">
              The following questions allow us to understand your Organisation’s current cyber
              security set up, and allow us to generate an action plan for continual improvement to
              mitigate cyber security risks
            </Typography>
          </div>
          <Box
            sx={{
              display: { xs: "none", md: "block" },
              width: {
                md: "834px",
              },
            }}
            style={{
              marginTop: activeStep !== numSteps - 1 ? "20px" : "20px",
              // width: "834px",
              marginBottom: "40px",
              alignItems: "center",
            }}
          >
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box
            sx={{
              display: { md: "none" },

              width: {
                xs: "400px",
              },
            }}
            style={{
              marginTop: activeStep !== numSteps - 1 ? "20px" : "20px",
              // width: "834px",
              marginBottom: "40px",
              alignItems: "center",
            }}
          >
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIconSmall}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {activeStep === steps.length - 1 ? (
            <Box>
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Stack direction="column" spacing={8}>
                  <Stack direction="column" spacing={4}>
                    <Box style={{ textAlign: "center" }}>
                      <Typography fontSize="14px">
                        Thanks, we have recorded your responses, you can check them
                      </Typography>
                      <Typography fontSize="14px">
                        again by going back to previous sections.
                      </Typography>
                    </Box>
                  </Stack>

                  <Stack direction="row" spacing={5}>
                    {activeStep !== 0 && (
                      <Button
                        onClick={handleBack}
                        startIcon={<BackIcon />}
                        style={{ color: "#66696C" }}
                      >
                        Previous Section
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      disabled={assessmentTriggered}
                      //  onClick={handleSignUp}
                      onClick={async () => {
                        try {
                          if (!assessmentTriggered) {
                            setAssessmentTriggered(true);
                            await runAssesment();
                            setShowQandA(false);
                          }
                        } catch (error) {
                          console.error("Error running assessment:", error);
                          // Handle the error (e.g., display an error message)
                        }
                      }}
                    >
                      {assessmentTriggered && <CircularProgress color="inherit" size={18} />}
                      Go To Dashboard
                    </Button>
                  </Stack>
                </Stack>
              </div>
            </Box>
          ) : (
            <>
              <form onSubmit={formik.handleSubmit}>
                {steps.map(
                  (label, index) =>
                    activeStep === index && (
                      <Box key={label} style={{ display: "flex", flexDirection: "row" }}>
                        <List
                          sx={{
                            display: "flex",
                            flexDirection: {
                              xs: "column", // For extra small screens
                              md: "row", // For medium screens
                            },
                            alignItems: "flex-start",
                          }}
                          // style={{
                          //   display: "flex",
                          //   flexDirection: "row",
                          //   alignItems: "flex-start",
                          // }}
                        >
                          {questions
                            .slice(index * 3, index * 3 + 3)
                            .map((question, questionIndex) => (
                              <React.Fragment key={question.questionId}>
                                <ListItem>{renderQuestion(question)}</ListItem>
                                {questionIndex !==
                                  questions.slice(index * 3, index * 3 + 3).length - 1 && (
                                  <Box
                                    sx={{
                                      borderRight: { xs: "none", md: "1px solid #D4D4D4" },
                                      borderBottom: { md: "none", xs: "1px solid #D4D4D4" },
                                      height: {
                                        md: "100%",
                                        xs: "0px",
                                      } /* Adjust this according to your layout */,
                                      width: {
                                        md: "0px",
                                        xs: "100%",
                                      },
                                      margin: "0 10px",
                                    }}
                                  ></Box>
                                )}
                              </React.Fragment>
                            ))}
                        </List>
                      </Box>
                    )
                )}
              </form>
              <Stack
                direction={{ md: "row", xs: "column-reverse" }}
                spacing={{ md: 5, xs: 3 }}
                sx={{ marginTop: "40px" }}
              >
                {activeStep !== 0 && (
                  <Button
                    onClick={handleBack}
                    startIcon={<BackIcon />}
                    style={{ color: "#66696C" }}
                  >
                    Previous Step
                  </Button>
                )}
                <Button
                  variant="contained"
                  type="submit"
                  onClick={async () => {
                    try {
                      if (await checkIfAllQuestionsAnswered()) {
                        window.location.reload();
                        return;
                      }
                      if (activeStep === numSteps - 2) {
                        // renderReferencesObligationsTable();
                        await formik.handleSubmit();
                        handleNext();
                        await saveSession();
                      } else {
                        handleNext();
                        await saveSession();
                      }
                    } catch (error) {
                      console.error("Error:", error);
                      // Handle error
                    }
                  }}
                  disabled={isButtonDisabled}
                >
                  Save and Continue
                </Button>
              </Stack>
            </>
          )}
        </div>
      ) : (
        <MainDashboardWithProviders />
        //show dashboard
      )}
    </div>
  );
};

export default CyberQADynamicForm;
