// GlobalStateContext.tsx
import { useMsal } from "@azure/msal-react";
import React, { createContext, useContext, ReactNode, useState } from "react";

type AccountInfo = {
  homeAccountId: string;
  environment: string;
  tenantId: string;
  username: string;
  localAccountId: string;
  name?: string | undefined;
  idTokenClaims?: object | undefined;
};
// Define types for your state
type State = {
  account: AccountInfo;
};

// Create the context with initial state and update function
const GlobalStateContext = createContext<
  { state: State; setState: React.Dispatch<React.SetStateAction<State>> } | undefined
>(undefined);

// Define the provider that includes the state and update function
type GlobalStateProviderProps = { children: ReactNode };
const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
  const { accounts } = useMsal();
  const initialState: State = {
    account: accounts[0],
  };

  const [state, setState] = useState<State>(initialState);

  return (
    <GlobalStateContext.Provider value={{ state, setState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// Hook to use the global state
const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
};

export { GlobalStateProvider, useGlobalState };
