import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";

import "./styles/App.css";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { findSigninAccount } from "./helpers/hooks/useAccountFinder";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { ReactComponent as OverView } from "./assets/space_dashboard.svg";
import { ReactComponent as Policy } from "./assets/assignment.svg";
import { ReactComponent as Workflow } from "./assets/account_tree.svg";
import { ReactComponent as Asset } from "./assets/database.svg";
import { ReactComponent as Users } from "./assets/group.svg";
import Eagle from "./assets/resoluteLogomark.png";
import Logo from "./assets/resoluteFulllogo.png";
import { makeStyles } from "@mui/styles";
import { Modal, Select } from "@mui/material";
import { loginRequest, b2cPolicies, deployment } from "./authConfig";
import { Link, NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";

const pages = ["Products", "Pricing", "Blog"];

type IdTokenClaims = {
  roles: string[];
  idp: string;
  email: string;
  signInName: string;
  appTenantName: string;
  sub: string;
  appTenantId: string;
  questionId: string;
  acr: any;
  allTenants: any[];
};

export const NavigationBar = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  // const classes = useStyles();
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [showQandA, setShowQandA] = useState(Boolean);

  const appTenantd = (accounts[0]?.idTokenClaims as IdTokenClaims)?.appTenantId;
  // console.log("appTenantd=================", accounts[0]);
  const isAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes("Tenant.admin");
  const isGlobalAdminTabRequired = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes(
    "GlobalAdmin"
  );
  const isGlobalAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles[0] === "GlobalAdmin";

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [loadingIframe, setLoadingIframe] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/org/cybersecurity/qa/${appTenantd}`
        ); // Replace with your API endpoint
        // setData(response.data);

        if (response.data) {
          if (response.data?.isCyberSecurityQAComplete) {
            setShowQandA(false);
          } else {
            // Update the form with data from the API response

            setShowQandA(true);
          }
        } else {
          setShowQandA(true);
        }
      } catch (error) {
        setShowQandA(true);
      }
    };

    fetchData();
  }, [appTenantd]);

  const location = useLocation();
  const routeName = location.pathname.substring(1);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //width: 380,
    width: "90%", // Adjusted to a percentage to be responsive
    maxWidth: "380px", // Added maximum width for larger screens
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    minHeight: "300px",
  };

  // ================= new nav bar for mobile design ========================================
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <UnauthenticatedTemplate>
        <AppBar position="static" sx={{ backgroundColor: "#000706", height: "73px" }}>
          <Container sx={{ paddingRight: "22px !important", minWidth: "100%" }}>
            <Toolbar
              disableGutters
              sx={{ marginTop: "4.5px", paddingLeft: "8px", paddingRight: "0px !important" }}
            >
              {/* Logo */}

              <Link
                to={process.env.REACT_APP_MAIN_WEBSITE_URL + "/"}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {/* <Logo /> */}
                <img style={{ width: "162.92px", height: "52px" }} src={Logo} alt="logo" />
              </Link>

              {/* mobile navbar */}
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", justifyContent: "flex-end", md: "none" },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  slotProps={{
                    paper: {
                      sx: {
                        backgroundColor: "#000706", // Set background color to grey
                      },
                    },
                  }}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  <MenuItem onClick={handleCloseNavMenu} sx={{ color: "white" }}>
                    <Link
                      to={process.env.REACT_APP_MAIN_WEBSITE_URL + "/"}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography textAlign="center">Home</Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu} sx={{ color: "white" }}>
                    <Link
                      to={process.env.REACT_APP_MAIN_WEBSITE_URL + "/#Security"}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography textAlign="center">Security</Typography>
                    </Link>
                  </MenuItem>
                  {/* <MenuItem onClick={handleCloseNavMenu} sx={{ color: "white" }}>
                    <Link
                      to={process.env.REACT_APP_MAIN_WEBSITE_URL + "/"}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography textAlign="center">Our Offerings</Typography>
                    </Link>
                  </MenuItem> */}
                  <MenuItem onClick={handleCloseNavMenu} sx={{ color: "white" }}>
                    <Link
                      to={process.env.REACT_APP_MAIN_WEBSITE_URL + "/#AboutUs"}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography textAlign="center">About Us</Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu} sx={{ color: "white" }}>
                    <Link
                      to={process.env.REACT_APP_MAIN_WEBSITE_URL + "/#FAQ"}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography textAlign="center">FAQ</Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu} sx={{ color: "white" }}>
                    <Link
                      to={process.env.REACT_APP_MAIN_WEBSITE_URL + "/#Contact"}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography textAlign="center">Contact Us</Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Button
                      component={Link}
                      to="/"
                      variant="contained"
                      onClick={() => {
                        handleCloseNavMenu(), setOpen(true);
                      }}
                      sx={{ my: 2, mx: 1, color: "white", display: "block", fontSize: "14px" }}
                    >
                      Login
                    </Button>
                  </MenuItem>
                </Menu>
              </Box>

              {/* larger screen navbar */}
              <Box
                sx={{
                  flexGrow: 1,
                  display: {
                    xs: "none",
                    md: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  },
                }}
              >
                <Button
                  component={Link}
                  to={process.env.REACT_APP_MAIN_WEBSITE_URL + "/"}
                  onClick={handleCloseNavMenu}
                  sx={{
                    mx: 1,
                    // color: "white",
                    display: "block",
                    fontSize: "16px",
                    // fontWeight: "400",
                    color: "#eeeceb",
                  }}
                >
                  Home
                </Button>
                <Button
                  component={Link}
                  to={process.env.REACT_APP_MAIN_WEBSITE_URL + "/#Security"}
                  onClick={handleCloseNavMenu}
                  sx={{ mx: 1, color: "#eeeceb", display: "block", fontSize: "16px" }}
                >
                  Security
                </Button>
                <Button
                  component={Link}
                  to={process.env.REACT_APP_MAIN_WEBSITE_URL + "/#AboutUs"}
                  onClick={handleCloseNavMenu}
                  sx={{ mx: 1, color: "#eeeceb", display: "block", fontSize: "16px" }}
                >
                  About Us
                </Button>
                <Button
                  component={Link}
                  to={process.env.REACT_APP_MAIN_WEBSITE_URL + "/#FAQ"}
                  onClick={handleCloseNavMenu}
                  sx={{ mx: 1, color: "#eeeceb", display: "block", fontSize: "16px" }}
                >
                  FAQ
                </Button>
                <Button
                  component={Link}
                  to={process.env.REACT_APP_MAIN_WEBSITE_URL + "/#Contact"}
                  onClick={handleCloseNavMenu}
                  sx={{ mx: 0.6, color: "#eeeceb", display: "block", fontSize: "16px" }}
                >
                  Contact Us
                </Button>
                <Button
                  component={Link}
                  to="/"
                  variant="contained"
                  onClick={() => {
                    handleCloseNavMenu(), setOpen(true);
                  }}
                  sx={{
                    my: "12px",
                    mx: "26px",
                    color: "#eeeceb",
                    // display: "block",
                    fontSize: "14px",
                    width: "110px",
                    height: "40px",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: "7px",
                    fontWeight: "400",
                  }}
                >
                  LOG IN
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <>
                      <iframe
                        src="/account/SignUpSignIn"
                        width="100%"
                        height="300px"
                        onLoad={() => setLoadingIframe(false)}
                        loading="lazy"
                        scrolling="no"
                        frameBorder="0"
                      ></iframe>
                      {loadingIframe ? <p> Loading ...</p> : null}
                    </>
                  </Box>
                </Modal>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <Nav
          style={{
            display: "block",
            backgroundColor: "#000706",
            width: "80px",
            // height: "100vh",

            paddingTop: "8px",
            minHeight: "100vh",
          }}
        >
          <Nav.Link
            href="/"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <img style={{ width: "45px", height: "45px" }} src={Eagle} alt="logo" />
            {/* <Eagle height="45px" width="45px" /> */}
          </Nav.Link>
          {(isAdmin || isGlobalAdmin) && (
            <Nav.Link
              href="/"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "15px",
                filter: showQandA ? "blur(1px)" : "none",
                backgroundColor: routeName === "" ? "#233DFF" : "transparent", // Highlight current page
                width: "66px",
                height: "66px",
                borderRadius: "4px",
                marginLeft: "4px",
              }}
              disabled={showQandA}
            >
              <OverView />
              <Typography fontSize="12px" color="white">
                Dashboard
              </Typography>
            </Nav.Link>
          )}

          <Nav.Link
            href="/WorkflowManager"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "15px",
              filter: showQandA ? "blur(1px)" : "none",
              backgroundColor:
                routeName === "WorkflowManager" || routeName === "DetailWorkflowManager"
                  ? "#233DFF"
                  : "transparent", // Highlight current page
              width: "66px",
              height: "66px",
              borderRadius: "4px",
              marginLeft: "4px",
            }}
            disabled={showQandA}
          >
            <Workflow />
            <Typography fontSize="12px" color="white">
              Workflow
            </Typography>
          </Nav.Link>
          <Nav.Link
            href="/PolicyBuilder"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "15px",
              filter: showQandA ? "blur(1px)" : "none",
              backgroundColor: routeName === "PolicyBuilder" ? "#233DFF" : "transparent", // Highlight current page
              width: "66px",
              height: "66px",
              borderRadius: "4px",
              marginLeft: "4px",
            }}
            disabled={showQandA}
          >
            <Policy />
            <Typography fontSize="12px" color="white">
              Policies
            </Typography>
          </Nav.Link>

          {(isAdmin || isGlobalAdmin) && (
            <Nav.Link
              href="/AssetManagement"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "15px",
                filter: showQandA ? "blur(1px)" : "none",
                backgroundColor: routeName === "AssetManagement" ? "#233DFF" : "transparent", // Highlight current page
                width: "66px",
                height: "66px",
                borderRadius: "4px",
                marginLeft: "4px",
              }}
              disabled={showQandA}
            >
              <Asset />
              <Typography fontSize="12px" color="white">
                Registers
              </Typography>
            </Nav.Link>
          )}
          {(isAdmin || isGlobalAdmin) && (
            <Nav.Link
              href="/UserManagement"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "15px",
                filter: showQandA ? "blur(1px)" : "none",
                backgroundColor: routeName === "UserManagement" ? "#233DFF" : "transparent", // Highlight current page
                width: "66px",
                height: "66px",
                borderRadius: "4px",
                marginLeft: "4px",
              }}
              disabled={showQandA}
            >
              <Users />
              <Typography fontSize="12px" color="white">
                Accounts
              </Typography>
            </Nav.Link>
          )}
          {isGlobalAdminTabRequired && (
            <Nav.Link
              href="/GlobalAdmin"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "15px",
                //filter: showQandA ? "blur(1px)" : "none",
                backgroundColor: routeName === "GlobalAdmin" ? "#233DFF" : "transparent", // Highlight current page
                width: "66px",
                height: "66px",
                borderRadius: "4px",
                marginLeft: "4px",
              }}
              //disabled={showQandA}
            >
              <Users />
              <Typography fontSize="12px" color="white">
                Admin
              </Typography>
            </Nav.Link>
          )}

          {/* <Button
      // variant="warning"
      variant="outlined"
      sx={{ color: "white" }}
      className="ml-auto"
      onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}
    >
      <Typography variant="h6" noWrap component="div">
        Sign out
      </Typography>
    </Button> */}
        </Nav>
      </AuthenticatedTemplate>
    </>
  );
};

const useStyles = makeStyles((theme: { spacing: (arg0: number) => any }) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(20),
    borderBottom: "1px solid transparent",
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
  select: {
    "&:before": {
      borderColor: "red",
    },
    "&:after": {
      borderColor: "red",
    },
  },
  icon: {
    fill: "red",
  },
}));

export const SwitchTenant = () => {
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());

  const classes = useStyles();

  console.log(
    "curr ten",
    (accounts[0].idTokenClaims as IdTokenClaims).allTenants,
    (accounts[0].idTokenClaims as IdTokenClaims).appTenantName.toUpperCase()
  );

  const listTenants = (accounts[0].idTokenClaims as IdTokenClaims).allTenants
    //.filter(currTenant)
    .map((tenant, ix) => (
      <MenuItem key={ix} value={tenant}>
        {tenant}
      </MenuItem>
    ));
  console.log("listTenants", listTenants);
  function currTenant(tenant: any) {
    return tenant != (accounts[0].idTokenClaims as IdTokenClaims).appTenantName.toUpperCase();
  }
  if ((accounts[0].idTokenClaims as IdTokenClaims).allTenants.length > 1) {
    var title = `${(accounts[0].idTokenClaims as IdTokenClaims).appTenantName.toUpperCase()}`;
    return (
      <>
        <Typography variant="h6" component="div">
          Organisation:
        </Typography>
        <>
          <Select
            label="Organisation"
            value={title}
            className={classes.select}
            sx={{ color: "white" }}
            onChange={(e) => {
              instance.setActiveAccount(null);
              instance.loginRedirect({
                authority: b2cPolicies.authorities.signIn.authority,
                scopes: loginRequest.scopes,
                redirectUri: deployment.baseUrl + "/loggedin",
                account: accounts[0],
                extraQueryParameters: { tenant: e.target.value },
              });
            }}
          >
            {listTenants}
          </Select>
        </>
      </>
    );
  } else
    return (
      <Typography color={"white"}>
        {(accounts[0].idTokenClaims as IdTokenClaims).appTenantName}
      </Typography>
    );
};
