import { Box, Modal } from "@mui/material";
import React, { useState, useEffect, FC } from "react";
import { deployment } from "../../authConfig";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  minHeight: "600px",
};
const Invitation: FC = () => {
  const [queryParams, setQueryParams] = useState<Record<string, string>>({});
  const [showModal, setShowModal] = useState(true);
  const [loadingIframe, setLoadingIframe] = useState(true);
  useEffect(() => {
    const handler = (ev: any) => {
      console.log("ev", ev);
      if (ev.data === "SignIn") {
        console.log("Got Sign In event");
        window.top?.location.assign(`${process.env.REACT_APP_BASE_URL}` + "/");
      }

      //setMessage(ev.data.message);
    };

    window.addEventListener("message", handler, false);

    // Don't forget to remove addEventListener
    return () => window.removeEventListener("message", handler);
  }, []);

  useEffect(() => {
    // Function to parse query parameters from URL
    const parseQueryParams = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const params: Record<string, string> = {};
      for (let [key, value] of Array.from(searchParams)) {
        params[key] = value;
      }
      setQueryParams(params);
    };

    parseQueryParams();
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div>
      <Modal
        open={showModal}
        //onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <>
            <iframe
              src={`${process.env.REACT_APP_BASE_LOGIN_URL}/${
                deployment.b2cTenantId
              }/B2C_1A_V1_INVITATION/oauth2/v2.0/authorize?${new URLSearchParams(
                queryParams
              ).toString()}`}
              width="100%"
              height="600px"
              onLoad={() => setLoadingIframe(false)}
              loading="lazy"
              scrolling="no"
              frameBorder="0"
            ></iframe>
            {loadingIframe ? <p> Loading ...</p> : null}
          </>
        </Box>
      </Modal>
    </div>
  );
};

export default Invitation;
