// GetStarted.tsx
import React, { useEffect, useState } from "react";
import CyberQADynamicForm, { Question } from "./CyberQADynamicForm";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { loginRequest, b2cPolicies, deployment } from "../../authConfig";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";

const Dashboard: React.FC = () => {
  const [estate, setEstate] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const fetchEstate = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/rm/org/cybersecurity/qa`);
    setEstate(res.data);
    setIsLoaded(true);
  };

  useEffect(() => {
    fetchEstate();
    //sign in user again
  }, []);

  return (
    <div>
      {isLoaded ? (
        <div>
          <CyberQADynamicForm orginalQuestions={estate || []} />
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default Dashboard;
