import { useMsal } from "@azure/msal-react";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Modal,
  Typography,
  createTheme,
  useTheme,
  OutlinedInput,
  IconButton,
  CircularProgress,
  TextField,
  styled,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useMemo } from "react";
import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { useEffect, useState } from "react";
import { Menu, MenuItem, InputAdornment } from "@mui/material";
import { Button } from "react-bootstrap";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "./styles.css";
import logoImg from "../../assets/rlogo.png";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import { base64StringToBlob } from "blob-util";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ReactComponent as Filter } from "../../assets/filter_alt.svg";
import {
  Download as DownloadIcon,
  Cancel as CancelIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  NavigateBefore as PreviousIcon,
  NavigateNext as NextIcon,
} from "@mui/icons-material";
import { set } from "lodash";

interface Person {
  policy: string;
  details: string;
  status: string;
  owner: string;
  actions: string;
  policyRefId: string; // Add policyRefId property
  lastUpdateDate: Date;
  title: string;
}

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px", // Adjust margin as needed
  },
  searchInput: {
    width: "390px", // Adjust width as needed
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflow: "hidden",
    height: "100%",
    width: "100%",
  },
  iframe: {
    display: "block",
    "border-style": "none",
    width: "100px",
    height: "50px",
    margin: "auto",
    "background-color": "#777",
  },
  tableHeader: {
    // New CSS class for table header
    backgroundColor: "#EDEDED", // Change to desired background color
    borderRight: "1px solid #EDEDED", // Add right border
  },

  documentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 16,
  },

  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: 16,
  },
  buttonContainer: {
    display: "flex",
    gap: 8,
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    borderBottom: "1px solid #ccc",
  },
  leftHeader: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "70px",
    height: "52px",
    backgroundImage: `url('../assets/Logo.png')`,
  },
  headerText: {
    marginLeft: "10px",
  },
  // Styles for the header elements
  rightHeader: {
    display: "flex",
    alignItems: "center",
    gap: "10px", // Add gap between elements
  },
  uploadInput: {
    marginLeft: "20px",
    width: "200px", // Adjust as needed
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 24px",
    borderTop: "1px solid #ccc",
  },
}));

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

const PolicyBuilder: React.FC = () => {
  const baseTheme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElFilter, setAnchorElFilter] = useState<null | HTMLElement>(null);
  const [selectedPolicy, setSelectedPolicy] = useState<Person | null>(null);
  const [viewingDetails, setViewingDetails] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  // const filteredPolicies = useMemo(() => {
  //   return policies.filter((policy) =>
  //     policy.title.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  // }, [policies, searchTerm]);

  // Function to handle viewing policy details
  const handleViewDetails = (policy: Person) => {
    setAnchorEl(null);
    setSelectedPolicy(policy);
    setViewingDetails(true); // Set viewingDetails to true when viewing details
  };

  // Function to handle closing the details view
  const handleDetailsClose = () => {
    setViewingDetails(false); // Set viewingDetails to false when closing details
  };
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, policy: Person) => {
    setAnchorEl(event.currentTarget);
    setSelectedPolicy(policy);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const newTheme = createTheme(baseTheme, {
    palette: {
      background: { default: "rgba(255,255,255,255)" },
    },
    indicator: {
      backgroundColor: "green",
      height: "10px",
      top: "45px",
    },
  });

  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const appTenantId = (accounts[0].idTokenClaims as IdTokenClaims).appTenantId;
  const appTenantName = (accounts[0].idTokenClaims as IdTokenClaims).appTenantName;
  const myUserId = (accounts[0].idTokenClaims as IdTokenClaims).sub;
  const isAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes("Tenant.admin");
  const isGlobalAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles[0] === "GlobalAdmin";
  const [policies, setPolicies] = useState<Person[]>([]);
  const [openViewer, setViewerOpen] = useState(false);
  const [viewerUrl, setViewerUrl] = useState<Blob>();
  const [downloadUrl, setDownloadUrl] = useState<string>();
  const [downloadReady, setDownloadReady] = useState(false);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [title, setTitle] = useState<string>("");
  const [pageScale, setPageScale] = useState(1);
  const [modalStyle] = useState(getModalStyle);
  const [selectedPolicyRefId, setSelectedPolicyRefId] = useState<string>("");
  const [reLoad, setReLoad] = useState(false);
  const classes = useStyles();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [loading, setLoading] = useState(false); // Add loading state
  const [users, setUsers] = useState<Member[]>([]);
  const [filteredPolicies, setFilteredPolicies] = useState<Person[]>([]);

  useEffect(() => {
    // Filter policies based on search term
    const filteredData = policies.filter((policy) =>
      policy.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    // Update filteredPolicies state
    setFilteredPolicies(filteredData);
  }, [policies, searchTerm]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function handleZoomIn() {
    if (pageScale < 3) {
      setPageScale(pageScale + 0.2);
    }
  }

  function handleZoomOut() {
    if (pageScale > 0.3) {
      setPageScale(pageScale - 0.2);
    }
  }

  function handleNext() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  }
  const handleViewerClose = () => {
    setViewerOpen(false);
  };

  function handlePrevious() {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  }
  const [selectedView, setSelectedView] = useState("All");
  const filterOpen = Boolean(anchorElFilter);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handleFilterClose = (view: React.SetStateAction<string>) => {
    setSelectedView(view);

    setAnchorElFilter(null);
  };
  const columns = useMemo<MRT_ColumnDef<Person>[]>(
    () => [
      {
        accessorKey: "title",
        header: "Policy",
        size: 150,
      },
      {
        accessorKey: "detail",
        header: "Details",
        size: 200,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 150,
        Cell: ({ row }: { row: { original: Person } }) => {
          let statusColor = "";
          switch (row.original.status) {
            case "PENDING":
              statusColor = "#FF6666";
              break;
            case "Not Started":
              statusColor = "#FF6666";
              break;
            case "A":
              statusColor = "#5ABE76";
              break;
            default:
              statusColor = "white";
          }

          // Extracting lastUpdateDate from the row data
          const date = row.original.lastUpdateDate ? new Date(row.original.lastUpdateDate) : null;
          const formattedDate = date
            ? `${date.getDate()} ${date.toLocaleString("default", {
                month: "short",
              })} ${date.getFullYear()}`
            : "";

          // Conditionally render status with date for "A" status
          return (
            <div style={{ position: "relative", display: "inline-block" }}>
              <div
                style={{
                  position: "absolute",
                  width: "5px",
                  height: "100px", // Adjust height as needed
                  backgroundColor: statusColor,
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: "-16px", // Adjust position of the line
                }}
              />
              <span>
                {row.original.status === "A" ? (
                  <>
                    ADOPTED <br /> {formattedDate}
                  </>
                ) : (
                  row.original.status
                )}
              </span>
            </div>
          );
        },
      },
      {
        accessorKey: "owner", // This column represents the owner
        header: "Owner",
        size: 200,
        Cell: ({ row }: { row: { original: Person } }) => (
          <span>
            {row.original.owner
              ? users?.find((user) => user.userId === row.original.owner)?.givenName +
                " " +
                users?.find((user) => user.userId === row.original.owner)?.surname
              : ""}
          </span>
        ),
      },
      {
        accessorKey: "actions", // This column represents actions
        header: "Actions",
        size: 70,
        Cell: ({ row }: { row: { original: Person } }) => {
          // Dynamically render button based on status
          let buttonLabel = "";
          let buttonAction: () => void = () => {}; // Placeholder function
          let buttonBackground = "";
          let policy = row.original;
          let buttonDisabled = false;

          switch (row.original.status) {
            case "PENDING":
              buttonLabel = "Adopt Policy";
              buttonAction = () => {
                setSelectedPolicy(policy);
                launchPolicyPDF(row.original.policyRefId, row.original.title);
              };
              if (!(isAdmin || isGlobalAdmin)) buttonDisabled = true;
              buttonBackground = "#233DFF"; // Gray background
              break;
            case "A":
              buttonLabel = "View Policy";
              buttonAction = () => {
                // Action for adopted policy
                console.log("jere");
                setSelectedPolicy(policy);
                viewPolicyPDF(row.original.policyRefId, row.original.title);
              };
              buttonBackground = "#838383"; // Gray background
              break;
            case "not started":
              buttonLabel = "Adopt Policy";
              buttonAction = () => {
                launchPolicyPDF(row.original.policyRefId, row.original.title);
              };
              buttonBackground = "#233DFF"; // Gray background
              break;
            default:
              buttonBackground = "#233DFF"; // Gray background
              break;
          }

          return (
            <>
              <Button
                onClick={buttonAction}
                style={{
                  background: buttonBackground,
                  borderBlockColor: buttonBackground,
                  border: "5px",
                }}
                disabled={buttonDisabled}
              >
                {buttonLabel}
              </Button>{" "}
              <IconButton onClick={(event) => handleMenuOpen(event, policy)}>
                <MoreVertIcon />
              </IconButton>
            </>
          );
        },
      },
    ],
    [users]
  );

  useEffect(() => {
    // Fetch policies data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/all/${appTenantId}`
        );
        console.log("responce", response.data);
        setPolicies(response.data);
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/` + appTenantId
        );
        setUsers((prevValue: Member[]) => [...prevValue, ...userResponse.data]);
      } catch (error) {
        console.error("Error fetching policies:", error);
      }
    };

    fetchData(); // Call the fetch data function
  }, []); // Empty dependency array to ensure the effect runs only once

  const table = useMaterialReactTable({
    columns,
    data: filteredPolicies, // Use fetched policies data
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default,
      boxShadow: "none !important",
      borderRadius: "none !important",
    }),
    muiTableBodyRowProps: {
      hover: false,
      sx: {
        whiteSpace: "nowrap",
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "none !important",
        // width: "90%",
      },
    },
    muiTableProps: {
      sx: {
        // border: "1px solid rgba(81, 81, 81, .5)",
        boxShadow: "none !important",
        whiteSpace: "nowrap",
        borderRadius: "none !important",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        border: "1px solid #EDEDED",
        // borderLeft: "1px solid rgba(81, 81, 81, .5)",
        // borderTop: "1px solid rgba(81, 81, 81, .5)",
        // fontStyle: "italic",
        fontWeight: "bold",
        fontSize: "14px",
        whiteSpace: "nowrap",
        textAlign: "center",
        boxShadow: "none",
        paddingLeft: "20px",
        backgroundColor: "#FBFBFF",
        color: "#111827 60%",
        height: "50px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderBottom: "1px solid rgba(81, 81, 81, .5)",
        // whiteSpace: "pre-wrap",
        // paddingLeft: "20px",
        // paddingRight: "20px",
        //paddingTop: "10px",
        //paddingBottom: "10px",
        border: "1px solid #EDEDED",
        // borderLeft: columns.accessorKey === "status" ? "none" : "1px solid #EDEDED",
      },
    },
    renderToolbarInternalActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          alignItems: "center", // Align items vertically
        }}
      >
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center", paddingRight: "10px" }}>
          <TextField
            className={classes.searchInput}
            placeholder="Search Policy" // Added placeholder text
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              // startAdornment: (
              //   <InputAdornment position="start">
              //     <SearchIcon />
              //   </InputAdornment>
              // ),
              sx: {
                borderRadius: "40px",
                width: "390px",
                height: "40px",
                padding: "10px 10px 10px 20px", // Added padding
                gap: "10px",
                border: "1px solid",
                borderColor: "rgba(0, 0, 0, 0.23)",
                opacity: "0.7",
              },
              // Moved search icon to the end
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Typography fontSize="12px" color="#66696C">
            Status:
          </Typography>
          <Typography fontSize="14px" color="#1E2429">
            {" "}
            {selectedView}
          </Typography>
          <IconButton sx={{ cursor: "pointer", padding: 0 }} onClick={handleFilterClick}>
            <Filter />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorElFilter}
            open={filterOpen}
            onClose={() => handleFilterClose(selectedView)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => handleFilterClose("All")}>All</MenuItem>
          </Menu>
        </Box>
      </Box>
    ),
  });

  const launchPolicyPDF = async (policyRefId: string | undefined, title: string | undefined) => {
    if (policyRefId === undefined) {
      console.error("Policy reference ID is undefined");
      return;
    }
    setLoading(true); // Set loading to true when launching the PDF

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/rm/policy/review/${appTenantId}/${policyRefId}?tenantName=${appTenantName}&userId=${myUserId}`
      );

      const file = response.data;
      setViewerUrl(file);
      setDownloadUrl(file);
      setViewerOpen(true);
      setSelectedPolicyRefId(policyRefId);
      setPageNumber(1);
      setTitle(title ?? "");
    } catch (error) {
      console.error("Error launching PDF:", error);
    } finally {
      setLoading(false); // Set loading to false when done
    }
  };
  const viewPolicyPDF = async (policyRefId: string | undefined, title: string | undefined) => {
    if (policyRefId === undefined) {
      console.error("Policy reference ID is undefined");
      return;
    }
    console.log("Viewing policy PDF with reference ID:", policyRefId);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/rm/policy/adopt/${appTenantId}/${policyRefId}`
      );

      const file = response.data;
      setViewerUrl(file);
      setDownloadUrl(file);
      setViewerOpen(true);
      setSelectedPolicyRefId(policyRefId);
      setPageNumber(1);
      setTitle(title ?? "");
    } catch (error) {
      console.error("Error launching PDF:", error);
    } finally {
      setLoading(false); // Set loading to false when done
    }
  };
  const handleDownload = () => {
    const filename = "policy.pdf";
    const link = document.createElement("a");
    if (!viewerUrl) return;
    link.href = downloadUrl ?? "";
    link.download = `${filename}-${+new Date()}.pdf`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  };

  const handleDownloadPDF = async (policyRefId: string | undefined, title: string | undefined) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/rm/policy/review/${appTenantId}/${policyRefId}?tenantName=${appTenantName}&userId=${myUserId}`
      );

      const file = response.data;
      //setViewerUrl(file);
      setDownloadUrl(file);
      setTitle(title ?? "");
      setDownloadReady(true);
    } catch (error) {
      console.error("Error fetching PDF for download:", error);
    }
  };

  useEffect(() => {
    if (downloadReady) {
      const filename = `${title}_${appTenantName}`;
      const link = document.createElement("a");
      link.href = downloadUrl ?? "";
      link.download = `${filename}.pdf`;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }

    setDownloadReady(false);
  }, [downloadReady]);
  const handleAdopt = async () => {
    //upload the file first
    const fileBlob = base64StringToBlob(
      downloadUrl?.split(";base64,").pop() ?? "",
      "application/pdf"
    );

    const fileFormData = new FormData();
    fileFormData.append("file", new File([fileBlob], `Policy-${selectedPolicyRefId}.pdf`));
    fileFormData.append("referenceNumber", `Policy-${selectedPolicyRefId}`);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_SERVER}/rm/b2cpolicies/uploadFiles/${appTenantId}`,
        fileFormData
      );
      //alert("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_SERVER}/rm/policy/adopt/${appTenantId}/${selectedPolicyRefId}/${myUserId}`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setViewerOpen(false);
      setReLoad(!reLoad);
    }
    alert("Policy adopted successfully");
    window.location.reload();
  };
  return (
    <>
      <div style={{ padding: "15px", backgroundColor: "#F9F9FB" }}>
        <Box sx={{ padding: "15px" }}>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Manrope",
              fontSize: "18px",
              fontWeight: 600,
              lineHeight: "19.12px",
              textAlign: "left",
              color: "#111827",
              marginRight: "500px",
            }}
          >
            Policy Builder
          </Typography>
        </Box>
        <Box
          style={{
            backgroundColor: "white",
            padding: "15px",
            borderRadius: "4px",
            minHeight: "90vh",
          }}
        >
          <div className={classes.tableHeader}>
            <MaterialReactTable table={table} />
          </div>
          <Menu
            id="policy-actions-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => handleDownloadPDF(selectedPolicy?.policyRefId, selectedPolicy?.title)}
            >
              Download PDF
            </MenuItem>
            <MenuItem
              onClick={() => launchPolicyPDF(selectedPolicy?.policyRefId, selectedPolicy?.title)}
            >
              View Details
            </MenuItem>
            {/* Add additional menu items here */}
          </Menu>

          <Box m={12}>
            <Dialog open={openViewer} onClose={handleViewerClose} fullWidth={true} maxWidth="lg">
              <div className={classes.dialogHeader}>
                <div className={classes.leftHeader} style={{ padding: "10px" }}>
                  <img src={logoImg} alt="Logo" className={classes.logo} />
                  <div className={classes.headerText}>
                    <Typography variant="body1">{title}</Typography>
                    <Typography variant="body2" style={{ color: "blue" }}>
                      By ResoluteRM
                    </Typography>
                  </div>
                </div>
                <div className={classes.rightHeader} style={{ marginLeft: "200px" }}>
                  {selectedPolicy?.status === "PENDING" && (
                    <>
                      {(isAdmin || isGlobalAdmin) && (
                        <Button
                          onClick={handleAdopt}
                          variant="contained"
                          color="white"
                          style={{ backgroundColor: "#233DFF", color: "white" }}
                        >
                          Adopt Policy
                        </Button>
                      )}
                      {/* <TextField id="standard-basic" label="Upload Your Policy" variant="standard" /> */}
                    </>
                  )}
                  {/* Close icon button */}
                  <IconButton onClick={handleViewerClose}>
                    <CancelIcon />
                  </IconButton>
                </div>
              </div>
              <DialogContent>
                <Box className={classes.documentContainer}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <div>
                        <Document
                          file={viewerUrl}
                          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                        >
                          <Page pageNumber={pageNumber} scale={pageScale} />
                        </Document>
                      </div>
                    </>
                  )}
                </Box>
              </DialogContent>
              <div
                className={classes.dialogFooter}
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  backgroundColor: "white",
                  padding: "10px 20px",
                }}
              >
                <div
                  className={classes.buttonContainer}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography style={{ marginRight: "10px" }}>
                      Page {pageNumber} of {numPages}
                    </Typography>
                    <IconButton
                      onClick={handlePrevious}
                      style={{ color: "blue", marginRight: "5px", width: "20px" }}
                      disabled={pageNumber === 1}
                    >
                      <PreviousIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleNext}
                      style={{ color: "blue" }}
                      disabled={pageNumber === numPages}
                    >
                      <NextIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </Dialog>
          </Box>
        </Box>
      </div>
    </>
  );
};
export default PolicyBuilder;
