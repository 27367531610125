import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../GlobalStateContext";

type AccountInfo = {
  homeAccountId: string;
  environment: string;
  tenantId: string;
  username: string;
  localAccountId: string;
  name?: string | undefined;
  idTokenClaims?: object | undefined;
};
// Custom hook for finding an account with homeAccountId containing 'signin'
export const findSigninAccount = (accounts: AccountInfo[]) => {
  const { state, setState } = useGlobalState();
  // Retrieve the active account from sessionStorage, if available

  const activeAccountString = sessionStorage.getItem("activeAccount");
  const activeAccountFromSession = activeAccountString
    ? (JSON.parse(activeAccountString) as AccountInfo)
    : null;
  //return [state.account ?? useMsal().accounts[0]];
  return [activeAccountFromSession ?? useMsal().accounts[0]];
};
