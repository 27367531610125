/* eslint-disable react/jsx-no-comment-textnodes */
import { useMsal } from "@azure/msal-react";
import { Box, Button, Card, CardContent, Divider, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
type IdTokenClaims = {
  roles: string[];
  idp: string;
  email: string;
  signInName: string;
  appTenantName: string;
  sub: string;
  appTenantId: string;
  questionId: string;
  acr: any;
  allTenants: any[];
};
const DetailledDashboard: React.FC = () => {
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const appTenantId = (accounts[0].idTokenClaims as IdTokenClaims).appTenantId;
  const [data, setData] = useState<any>();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/org/r_scores/${appTenantId}`
        ); // Replace with your API endpoint
        setData(response.data);
        console.log(response.data);
        setLoaded(true);
      } catch (error) {
        setLoaded(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ padding: "15px", backgroundColor: "#F9F9FB", minHeight: "90vh" }}>
      {loaded ? (
        <Card>
          <CardContent>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: "28px" }}>
                <b>Detailed R_Scores</b>
              </Typography>
              <Button variant="contained" color="primary" component={Link} to="/WorkflowManager">
                Workflow Manager
              </Button>
            </Box>
            <Divider sx={{ mt: "10px", mb: "15px" }} />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Stack direction="row" spacing={26} sx={{ mr: "25px", ml: "2px" }}>
                <span>
                  <b>Initial Assesment</b>
                </span>

                <span>
                  <b>Current State</b>
                </span>

                <span>
                  <b>Target State</b>
                </span>
              </Stack>
            </div>
            <Stack direction="column" spacing={2} sx={{ mt: "10px" }}>
              {/* overall */}
              <Box
                style={{
                  border: "1px solid #010041",
                  borderRadius: "4px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <Typography sx={{ fontSize: "26px", ml: "10px" }}>
                  <b>R Score Overall</b>
                </Typography> */}
                <span style={{ fontSize: "23px", marginLeft: "10px", fontWeight: "bold" }}>
                  R Score Overall
                </span>
                <Stack direction="row" spacing={24} sx={{ mr: "10px" }}>
                  <div
                    className="rs-div1"
                    // style={{
                    //   backgroundColor: "#FFD27F",
                    // }}
                  >
                    <Typography style={{ fontSize: "34px", color: "#FFD27F" }}>
                      <b>{data?.initialOverallScore}</b>
                    </Typography>
                  </div>
                  <div
                    className="rs-div2"
                    // style={{
                    //   backgroundColor: "#b2b2ff",
                    // }}
                  >
                    <Typography style={{ fontSize: "34px", color: "#b2b2ff" }}>
                      <b>{data?.currentOverallScore}</b>
                    </Typography>
                  </div>
                  <div
                    className="rs-div1"
                    // style={{
                    //   backgroundColor: "#5BDE5B",
                    // }}
                  >
                    <Typography style={{ fontSize: "34px", color: "#5BDE5B" }}>
                      <b>{data?.projected12MonthOverallScore}</b>
                    </Typography>
                  </div>
                </Stack>
              </Box>

              {/* govern */}
              <Box
                style={{
                  border: "1px solid #010041",
                  borderRadius: "4px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <Typography sx={{ fontSize: "26px", ml: "10px", fontWeight: "bold" }}>
                  Govern
                </Typography> */}
                <span style={{ fontSize: "23px", marginLeft: "10px", fontWeight: "bold" }}>
                  Govern
                </span>
                <Stack direction="row" spacing={24} sx={{ mr: "10px" }}>
                  <div className="rs-div1">
                    <Typography style={{ fontSize: "34px", color: "#FFD27F" }}>
                      <b>{data?.initialScoreByPrinciple["G"]}</b>
                    </Typography>
                  </div>
                  <div
                    className="rs-div2"
                    // style={{
                    //   backgroundColor: "#b2b2ff",
                    // }}
                  >
                    <Typography style={{ fontSize: "34px", color: "#b2b2ff" }}>
                      <b>{data?.currentScoreByPrinciple["G"]}</b>
                    </Typography>
                  </div>
                  <div
                    className="rs-div1"
                    // style={{
                    //   backgroundColor: "#5BDE5B",
                    // }}
                  >
                    <Typography style={{ fontSize: "34px", color: "#5BDE5B" }}>
                      <b>{data?.projected12MonthScoreByPrinciple["G"]}</b>
                    </Typography>
                  </div>
                </Stack>
              </Box>

              {/* Protect */}
              <Box
                style={{
                  border: "1px solid #010041",
                  borderRadius: "4px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <Typography sx={{ fontSize: "26px", ml: "10px", fontWeight: "bold" }}>
                  Protect
                </Typography> */}
                <span style={{ fontSize: "23px", marginLeft: "10px", fontWeight: "bold" }}>
                  Protect
                </span>
                <Stack direction="row" spacing={24} sx={{ mr: "10px" }}>
                  <div className="rs-div1">
                    <Typography style={{ fontSize: "34px", color: "#FFD27F" }}>
                      <b>{data?.initialScoreByPrinciple["P"]}</b>
                    </Typography>
                  </div>
                  <div
                    className="rs-div2"
                    // style={{
                    //   backgroundColor: "#b2b2ff",
                    // }}
                  >
                    <Typography style={{ fontSize: "34px", color: "#b2b2ff" }}>
                      <b>{data?.currentScoreByPrinciple["P"]}</b>
                    </Typography>
                  </div>
                  <div
                    className="rs-div1"
                    // style={{
                    //   backgroundColor: "#5BDE5B",
                    // }}
                  >
                    <Typography style={{ fontSize: "34px", color: "#5BDE5B" }}>
                      <b>{data?.projected12MonthScoreByPrinciple["P"]}</b>
                    </Typography>
                  </div>
                </Stack>
              </Box>

              {/* Detect */}
              <Box
                style={{
                  border: "1px solid #010041",
                  borderRadius: "4px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <Typography sx={{ fontSize: "26px", ml: "10px", fontWeight: "bold" }}>
                  Detect
                </Typography> */}
                <span style={{ fontSize: "23px", marginLeft: "10px", fontWeight: "bold" }}>
                  Detect
                </span>
                <Stack direction="row" spacing={24} sx={{ mr: "10px" }}>
                  <div className="rs-div1">
                    <Typography style={{ fontSize: "34px", color: "#FFD27F" }}>
                      <b>{data?.initialScoreByPrinciple["D"]}</b>
                    </Typography>
                  </div>
                  <div
                    className="rs-div2"
                    // style={{
                    //   backgroundColor: "#b2b2ff",
                    // }}
                  >
                    <Typography style={{ fontSize: "34px", color: "#b2b2ff" }}>
                      <b>{data?.currentScoreByPrinciple["D"]}</b>
                    </Typography>
                  </div>
                  <div
                    className="rs-div1"
                    // style={{
                    //   backgroundColor: "#5BDE5B",
                    // }}
                  >
                    <Typography style={{ fontSize: "34px", color: "#5BDE5B" }}>
                      <b>{data?.projected12MonthScoreByPrinciple["D"]}</b>
                    </Typography>
                  </div>
                </Stack>
              </Box>

              {/* Respond */}
              <Box
                style={{
                  border: "1px solid #010041",
                  borderRadius: "4px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <Typography sx={{ fontSize: "26px", ml: "10px", fontWeight: "bold" }}>
                  Respond
                </Typography> */}
                <span style={{ fontSize: "23px", marginLeft: "10px", fontWeight: "bold" }}>
                  Respond
                </span>
                <Stack direction="row" spacing={24} sx={{ mr: "10px" }}>
                  <div className="rs-div1">
                    <Typography style={{ fontSize: "34px", color: "#FFD27F" }}>
                      <b>{data?.initialScoreByPrinciple["R"]}</b>
                    </Typography>
                  </div>
                  <div
                    className="rs-div2"
                    // style={{
                    //   backgroundColor: "#b2b2ff",
                    // }}
                  >
                    <Typography style={{ fontSize: "34px", color: "#b2b2ff" }}>
                      <b>{data?.currentScoreByPrinciple["R"]}</b>
                    </Typography>
                  </div>
                  <div
                    className="rs-div1"
                    // style={{
                    //   backgroundColor: "#5BDE5B",
                    // }}
                  >
                    <Typography style={{ fontSize: "34px", color: "#5BDE5B" }}>
                      <b>{data?.projected12MonthScoreByPrinciple["R"]}</b>
                    </Typography>
                  </div>
                </Stack>
              </Box>
            </Stack>
            {/* <Box style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}> */}
            {/* <Grid item xs={2}> */}
            {/* <Button variant="contained" color="primary" component={Link} to="/DetailledDashboard">
                Workflow Manager
              </Button> */}
            {/* </Grid> */}
            {/* <Grid item xs={2}>
                <Button variant="contained" color="primary" component={Link} to="/Dashboard">
                  Dashboard
                </Button>
              </Grid> */}
            {/* </Box> */}
          </CardContent>
        </Card>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};
export default DetailledDashboard;
