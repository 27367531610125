import { useEffect, useState } from "react";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import { loginRequest, b2cPolicies, deployment } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../GlobalStateContext";

export const LoggedIn = () => {
  const { instance } = useMsal();
  // This callback is passed into `acquireTokenPopup` and `acquireTokenRedirect` to handle the interactive auth response
  function handleResponse(resp: any) {
    //setState({ account: resp.account });
    sessionStorage.setItem("activeAccount", JSON.stringify(resp.account));
    instance.setActiveAccount(resp.account);
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id_token");
    const domain_hint = queryParams.get("domain_hint");
    const tenant = queryParams.get("tenant");
    const login_hint = queryParams.get("login_hint");
    console.log("id_token:" + id);

    const callbackId = instance.addEventCallback((event: any) => {
      console.log("event", event.eventType);
      if (event.eventType === EventType.LOGIN_FAILURE) {
        console.log("sign in failed:" + JSON.stringify(event));
        if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
          console.log("Forgot password");
        } else {
          console.log("login failed");
        }
      }

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        console.log("Login success" + JSON.stringify(event));
        if (event?.payload) {
          sessionStorage.setItem("accessToken", event?.payload.accessToken);
          handleResponse(event?.payload);
          //window.top?.location.reload();
        }
      }
      if (event.eventType === EventType.HANDLE_REDIRECT_END) {
        //window.top?.location.assign("/Dashboard");
        if (id === null) {
          //window.top?.location.assign(`${process.env.REACT_APP_BASE_URL}` + "/");
          console.log("redirecting to home page from loggendin");
          window.parent.postMessage("SignIn", "*");
          if (window.parent === window) {
            console.log("window.parent is null");
            window.top?.location.assign(`${process.env.REACT_APP_BASE_URL}` + "/");
          }
        }
        //window.top?.location.reload();
      }

      if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        if (event.interactionType === InteractionType.Redirect) {
          console.log("redirected", JSON.stringify(event?.payload));
          sessionStorage.setItem("accessToken", event?.payload.accessToken);
          handleResponse(event?.payload);
        }
      }
    });
    if (domain_hint || login_hint || tenant) {
      instance.loginRedirect({
        scopes: loginRequest.scopes,
        loginHint: login_hint ?? "",
        extraQueryParameters: {
          domain_hint: domain_hint ?? "",
          tenant: tenant ?? "",
        },
      });
    }
    if (id) {
      instance
        .loginRedirect({
          scopes: loginRequest.scopes,
          redirectUri: deployment.baseUrl + "/loggedin",
        })
        .then(() => {
          console.log("Redirected to login page");
        })
        .catch((err) => {
          console.log("Error in redirecting to login page", err);
          console.error(err);
        });
    }

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return <div>{<p>Loading...</p>}</div>;
};
