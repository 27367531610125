/* eslint-disable react/jsx-no-undef */
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { act } from "react-dom/test-utils";
import { useMsal } from "@azure/msal-react";
import { DateField, DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import AddActionForm from "./AddActionForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import Dropzone, { useDropzone } from "react-dropzone";
import CancelIcon from "@mui/icons-material/Cancel";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import { ReactComponent as Close } from "../../assets/close.svg";
import { ReactComponent as Upload } from "../../assets/cloud_upload.svg";
import { ReactComponent as Download } from "../../assets/download.svg";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { ReactComponent as ArrowDown } from "../../assets/keyboard_arrow_down.svg";

interface AddActionProps {
  isSystemAction: boolean;
  onClose: () => void;
}
type PolicyActionEntity = {
  referenceNumber: string;
  // id: string;

  policyId: string;

  tenantId: string;

  code: string;

  title: string;

  description: string;

  importance: string;

  suggestedTimePeriod: Map<number, string>;

  owner: string;

  manager: string;

  status: string;

  frequency: string | null;

  priority: number;

  comments: string;

  dueDate: Date | null;

  nextDueDate: Date | null;

  lastUpdateDate: Date | null;

  isRecurring: boolean;

  source: string;

  isResolved: boolean;

  costExternal: string;

  staffHours: string;

  dependencies: string[];

  attachmentDocIds: string[];

  assignedActioner: string;
  principle: string;
  references: { [x: string]: string }[];
  referencesInput: string;

  [key: string]: any; // Index signature allowing dynamic access to string properties
};
const status: Record<string, string> = {
  N: "Not Started",
  U: "Not Assigned",
  P: "Not Assigned",
  I: "In Progress",
  D: "Done",
  C: "Cancelled",
  H: "On Hold",
};
// const frequencyValues: Record<string, string> = {
//   D: "Daily",
//   W: "Weekly",
//   F: "Fortnightly",
//   M: "Monthly",
//   Q: "Quarterly",
//   H: "Half Yearly",
//   Y: "Yearly",
// };
const principles: any[] = [
  { code: "G", label: "Govern", color: "#0263FF" },
  { code: "P", label: "Protect", color: "#00108B" },
  { code: "D", label: "Detect", color: "#49AEEE" },
  { code: "R", label: "Respond", color: "#3D74A7" },
  { code: "N", label: "Manual", color: "#8c9eff" },
];
enum frequency {
  Q = "Quarterly",
  S = "Semi Annually",
  A = "Annually",
}
const priority: Record<number, string> = {
  0: "Low",
  1: "Medium",
  2: "High",
};
// const importance = ["High", "Critical"];
enum importance {
  C = "Critical",
  H = "High",
  //N = "None",
}
const initialPolicyActionEntity: PolicyActionEntity = {
  referenceNumber: "",
  // id: "",
  policyId: "",
  // ... other properties with appropriate initial values
  assignedActioner: "",
  staffHours: "",
  costExternal: "",
  isResolved: false,
  source: "M",
  isRecurring: false,
  lastUpdateDate: null,
  nextDueDate: null,
  dueDate: new Date(),
  comments: "",
  priority: 0,
  frequency: "",
  tenantId: "",
  code: "",
  title: "",
  description: "",
  importance: "",
  suggestedTimePeriod: new Map<number, string>(),
  owner: "",
  manager: "",
  status: "N",
  dependencies: [],
  attachmentDocIds: [],
  isActive: true,
  principle: "N",
  isTechAssistanceRequired: false,
  references: [],
  referencesInput: "",
};
type IdTokenClaims = {
  roles: string[];
  idp: string;
  email: string;
  signInName: string;
  appTenantName: string;
  sub: string;
  appTenantId: string;
  QuestionId: string;
  acr: any;
};
//example data type
type Member = {
  id: string;
  roles: string;
  tenantId: string;
  userId: string;
  givenName: string;
  surname: string;
  email: string;
  status: string;
};
const AddAction: React.FC<AddActionProps> = ({ isSystemAction, onClose }) => {
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState<string>("");
  const [data, setData] = React.useState<PolicyActionEntity>();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const [action, setAction] = React.useState("");
  const [values, setValues] = useState<PolicyActionEntity>(initialPolicyActionEntity);
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState<Member[]>([]);
  const [allActions, setAllActions] = useState<PolicyActionEntity[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [adoptedPolicies, setAdoptedPolicies] = useState<any[]>([]);
  const [files, setFiles] = useState<any>([]);
  const [alertMessage, setAlertMessage] = useState("");
  const appTenantId = (accounts[0]?.idTokenClaims as IdTokenClaims)?.appTenantId;
  const validationSchema = Yup.object({
    // referenceNumber: Yup.string().required("Reference number is required"),
    title: Yup.string().required("Action Title is required"),
    description: Yup.string().required("Action Description is required"),
    importance: Yup.string().required("Priority Level is required"),
    manager: Yup.string().test({
      name: "requiredIfNotN",
      test: function (value) {
        const status = this.parent.status;
        // Check if manager is required when status is not "N"
        return status === "U" || !!value;
      },
      message: "Manager is required",
    }),
    owner: Yup.string().test({
      name: "requiredIfNotN",
      test: function (value) {
        const status = this.parent.status;
        // Check if owner is required when status is not "N"
        return status === "U" || !!value;
      },
      message: "Action Owner is required",
    }),
    dueDate: Yup.date().required("Due Date is required"),
    isRecurring: Yup.string().required("Recurring Action is required"),
    frequency: Yup.string().test("isRecurring", "Action Frequency is required", function (value) {
      const { isRecurring } = this.parent; // Access parent values
      if (isRecurring === "true") {
        return !!value; // Return true if value is truthy when isRecurring is "true"
      }
      return true; // Return true if isRecurring is not "true"
    }),
  });

  const [manager, setManager] = useState("");
  const formik = useFormik({
    initialValues: initialPolicyActionEntity,
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      console.log("===in submit");

      try {
        // Handle form submission here, e.g., send data to the server
        // event.preventDefault();
        // Mark all fields as touched to trigger validation for all fields
        // formik.setTouched(
        //   Object.keys(values).reduce((acc, key) => {
        //     acc[key] = true;
        //     return acc;
        //   }, {} as any), // Type assertion to any
        //   true
        // );

        // await formik.validateForm(); // Ensure the form is validated

        // Validate the form
        const errors = await formik.validateForm();

        // If there are errors, stop submission
        if (errors && Object.keys(errors).length > 0) {
          return;
        }
        formik.values["tenantId"] = appTenantId;
        formik.values["source"] = "M";
        formik.values["status"] = "N";
        formik.values["dependencies"] = formik.values["dependencies"];
        formik.values["nextDueDate"] = formik.values["dueDate"];

        //formik.values["dueDate"] = null;
        // formik.values["nextDueDate"] = new Date(formik.values["nextDueDate"]);
        formik.values["lastUpdateDate"] = new Date();
        if (formik.values["isRecurring"] === false) {
          formik.values["frequency"] = null;
        }
        // formik.values["manager"] = manager;
        // let response = await axios.post(
        //   `${process.env.REACT_APP_API_SERVER}/rm/policy/control/action/${formik.values["control"]}`,
        //   formik.values
        // );
        //convert references to an array of object with keys as the value inputted
        formik.values["references"] = formik.values["referencesInput"]
          .split(",")
          .map((item: string) => ({ [item]: "N/A" }));
        const {
          isRecurring,
          isResolved,
          isActive,
          lastUpdateDate,
          suggestedTimePeriod,
          nextDueDate,
          assignedActioner,
          referenceNumber,
          policyId,
          priority,
          code,
          ...newFormik
        } = formik.values;
        if (formik.values["control"]) {
          console.log("in control");

          // If control is not empty, make the full API call
          let response: any; // Declare response using 'let' instead of 'const'
          try {
            response = await axios.post(
              `${process.env.REACT_APP_API_SERVER}/rm/policy/control/action/${formik.values["control"]}`,
              newFormik
            );
            if (response.status === 200 || response.status === 201) {
              setId(response.data.id);
              files.map(async (file: any) => {
                const fileFormData = new FormData();
                fileFormData.append("file", file);
                fileFormData.append("referenceNumber", response.data.id);

                try {
                  await axios.post(
                    `${process.env.REACT_APP_API_SERVER}/rm/b2cpolicies/uploadFiles/${formik.values["tenantId"]}`,
                    fileFormData
                  );
                  //alert("File uploaded successfully!");
                } catch (error: any) {
                  console.error(
                    "Error uploading file:",
                    error.response ? error.response.data : error.message
                  );
                  alert(
                    "Error uploading file: " + error.response ? error.response.data : error.message
                  );
                }
              });
              setShowAlert(false);
              handleClose();
            } else {
              console.error("Error creating action", response.data.message);
              setAlertMessage("Error creating action: " + response.data.message); // Add a colon after "Error creating action"
              setShowAlert(true);
            }
          } catch (err) {
            console.error("Error submitting form:", err, response);
            // Handle form submission error here, e.g., display an alert
            setAlertMessage(
              "Error submitting form: " + (response?.data?.message || (err as Error).message)
            ); // Use optional chaining to safely access response.data.message
            setShowAlert(true);
          }
        } else {
          // If control is empty, make the API call up to the action endpoint
          console.log("no control");
          let response: any; // Declare response using 'let' instead of 'const'
          try {
            response = await axios.post(
              `${process.env.REACT_APP_API_SERVER}/rm/policy/control/action`,
              newFormik
            );
            if (response.status === 200 || response.status === 201) {
              setId(response.data.id);
              files.map(async (file: any) => {
                const fileFormData = new FormData();
                fileFormData.append("file", file);
                fileFormData.append("referenceNumber", response.data.id);

                try {
                  await axios.post(
                    `${process.env.REACT_APP_API_SERVER}/rm/b2cpolicies/uploadFiles/${formik.values["tenantId"]}`,
                    fileFormData
                  );
                  //alert("File uploaded successfully!");
                } catch (error: any) {
                  console.error(
                    "Error uploading file:",
                    error.response ? error.response.data : error.message
                  );
                  alert(
                    "Error uploading file: " + error.response ? error.response.data : error.message
                  );
                }
              });
              setShowAlert(false);
              handleClose();
            } else {
              console.error("Error creating action", response.data.message);
              setAlertMessage("Error creating action" + response.data.message);
              setShowAlert(true);
            }
          } catch (err) {
            console.error("Error submitting form:", err, response);
            // Handle form submission error here, e.g., display an alert
            setAlertMessage(
              "Error submitting form: " + (response?.data?.message || (err as Error).message)
            ); // Use optional chaining to safely access response.data.message
            setShowAlert(true);
          }
        }
      } catch (err: any) {
        console.error("Error submitting form:", err);
        // Handle form submission error here, e.g., display an alert
        // setAlertMessage("Error submitting form: " + err.message);
        setShowAlert(true);
      }
    },
  });

  console.log("====", manager);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  const handleCancelClick = (index: number) => {
    // Create a new array excluding the canceled file
    const updatedFiles = [...files.slice(0, index), ...files.slice(index + 1)];
    setFiles(updatedFiles);
  };
  // const onDrop = async (acceptedFiles: any) => {
  //   console.log(acceptedFiles);
  //   setFiles([...files, acceptedFiles[0]]);
  // };
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onDrop = async (acceptedFiles: any) => {
    console.log(acceptedFiles);
    // Check if the file with the same name already exists
    const isDuplicate = files.some((file: any) => file.name === acceptedFiles[0].name);
    if (!isDuplicate) {
      // If it's not a duplicate, add the file to the files array
      setFiles([...files, acceptedFiles[0]]);
    } else {
      console.log("File with the same name already exists!");
      // If it's a duplicate, set the error message
      setErrorMessage("File with the same name already exists!");
    }
  };

  // fetch data
  useEffect(() => {
    setOpen(true);
    const fetchData = async () => {
      try {
        // const appTenantId = (accounts[0]?.idTokenClaims as IdTokenClaims)?.appTenantId;
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/${appTenantId}`
        );
        setUsers(response.data);
        const response2 = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/actions/${appTenantId}`
        );
        if (response2.status === 200) {
          setAllActions(response2.data);
        } else {
          setAllActions([]);
        }
      } catch (error) {
        // setError("Error fetching data");
        setAllActions([]);
      }
      try {
        // const appTenantId = (accounts[0]?.idTokenClaims as IdTokenClaims)?.appTenantId;
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/${appTenantId}`
        );
        setAdoptedPolicies(response.data);
        setLoaded(true);
      } catch (error) {
        // setError("Error fetching data");
        setAdoptedPolicies([]);
        setLoaded(true);
      }
    };

    fetchData();
  }, []);
  const handleControlChange = (e: any) => {
    console.log("contol===========", e);
    formik.setFieldValue("control", e);
    const control = adoptedPolicies.find((item) => item.id === e);

    let maxIntegerRefId = 0;
    let maxActionRefId = 0;
    if (control) {
      // Find the maximum actionRefId
      if (control.actions && Array.isArray(control.actions) && control.actions.length > 0) {
        const { maxIntegerRefId, maxActionRefId } = control.actions.reduce(
          (
            max: { maxIntegerRefId: number; maxActionRefId: number },
            action: { actionRefId: { toString: () => string } }
          ) => {
            console.log(action.actionRefId);
            const fractionalPart = parseInt(action.actionRefId.toString().split(".")[1]);
            const [integerPart] = action.actionRefId.toString().split(".").map(Number);

            return {
              maxIntegerRefId:
                integerPart > max.maxIntegerRefId ? integerPart : max.maxIntegerRefId,
              maxActionRefId:
                fractionalPart > max.maxActionRefId ? fractionalPart : max.maxActionRefId,
            };
          },
          {
            maxIntegerRefId: 0,
            maxActionRefId: 0,
          }
        );

        const newActionRefId = `${control.principle}.A.${maxIntegerRefId}.${(
          maxActionRefId + 1
        ).toFixed(0)}`;
        formik.setFieldValue(
          "actionRefId",
          Number(`${maxIntegerRefId}.${(maxActionRefId + 1).toFixed(0)}`)
        );
        formik.setFieldValue("referenceNumber", newActionRefId);
        formik.setFieldValue("principle", control.principle);
        console.log("Maximum ActionRefId:", maxActionRefId);
        console.log("New ActionRefId:", newActionRefId);
      } else {
        console.log("here control", control.controlRefId);
        const maxIntegerRefId = control.controlRefId;
        const maxActionRefId = 0;
        const newActionRefId = `${control.principle}.A.${maxIntegerRefId}.${(
          maxActionRefId + 1
        ).toFixed(0)}`;
        formik.setFieldValue(
          "actionRefId",
          Number(`${maxIntegerRefId}.${(maxActionRefId + 1).toFixed(0)}`)
        );
        formik.setFieldValue("referenceNumber", newActionRefId);
        // formik.setFieldValue("principle", control.principle);
        console.log("Maximum ActionRefId:", maxActionRefId);
        console.log("New ActionRefId:", newActionRefId);
      }
      console.log("Maximum IntegerRefId:", maxIntegerRefId);
      // Generate a new actionRefId by adding 1 to the maximum
    } else {
      console.log("Control not found");
      formik.setFieldValue("principle", "N");
      formik.setFieldValue("referenceNumber", "");
    }
  };

  // console.log("formik.values", formik.values);
  const isAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes("Tenant.admin");
  const isGlobalAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles[0] === "GlobalAdmin";

  console.log("====", formik.values);
  console.log("====ad", adoptedPolicies);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openFile = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeFiles = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Dialog
        //fullScreen
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        // onClose={handleClose}
        open={open}
        // PaperProps={{
        //   component: "form",
        //   onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
        //     event.preventDefault();
        //     formik.values["tenantId"] = appTenantId;
        //     formik.values["source"] = "M";
        //     formik.values["status"] = "P";
        //     formik.values["dependencies"] = formik.values["dependencies"];
        //     formik.values["nextDueDate"] = formik.values["dueDate"];
        //     //formik.values["dueDate"] = null;
        //     // formik.values["nextDueDate"] = new Date(formik.values["nextDueDate"]);
        //     formik.values["lastUpdateDate"] = new Date();
        //     if (formik.values["isRecurring"] === false) {
        //       formik.values["frequency"] = null;
        //     }
        //     // console.log(formik.values);
        //     // let response = await axios.post(
        //     //   `${process.env.REACT_APP_API_SERVER}/rm/policy/control/action/${formik.values["control"]}`,
        //     //   formik.values
        //     // );
        //     const { isRecurring, isResolved, isActive, lastUpdateDate, ...newFormik } =
        //       formik.values;
        //     if (formik.values["control"]) {
        //       // If control is not empty, make the full API call
        //       let response = await axios.post(
        //         `${process.env.REACT_APP_API_SERVER}/rm/policy/control/action/${formik.values["control"]}`,
        //         newFormik
        //       );
        //     } else {
        //       // If control is empty, make the API call up to the action endpoint
        //       let response = await axios.post(
        //         `${process.env.REACT_APP_API_SERVER}/rm/policy/control/action`,
        //         newFormik
        //       );
        //     }

        //     files.map(async (file: any) => {
        //       const fileFormData = new FormData();
        //       fileFormData.append("file", file);
        //       fileFormData.append("referenceNumber", formik.values["referenceNumber"]);

        //       try {
        //         await axios.post(
        //           `${process.env.REACT_APP_API_SERVER}/rm/b2cpolicies/uploadFiles/${formik.values["tenantId"]}`,
        //           fileFormData
        //         );
        //         alert("File uploaded successfully!");
        //       } catch (error) {
        //         console.error("Error uploading file:", error);
        //       }
        //     });
        //     setShowAlert(true);
        //     handleClose();
        //   },
        // }}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography fontSize="16px" fontWeight="600">
                  Add Action
                </Typography>
              </Box>

              <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                <Button type="submit" variant="contained">
                  Save Action
                </Button>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <>
              {showAlert && <Alert severity="error">{alertMessage}</Alert>}
              {loaded ? (
                <>
                  {isSystemAction && (
                    <>
                      <Stack direction="column" spacing={3}>
                        {/* action title */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Action Title <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            // label="Action Title"
                            name="title"
                            type="text" // You may need to determine the type dynamically based on your data
                            value={formik.values["title"]}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // required
                            error={Boolean(formik.errors.title)}
                            helperText={formik.errors.title}
                            sx={{ height: "40px", borderRadius: "5px", paddingY: "0px" }}
                            InputProps={{
                              style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                            }}
                          />
                        </Box>

                        {/* description */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Action Description <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            // label="Action Description"
                            // placeholder="Action Description"
                            name="description"
                            type="text" // You may need to determine the type dynamically based on your data
                            value={formik.values["description"]}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // required
                            error={Boolean(formik.errors.description)}
                            helperText={formik.errors.description}
                            multiline
                            sx={{
                              borderRadius: "5px",
                              paddingY: "0px",
                            }}
                            InputProps={{
                              style: { fontSize: "14px", borderRadius: "5px" }, // Set font size here
                            }}
                          />
                        </Box>

                        <Stack
                          direction="row"
                          spacing={4.8}
                          style={{ marginBottom: "10px", padding: "0px" }}
                        >
                          {/* control */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              fontSize="14px"
                              color="rgba(17, 24, 39, 0.6)"
                              fontWeight="600"
                              sx={{ marginLeft: "5px" }}
                            >
                              Control
                            </Typography>
                            {/* <TextField
                              fullWidth
                              select
                              variant="outlined"
                              placeholder="control"
                              name="control"
                              value={formik.values["control"] || ""}
                              onChange={handleControlChange}
                              onBlur={() => formik.setFieldTouched("control", true)}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                height: "44px",
                                width: "180px",
                                borderRadius: "5px",
                                paddingY: "0px",
                              }}
                              InputProps={{
                                style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                              }}
                            >
                              <MenuItem value="">None</MenuItem>

                              {adoptedPolicies.map((value, index) => (
                                <MenuItem key={index} value={value.id}>
                                  {`${value.controlRefId} - ${value.control}`}
                                </MenuItem>
                              ))}
                            </TextField> */}
                            <Autocomplete
                              fullWidth
                              options={adoptedPolicies}
                              getOptionLabel={(option) =>
                                `${option.controlRefId} - ${option.control}`
                              }
                              value={
                                adoptedPolicies.find(
                                  (option) => option.id === formik.values["control"]
                                ) || null
                              }
                              // value={formik.values["control"] || null}
                              // onChange={(event, selectedOption) => {
                              //   handleControlChange(selectedOption.id); // Pass the selected option to the handler
                              // }}
                              onChange={(event, selectedOption) => {
                                if (selectedOption) {
                                  handleControlChange(selectedOption.id);
                                } else {
                                  // Handle the case when no option is selected
                                  handleControlChange(null); // Pass null or any appropriate value
                                }
                              }}
                              onBlur={() => formik.setFieldTouched("control", true)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="control"
                                  name="control"
                                  // value={formik.values["control"] || ""}
                                  // onChange={handleControlChange}
                                  InputLabelProps={{ shrink: true }}
                                  sx={{
                                    height: "44px",
                                    width: "210px",
                                    borderRadius: "5px",
                                    paddingY: "0px",
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    style: {
                                      fontSize: "14px",
                                      height: "44px",
                                      borderRadius: "5px",
                                    },
                                  }}
                                />
                              )}
                            />
                          </Box>

                          {/* reference */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              fontSize="14px"
                              color="rgba(17, 24, 39, 0.6)"
                              fontWeight="600"
                              sx={{ marginLeft: "5px" }}
                            >
                              Reference
                            </Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              // label="Reference"
                              placeholder="Action Reference"
                              name="referenceNumber"
                              type="text" // You may need to determine the type dynamically based on your data
                              value={
                                // Check if the selected control is "None", if yes, set the value to an empty string
                                formik.values["control"] === ""
                                  ? ""
                                  : formik.values["referenceNumber"]
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              disabled
                              sx={{
                                height: "44px",
                                width: "180px",
                                borderRadius: "5px",
                                paddingY: "0px",
                              }}
                              InputProps={{
                                readOnly: true,
                                style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                              }}
                            />
                          </Box>

                          {/* importance/priority */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              fontSize="14px"
                              color="rgba(17, 24, 39, 0.6)"
                              fontWeight="600"
                              sx={{ marginLeft: "5px" }}
                            >
                              Priority Level <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                              fullWidth
                              select
                              variant="outlined"
                              // label="Priority"
                              placeholder="Importance"
                              name="importance"
                              value={formik.values["importance"]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              sx={{
                                height: "44px",
                                width: "180px",
                                borderRadius: "5px",
                                paddingY: "0px",
                              }}
                              InputProps={{
                                style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                              }}
                              error={Boolean(formik.errors.importance)}
                              helperText={formik.errors.importance}
                            >
                              {Object.entries(importance).map(([key, value]) => (
                                <MenuItem key={key} value={key}>
                                  {value}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>

                          {/* manager */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              fontSize="14px"
                              color="rgba(17, 24, 39, 0.6)"
                              fontWeight="600"
                              sx={{ marginLeft: "5px" }}
                            >
                              Manager <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                              fullWidth
                              select
                              // required
                              variant="outlined"
                              // label="Manager"
                              placeholder="Manager"
                              name="manager"
                              //value={manager}
                              value={formik.values["manager"] || ""}
                              // onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              //disabled={!isAdmin}
                              /* onChange={(event) => {
                            const selectedUserId = event.target.value;
                            const selectedUser = users.find(
                              (user) => user.userId === selectedUserId
                            );
                            if (selectedUser) {
                              const formattedManager = {
                                [selectedUserId]: `${selectedUser.givenName} ${selectedUser.surname}`,
                              };
                              setManager(`${selectedUser.givenName} ${selectedUser.surname}`);
                              formik.setFieldValue("manager", formattedManager);
                            }
                          }} */
                              onChange={formik.handleChange}
                              sx={{
                                height: "44px",
                                width: "180px",
                                borderRadius: "5px",
                                paddingY: "0px",
                              }}
                              InputProps={{
                                style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                              }}
                              error={Boolean(formik.errors.manager)}
                              helperText={formik.errors.manager}
                            >
                              {users
                                .filter(
                                  (user) =>
                                    user.status === "Active" &&
                                    (user.roles.includes("Tenant.admin") ||
                                      user.roles.includes("Tenant.manager"))
                                ) // Filter users where status is Active
                                .map((user) => (
                                  <MenuItem key={user.id} value={user.userId}>
                                    {`${user.givenName} ${user.surname}`}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Box>

                          {/* owner  */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              fontSize="14px"
                              color="rgba(17, 24, 39, 0.6)"
                              fontWeight="600"
                              sx={{ marginLeft: "5px" }}
                            >
                              Action Owner <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                              fullWidth
                              select
                              required={formik.values["status"] !== "N"}
                              variant="outlined"
                              // label="Action Owner"
                              placeholder="Owner"
                              name="owner"
                              value={formik.values["owner"]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              //disabled={!isAdmin}
                              sx={{
                                height: "44px",
                                width: "180px",
                                borderRadius: "5px",
                                paddingY: "0px",
                              }}
                              InputProps={{
                                style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                              }}
                              error={Boolean(formik.errors.owner)}
                              helperText={formik.errors.owner}
                            >
                              {users
                                .filter((user) => user.status === "Active") // Filter users where status is Active
                                .map((user) => (
                                  <MenuItem key={user.id} value={user.userId}>
                                    {`${user.givenName} ${user.surname}`}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Box>
                        </Stack>
                        <Stack direction="row" spacing={4.8}>
                          {/* due date */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                              // gap: "5px",
                            }}
                          >
                            <Typography
                              fontSize="14px"
                              color="rgba(17, 24, 39, 0.6)"
                              fontWeight="600"
                              sx={{ marginLeft: "5px" }}
                            >
                              Due By <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DateField", "DateField"]}>
                                <div style={{ width: "180px" }}>
                                  <DatePicker
                                    // label="Due Date"
                                    name="dueDate"
                                    value={dayjs(formik.values["dueDate"]) as Dayjs}
                                    onChange={(date) => formik.setFieldValue("dueDate", date)}
                                    format="DD/MMM/YY"
                                    minDate={dayjs().startOf("day")} // Use Day.js directly
                                    maxDate={dayjs().add(1, "year").startOf("day")}
                                    sx={{
                                      root: {
                                        paddingTop: "0px",
                                      },
                                      // Apply styles to the DatePicker component itself
                                      "& input": {
                                        // Target the input element within the DatePicker
                                        fontSize: "14px",
                                        height: "15px",
                                        borderRadius: "5px",
                                      },
                                    }}
                                    // onError={Boolean(formik.errors.owner)}
                                    // helperText={formik.errors.owner}
                                  />
                                </div>
                              </DemoContainer>
                            </LocalizationProvider>
                          </Box>

                          {/* recurring */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                              gap: "5px",
                            }}
                          >
                            <FormControl component="fieldset">
                              {/* <FormLabel component="legend">Recurring Action</FormLabel> */}
                              <Typography
                                fontSize="14px"
                                color="rgba(17, 24, 39, 0.6)"
                                fontWeight="600"
                                sx={{ marginLeft: "5px" }}
                              >
                                Recurring Action <span style={{ color: "red" }}>*</span>
                              </Typography>
                              <RadioGroup
                                row
                                aria-label="Recurring Action"
                                name="isRecurring"
                                value={formik.values["isRecurring"]}
                                onChange={(e) => {
                                  formik.setFieldValue("isRecurring", e.target.value === "true");
                                }}
                                sx={{
                                  height: "44px",
                                  width: "180px",
                                  paddingTop: "9px",
                                  paddingLeft: "6px",
                                }}
                                // error={Boolean(formik.errors.owner)}
                                // helperText={formik.errors.owner}
                              >
                                <FormControlLabel value="false" control={<Radio />} label="No" />
                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                              </RadioGroup>
                            </FormControl>
                          </Box>

                          {/* frequency  */}
                          {/* {formik.values["isRecurring"] && ( */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              fontSize="14px"
                              color="rgba(17, 24, 39, 0.6)"
                              fontWeight="600"
                              sx={{ marginLeft: "5px" }}
                            >
                              Action Frequency{" "}
                              {formik.values["isRecurring"] ? (
                                <span style={{ color: "red" }}>*</span>
                              ) : (
                                ""
                              )}
                            </Typography>
                            <TextField
                              fullWidth
                              select
                              variant="outlined"
                              // label="Frequency"
                              placeholder="Frequency"
                              name="frequency"
                              value={formik.values["frequency"]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              disabled={!formik.values["isRecurring"]}
                              sx={{
                                height: "44px",
                                width: "180px",
                                borderRadius: "5px",
                                paddingY: "0px",
                              }}
                              InputProps={{
                                style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                              }}
                              error={
                                formik.values["isRecurring"] && Boolean(formik.errors.frequency)
                              }
                              helperText={formik.values["isRecurring"] && formik.errors.frequency}
                            >
                              {Object.entries(frequency).map(([key, value]) => (
                                <MenuItem key={key} value={key}>
                                  {value}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                          {/* )} */}

                          {/* principle */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              fontSize="14px"
                              color="rgba(17, 24, 39, 0.6)"
                              fontWeight="600"
                              sx={{ marginLeft: "5px" }}
                            >
                              Relevant Principle
                            </Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              // label="Principle"
                              placeholder="principle"
                              name="principle"
                              type="text" // You may need to determine the type dynamically based on your data
                              value={
                                principles.find(
                                  (principle) => principle.code === formik.values["principle"]
                                )?.label || ""
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              disabled
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                height: "44px",
                                width: "180px",
                                borderRadius: "5px",
                                paddingY: "0px",
                              }}
                              InputProps={{
                                // readOnly: true,

                                style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                              }}
                            />
                          </Box>
                        </Stack>
                        {/* comments */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Comments/Notes
                          </Typography>
                          <TextField
                            fullWidth
                            multiline
                            variant="outlined"
                            // label="Comments and Notes"
                            placeholder="Comments"
                            name="notes"
                            type="text" // You may need to determine the type dynamically based on your data
                            value={formik.values["notes"]}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            InputProps={{
                              style: { fontSize: "14px", borderRadius: "5px" }, // Set font size here
                            }}
                          />
                        </Box>
                        <Stack direction="row" spacing={4.8}>
                          {/* file upload */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              fontSize="14px"
                              color="rgba(17, 24, 39, 0.6)"
                              fontWeight="600"
                              sx={{ marginLeft: "5px" }}
                            >
                              Attachments
                            </Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              // label="Action Description"
                              placeholder="Attachments"
                              name="Attachments"
                              multiline
                              type="text" // You may need to determine the type dynamically based on your data
                              value={files ? `${files.length} attached` : ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              sx={{
                                height: "44px",
                                width: "400px",
                                borderRadius: "5px",
                                paddingY: "0px",
                              }}
                              InputProps={{
                                style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {files.length > 0 && (
                                      <IconButton onClick={handleClick}>
                                        <ArrowDown />
                                      </IconButton>
                                    )}
                                    <Menu
                                      id="file-menu"
                                      anchorEl={anchorEl}
                                      open={openFile}
                                      onClose={closeFiles}
                                    >
                                      {files &&
                                        files.map(
                                          (
                                            file: {
                                              name:
                                                | boolean
                                                | React.ReactChild
                                                | React.ReactFragment
                                                | React.ReactPortal
                                                | null
                                                | undefined;
                                            },
                                            index: number
                                          ) => (
                                            <MenuItem key={index}>
                                              <div
                                                style={{ display: "flex", alignItems: "center" }}
                                              >
                                                <Typography>{file.name}</Typography>
                                                <IconButton
                                                  onClick={() => handleCancelClick(index)}
                                                >
                                                  <Delete />
                                                </IconButton>
                                                {/* <IconButton>
                                                <Download />
                                              </IconButton> */}
                                              </div>
                                            </MenuItem>
                                          )
                                        )}
                                    </Menu>
                                    <Dropzone
                                      onDrop={onDrop}
                                      accept={{
                                        "application/pdf": [".pdf"],
                                        "image/*": [],
                                        "application/msword": [],
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                          [],
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                          [],
                                        "application/vnd.ms-excel": [],
                                      }}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                          <input
                                            {...getInputProps()}
                                            id="fileInput"
                                            // onChange={handleFileInputChange}
                                            style={{ display: "none" }}
                                          />
                                          <IconButton
                                          // onClick={handleUploadClick}
                                          >
                                            <Upload />
                                          </IconButton>
                                        </div>
                                      )}
                                    </Dropzone>

                                    {/* <IconButton>
                                    <Download />
                                  </IconButton>
                                  <IconButton>
                                    <Delete />
                                  </IconButton> */}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>

                          {/* source */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              fontSize="14px"
                              color="rgba(17, 24, 39, 0.6)"
                              fontWeight="600"
                              sx={{ marginLeft: "5px" }}
                            >
                              Action Source
                            </Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              // label="Action Source"
                              placeholder="source"
                              name="source"
                              type="text" // You may need to determine the type dynamically based on your data
                              value="Manual"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              disabled
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                height: "44px",
                                width: "180px",
                                borderRadius: "5px",
                                paddingY: "0px",
                              }}
                              InputProps={{
                                style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                              }}
                            />
                          </Box>

                          {/* technical action */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                              gap: "5px",
                            }}
                          >
                            <FormControl component="fieldset">
                              {/* <FormLabel component="legend">Recurring Action</FormLabel> */}
                              <Typography
                                fontSize="14px"
                                color="rgba(17, 24, 39, 0.6)"
                                fontWeight="600"
                                sx={{ marginLeft: "5px" }}
                              >
                                Technical Action? <span style={{ color: "red" }}>*</span>
                              </Typography>
                              <RadioGroup
                                row
                                aria-label="isTechAssistanceRequired"
                                name="isTechAssistanceRequired"
                                value={formik.values["isTechAssistanceRequired"]}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "isTechAssistanceRequired",
                                    e.target.value === "true"
                                  );
                                }}
                                sx={{
                                  height: "44px",
                                  width: "180px",
                                  paddingTop: "6px",
                                  paddingLeft: "6px",
                                }}
                              >
                                <FormControlLabel value="false" control={<Radio />} label="No" />
                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                              </RadioGroup>
                            </FormControl>
                          </Box>
                        </Stack>

                        {/* references */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Useful Reference Information
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            // label="Useful Reference Information"
                            placeholder="References"
                            name="referencesInput"
                            // required
                            value={formik.values["referencesInput"]}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            InputProps={{
                              // readOnly: true,
                              style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                            }}
                          ></TextField>
                        </Box>
                      </Stack>
                    </>
                  )}
                </>
              ) : (
                <div> Loading...</div>
              )}
            </>{" "}
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
};
export default AddAction;
