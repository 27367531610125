/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState } from "react";
import { Docs } from "../../docs";
import { useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import { b2cPolicies, loginRequest } from "../../authConfig";
import useFetch from "../../helpers/hooks/useFetch";
import axios from "axios";
import React from "react";
import "./index.css";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Verified_user } from "../../assets/verified_user.svg";
import { ReactComponent as Handshake } from "../../assets/handshake.svg";
import { ReactComponent as Hub } from "../../assets/hub.svg";
import { ReactComponent as Explore } from "../../assets/explore.svg";
import { styled } from "@mui/material/styles";

const Responsive = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
    fontWeight: "700",
    letterSpacing: "1.4px",
    marginTop: "5px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "22px",
    fontWeight: "700",
  },
}));

const Home: React.FC = () => {
  const { instance } = useMsal();
  const [redeemToken, setRedeemToken] = useState("");
  const [loginError, setLoginError] = useState("");
  const [step, setStep] = useState(0);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [newEstate, setNewEstate] = useState<any>(null);

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id_token");
    const domain_hint = queryParams.get("domain_hint");
    const tenant = queryParams.get("tenant");
    const login_hint = queryParams.get("login_hint");
    setRedeemToken(id ? id : "");

    const callbackId = instance.addEventCallback(
      (event: {
        eventType: EventType;
        error: { errorMessage: string | string[] };
        interactionType: InteractionType;
        payload: any;
      }) => {
        if (event.eventType === EventType.LOGIN_FAILURE) {
          console.log("sign in failed:" + event.error.errorMessage);
          if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
            if (event.interactionType === InteractionType.Redirect) {
              instance.loginRedirect({ ...b2cPolicies.authorities.forgotPassword, scopes: [] });
            } else if (event.interactionType === InteractionType.Popup) {
              instance
                .loginRedirect({ ...b2cPolicies.authorities.forgotPassword, scopes: [] })
                .catch((e) => {
                  return;
                });
            }
          } else {
            setLoginError("No tenants!");
          }
        }

        if (event.eventType === EventType.LOGIN_SUCCESS) {
          if (event?.payload) {
            /**
             * We need to reject id tokens that were not issued with the default sign-in policy.
             * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
             * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
             */
            /*if (event.payload.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword) {
                        window.alert("Password has been reset successfully. \nPlease sign-in with your new password");
                        return instance.logout();
                    }*/
          }
        }
      }
    );
    if (domain_hint || login_hint || tenant) {
      instance.loginRedirect({
        scopes: loginRequest.scopes,
        loginHint: login_hint ?? "",
        extraQueryParameters: {
          domain_hint: domain_hint ?? "",
          tenant: tenant ?? "",
        },
      });
    }
  }, [step]);

  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the 'get-started' page
    navigate("/get-started");
  };

  // const classes = useStyles();

  return (
    <>
      <div className="main-outer-div">
        <Grid container spacing={6} style={{ height: "100%" }}>
          <Grid
            item
            md={6}
            lg={6}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "left",
              flexDirection: "column",
            }}
          >
            <div className="title-div">
              <span className="main-title">Cost-effective cyber solutions for SMEs</span>
              <Responsive>Your Organisation's portal to manage data and cyber security.</Responsive>
            </div>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                flexDirection: "column",
              }}
              className="title2-div"
            >
              <Typography fontSize="16px" fontWeight="500" className="note-title">
                Start with our free assessment tool now.
              </Typography>
              <Button variant="contained" className="get-started-btn" onClick={handleClick}>
                Get Started
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            md={2}
            lg={2}
            sm={12}
            sx={{
              display: { xs: "none", md: "flex" },
            }}
            style={{
              // display: "flex",
              justifyContent: "center",
              alignItems: "left",
              flexDirection: "column",
              paddingLeft: "160px",
            }}
          >
            <div className="box-main-div">
              <Stack direction="column" spacing={5}>
                <Stack direction="row" spacing={4}>
                  <div className="box-div">
                    <Verified_user />
                    <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                      <Typography
                        fontSize="16px"
                        fontWeight="700"
                        color="primary"
                        lineHeight="21.86px"
                      >
                        Understand Obligations
                      </Typography>
                    </div>
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      color="#1E2429"
                      lineHeight="19.12px"
                    >
                      Understand data-related obligations and processes to manage them
                    </Typography>
                  </div>
                  <div className="box-div">
                    <Hub />
                    <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                      <Typography
                        fontSize="16px"
                        fontWeight="700"
                        color="primary"
                        lineHeight="21.86px"
                      >
                        Peace of Mind
                      </Typography>
                    </div>
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      color="#1E2429"
                      lineHeight="19.12px"
                    >
                      Take the pain out of cyber security, allowing you to get back to managing
                      business
                    </Typography>
                  </div>
                </Stack>
                <Stack direction="row" spacing={4}>
                  <div className="box-div">
                    <Handshake />
                    <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                      <Typography
                        fontSize="16px"
                        fontWeight="700"
                        color="primary"
                        lineHeight="21.86px"
                      >
                        Enhance Assurance
                      </Typography>
                    </div>
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      color="#1E2429"
                      lineHeight="19.12px"
                    >
                      Satisfy key supplier, counterparty or insurer requirements
                    </Typography>
                  </div>
                  <div className="box-div">
                    <Explore />
                    <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                      <Typography
                        fontSize="16px"
                        fontWeight="700"
                        color="primary"
                        lineHeight="21.86px"
                      >
                        Ready Made Tools
                      </Typography>
                    </div>
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      color="#1E2429"
                      lineHeight="19.12px"
                    >
                      Simplified processes and playbooks to minimise, and navigate, cyber incidents
                    </Typography>
                  </div>
                </Stack>
              </Stack>
            </div>
          </Grid>
          <Grid
            item
            md={2}
            lg={2}
            sm={12}
            sx={{
              display: { xs: "flex", md: "none" },
            }}
            style={{
              // display: "flex",
              justifyContent: "center",
              alignItems: "left",
              flexDirection: "column",
              paddingTop: "50px",
              // paddingLeft: "160px",
            }}
          >
            <div className="box-main-div">
              <Stack direction="column" spacing={5}>
                <Stack direction="row" spacing={4}>
                  <div className="box-div">
                    <Verified_user />
                    <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                      <Typography
                        fontSize="16px"
                        fontWeight="700"
                        color="primary"
                        lineHeight="21.86px"
                      >
                        Understand Obligations
                      </Typography>
                    </div>
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      color="#1E2429"
                      lineHeight="19.12px"
                    >
                      Understand data-related obligations and processes to manage them
                    </Typography>
                  </div>
                  <div className="box-div">
                    <Hub />
                    <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                      <Typography
                        fontSize="16px"
                        fontWeight="700"
                        color="primary"
                        lineHeight="21.86px"
                      >
                        Peace of Mind
                      </Typography>
                    </div>
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      color="#1E2429"
                      lineHeight="19.12px"
                    >
                      Take the pain out of cyber security, allowing you to get back to managing
                      business
                    </Typography>
                  </div>
                  <div className="box-div">
                    <Handshake />
                    <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                      <Typography
                        fontSize="16px"
                        fontWeight="700"
                        color="primary"
                        lineHeight="21.86px"
                      >
                        Enhance Assurance
                      </Typography>
                    </div>
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      color="#1E2429"
                      lineHeight="19.12px"
                    >
                      Satisfy key supplier, counterparty or insurer requirements
                    </Typography>
                  </div>
                  <div className="box-div">
                    <Explore />
                    <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                      <Typography
                        fontSize="16px"
                        fontWeight="700"
                        color="primary"
                        lineHeight="21.86px"
                      >
                        Ready Made Tools
                      </Typography>
                    </div>
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      color="#1E2429"
                      lineHeight="19.12px"
                    >
                      Simplified processes and playbooks to minimise, and navigate, cyber incidents
                    </Typography>
                  </div>
                </Stack>
              </Stack>
            </div>
          </Grid>
        </Grid>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "none" },
            flexDirection: "column",
            alignItems: "center",
          }}
          className="title2-div"
        >
          <Typography fontSize="16px" fontWeight="500" className="note-title">
            Start with our free assessment tool now.
          </Typography>
          <Button variant="contained" className="get-started-btn" onClick={handleClick}>
            Get Started
          </Button>
        </Box>
      </div>
    </>
  );
};
export default Home;
