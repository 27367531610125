import { useEffect, useState } from "react";
import { Docs } from "../../docs";
import { useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import { b2cPolicies, loginRequest } from "../../authConfig";
import useFetch from "../../helpers/hooks/useFetch";
import axios from "axios";
import React from "react";
import {
  Button,
  Box,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Alert,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import "../Organisation/organisation.css";
import { Close } from "@mui/icons-material";
import { set } from "lodash";

//example data type
type Member = {
  id: string;
  roles: string;
  tenantId: string;
  userId: string;
  givenName: string;
  surname: string;
  email: string;
  status: string;
};
type RMOrganisation = {
  id: string;

  tenantId: string;

  name: string;

  abnacn: string;

  logoBase64: ArrayBuffer | string | null | undefined;

  address: string;

  mainContactName: string;

  mainContactMobile: string;

  sendReminderEmailToActionOwner: boolean;

  emailNoDays: number;

  emailCCManager: boolean;

  sendReminderSMSToActionOwner: boolean;

  smsNoDays: number;

  smsCCManager: boolean;

  lastUpdateDate: Date;
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const SystemSettings: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const appTenantId = (accounts[0].idTokenClaims as IdTokenClaims).appTenantId;
  const [data, setData] = useState<RMOrganisation>({} as RMOrganisation);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = React.useState<Member[]>([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  useEffect(() => {
    const fetchOrg = async () => {
      // Update the query parameter based on the current step
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/rm/organisation/${appTenantId}`
      );
      setData(response.data);
      setIsLoading(false);
      const userResponse = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/` + appTenantId
      );
      setUsers((prevValue: Member[]) => [...prevValue, ...userResponse.data]);
    };
    // Call the function to fetch the image whenever the step changes
    fetchOrg();
  }, []);

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("event", event.target.value);
    console.log("prop", prop);
    setData({ ...data, [prop]: event.target.value });
    console.log(data);
  };
  const handleNoChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("event", event.target.value);
    console.log("prop", prop);
    setData({ ...data, [prop]: Number(event.target.value) });
    console.log(data);
  };
  const handleBooleanChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [prop]: event.target.checked });
  };

  const handleSave = async () => {
    if (data.emailNoDays < 1) {
      setErrorMessage("Email Number of Days before action is due must be greater than 0");
      setShowError(true);
      return;
    }
    if (data.smsNoDays < 1) {
      setErrorMessage("SMS Number of Days before action is due must be greater than 0");
      setShowError(true);
      return;
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_SERVER}/rm/organisation/saveOrganisation`,
        data
      );
      setShowSuccess(true);
      onClose();
    } catch (error) {
      setShowError(true);
    }
  };
  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    const fileInput = event.target;
    if (file.size > 51200) {
      alert("File is too big!");
      fileInput.value = "";
      return;
    }

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // The result property contains the base64 encoded strings
        console.log("reader.result", reader.result);
        setData((prevData) => ({ ...prevData, logoBase64: reader.result }));
      };

      // Read the file as a Data URL (base64 encoding)
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      {showSuccess && (
        <Alert severity="success" onClose={() => setShowSuccess(false)}>
          System settings saved successfully
        </Alert>
      )}
      {showError && <Alert severity="error">Error saving system settings - {errorMessage}</Alert>}
      <Box style={{ padding: "10px" }}>
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}
        >
          <Typography fontSize="24px" fontWeight="bold">
            System Settings
          </Typography>
          <div>
            <Box style={{ textAlign: "center", marginTop: "20px", padding: "2px" }}>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
          </div>
        </div>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "column", margin: "5px" }}>
                <div style={{ marginBottom: "10px", minWidth: "250px" }}>
                  <Typography variant="h6">Email Notifications</Typography>
                </div>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data?.sendReminderEmailToActionOwner}
                        onChange={handleBooleanChange("sendReminderEmailToActionOwner")}
                      />
                    }
                    label="Send reminder email to action owner"
                  />
                </FormGroup>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    style={{ marginRight: "10px", maxWidth: "70px" }}
                    required
                    type="number"
                    inputProps={{ inputMode: "numeric", min: "1" }} // Set the min attribute to "1"
                    variant="outlined"
                    name="emailNoDays"
                    value={data.emailNoDays}
                    onChange={handleNoChange("emailNoDays")}
                  />
                  <Typography style={{ marginRight: "20px", minWidth: "500px" }}>
                    Number of Days before action is due
                  </Typography>
                </div>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data?.emailCCManager}
                        onChange={handleBooleanChange("emailCCManager")}
                      />
                    }
                    label="Copy in Manager on email notifications"
                  />
                </FormGroup>
                {/* SMS Notifications Section */}
                <div style={{ marginBottom: "10px", minWidth: "250px" }}>
                  <Typography variant="h6">SMS Notifications</Typography>
                </div>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data?.sendReminderSMSToActionOwner}
                        onChange={handleBooleanChange("sendReminderSMSToActionOwner")}
                      />
                    }
                    label="Send reminder SMS to action owner"
                  />
                </FormGroup>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    style={{ marginRight: "10px", maxWidth: "70px" }}
                    required
                    inputProps={{ inputMode: "numeric", min: "1" }} // Set the min attribute to "1"
                    variant="outlined"
                    type="number"
                    name="smsNoDays"
                    value={data.smsNoDays}
                    onChange={handleNoChange("smsNoDays")}
                  />
                  <Typography style={{ marginRight: "10px", minWidth: "250px" }}>
                    Number of Days before action is due
                  </Typography>
                </div>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data?.smsCCManager}
                        onChange={handleBooleanChange("smsCCManager")}
                      />
                    }
                    label="Copy in Manager on SMS notifications"
                  />
                </FormGroup>
              </div>
            </Box>
            <Box style={{ textAlign: "right", marginTop: "20px" }}>
              <Button variant="contained" style={{ margin: "10px" }} onClick={handleSave}>
                Save
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
export default SystemSettings;
