import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SystemRegisterWithProviders from "./SystemRegister";
import HardwareRegisterWithProviders from "./HardwareRegister";
import ServiceProviderRegisterWithProviders from "./ServiceProviderRegister";
import { Button } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AssetManager() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // add system
  const [add, setAdd] = React.useState(false);
  console.log("add", add);

  return (
    <Box style={{ margin: "15px", boxShadow: "0px 0px 16px 0px #00000014" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "5px",
          alignItems: "center",
        }}
      >
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab
            label={
              <Typography fontSize="14px" color="#233DFF" fontWeight="bold">
                Systems
              </Typography>
            }
            {...a11yProps(0)}
            style={{ color: "#233DFF" }}
          />
          <Tab
            label={
              <Typography fontSize="14px" color="#233DFF" fontWeight="bold">
                Hardware & Device
              </Typography>
            }
            {...a11yProps(1)}
            style={{ color: "#233DFF" }}
          />
          <Tab
            label={
              <Typography fontSize="14px" color="#233DFF" fontWeight="bold">
                I.T Services Providers
              </Typography>
            }
            {...a11yProps(2)}
            style={{ color: "#233DFF" }}
          />
        </Tabs>
        {/* {value === 0 && (
          <Box sx={{ display: "flex", gap: "20px", alignItems: "center", paddingRight: "10px" }}>
            <Button variant="contained" onClick={() => setAdd(true)}>
              Add System
            </Button>
            <Button variant="contained">Export</Button>
          </Box>
        )} */}
      </Box>
      <CustomTabPanel value={value} index={0}>
        <SystemRegisterWithProviders />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <HardwareRegisterWithProviders />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ServiceProviderRegisterWithProviders />
      </CustomTabPanel>
    </Box>
  );
}
