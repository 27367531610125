function base64ToBlob(base64String: string, contentType = "") {
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }

  const byteArray = new Uint8Array(byteArrays);
  return new Blob([byteArray], { type: contentType });
}
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-undef */
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import axios, { AxiosError } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { act } from "react-dom/test-utils";
import { useMsal } from "@azure/msal-react";
import { DateField, DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import Alert from "@mui/material/Alert";
import { useFormik } from "formik";
import * as Yup from "yup";
import Dropzone, { useDropzone } from "react-dropzone";
import CancelIcon from "@mui/icons-material/Cancel";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import { ReactComponent as Close } from "../../assets/close.svg";
import { ReactComponent as Upload } from "../../assets/cloud_upload.svg";
import { ReactComponent as Download } from "../../assets/download.svg";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { ReactComponent as ArrowDown } from "../../assets/keyboard_arrow_down.svg";
import EditAction from "./EditAction";

interface EditActionProps {
  actionId: string;
  isSystemAction: boolean;
  onClose: () => void;
}
type PolicyActionEntity = {
  referenceNumber: string;
  id: string;

  policyId: string;

  tenantId: string;

  code: string;

  title: string;

  description: string;

  importance: string;

  suggestedTimePeriod: Map<number, string>;

  owner: string;

  manager: string;

  status: string;

  frequency: string;

  // priority: number;

  comments: string;

  dueDate: Date;

  nextDueDate: Date;

  lastUpdateDate: Date;

  isRecurring: boolean;

  source: string;

  isResolved: boolean;

  costExternal: string;

  staffHours: string;

  // dependencies: string[];

  attachmentDocIds: string[];

  assignedActioner: string;
  actionRefId: string;
  principle: string;
  references: string[];
  isTechAssistanceRequired: boolean;

  [key: string]: any; // Index signature allowing dynamic access to string properties
};
const initialPolicyActionEntity: PolicyActionEntity = {
  referenceNumber: "",
  id: "",
  policyId: "",
  // ... other properties with appropriate initial values
  assignedActioner: "",
  staffHours: "",
  costExternal: "",
  isResolved: false,
  source: "",
  isRecurring: false,
  lastUpdateDate: new Date(),
  nextDueDate: new Date(),
  dueDate: new Date(),
  comments: "",
  // priority: 0,
  frequency: "",
  tenantId: "",
  code: "",
  title: "",
  description: "",
  importance: "",
  suggestedTimePeriod: new Map<number, string>(),
  owner: "",
  manager: "",
  status: "",
  // dependencies: [],
  attachmentDocIds: [],
  principle: "",
  actionRefId: "0",
  isTechAssistanceRequired: false,
  references: [],
};
type IdTokenClaims = {
  roles: string[];
  idp: string;
  email: string;
  signInName: string;
  appTenantName: string;
  sub: string;
  appTenantId: string;
  QuestionId: string;
  acr: any;
};
//example data type
type Member = {
  id: string;
  roles: string;
  tenantId: string;
  userId: string;
  givenName: string;
  surname: string;
  email: string;
  status: string;
};
// const status: Record<string, string> = {
//   P: "PENDING",
//   I: "IN PROGRESS",
//   D: "DONE",
//   C: "CANCELLED",
//   H: "ON HOLD",
// };
enum status {
  N = "Not Started",
  P = "Pending",
  I = "In Progress",
  D = "Done",
  C = "Cancelled",
  H = "On Hold",
}
// const frequency: Record<string, string> = {
//   // D: "Daily",
//   // W: "Weekly",
//   // F: "Fortnightly",
//   //  M: "Monthly",
//   Q: "Quarterly",
//   H: "Half Yearly",
//   S: "Semi Annually",
//   Y: "Yearly",
//   A: "Annually",
//   N: "Not Applicable",
// };
enum frequency {
  Q = "Quarterly",
  S = "Semi Annually",
  A = "Annually",
  N = "Not Applicable",
}

// const priority: Record<number, string> = {
//   0: "Low",
//   1: "Medium",
//   2: "High",
// };
// const importance = ["High", "Critical"];
enum importance {
  C = "Critical",
  H = "High",
  N = "None",
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const ViewAction: React.FC<EditActionProps> = ({ actionId, isSystemAction, onClose }) => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<PolicyActionEntity>();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const [action, setAction] = React.useState("");
  const [values, setValues] = useState<PolicyActionEntity>(initialPolicyActionEntity);
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const [users, setUsers] = useState<Member[]>([]);
  const [allActions, setAllActions] = useState<PolicyActionEntity[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [existingFiles, setExistingFiles] = useState<any>([]);
  const [adoptedPolicies, setAdoptedPolicies] = useState<any[]>([]);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const appTenantId = (accounts[0]?.idTokenClaims as IdTokenClaims)?.appTenantId;
  const onDrop = async (acceptedFiles: any) => {
    // Check if the file with the same name already exists
    const isDuplicate = files.some((file: any) => file.name === acceptedFiles[0].name);
    if (!isDuplicate) {
      // If it's not a duplicate, add the file to the files array
      setFiles([...files, acceptedFiles[0]]);
    } else {
      console.log("File with the same name already exists!");
      // If it's a duplicate, set the error message
      setErrorMessage("File with the same name already exists!");
    }
  };
  const handleDownloadClick = async (filename: string) => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_SERVER
        }/rm/b2cpolicies/getFile?tenantId=${appTenantId}&referenceNumber=${
          filename.split("/")[0]
        }&fileName=${encodeURIComponent(filename.split("/")[1])}`
      );
      const parts = response.data.split(",");
      const contentType = parts[0].split(":")[1].split(";")[0];
      const base64String = parts[1];
      const blob = base64ToBlob(base64String, contentType);
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename.split("/")[1];
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleCancelClick = (index: number) => {
    // Create a new array excluding the canceled file
    const updatedFiles = [...files.slice(0, index), ...files.slice(index + 1)];
    setFiles(updatedFiles);
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
    // setSelectedRowId("");
  };
  const validationSchema = Yup.object({
    referenceNumber: Yup.string().required("Reference number is required"),
    title: Yup.string().required("Action is required"),
    // Add validations for other fields as needed
    costExternal: Yup.string().test(
      "is-decimal",
      "Input must be a number with up to two decimal places",
      (value) => {
        if (!value) return true; // Allow empty value
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    ),
    costStaffHours: Yup.string().test(
      "is-decimal",
      "Input must be a number with up to two decimal places",
      (value) => {
        if (!value) return true; // Allow empty value
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    ),
  });
  const formik = useFormik({
    initialValues: initialPolicyActionEntity,
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      try {
        console.log("===in submit");
        await formik.validateForm(); // Ensure the form is validated
        await Promise.all(
          files.map(async (file: any) => {
            const fileFormData = new FormData();
            fileFormData.append("file", file);
            fileFormData.append("referenceNumber", values["id"]);

            try {
              await axios.post(
                `${process.env.REACT_APP_API_SERVER}/rm/b2cpolicies/uploadFiles/${values["tenantId"]}`,
                fileFormData
              );
              alert("File uploaded successfully!");
            } catch (error: any) {
              console.error(
                "Error uploading file:",
                error.response ? error.response.data : error.message
              );
              alert(
                "Error uploading file: " + error.response ? error.response.data : error.message
              );
            }
          })
        );

        formik.values["nextDueDate"] = formik.values["dueDate"];

        const {
          dependencies,
          priority,
          isResolved,
          isActive,
          //isRecurring,
          lastUpdateDate,
          ...newFormik
        } = formik.values;

        let response = await axios.put(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/action/${formik.values["controlId"]}/${actionId}`,
          newFormik
        );

        if (response.status === 200) {
          setShowAlert(false);
          handleClose();
        } else {
          console.error("Error updating action", response.data.message);
          setAlertMessage("Error updating action" + response.data.message);
          setShowAlert(true);
        }
      } catch (err: any) {
        if (err.response && err.response.data && err.response.data.message) {
          console.error("Error submitting form:", err.response.data.message);
          setAlertMessage("Error submitting form: " + err.response.data.message);
        } else {
          console.error("Error submitting form:", err.message);
          setAlertMessage("Error submitting form: " + err.message);
        }
        setShowAlert(true);
      }
    },
  });
  const [oldValues, setOldValues] = useState(formik.values);

  useEffect(() => {
    setOpen(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/` + appTenantId
        );
        setUsers(response.data);
        // const response2 = await axios.get(
        //   `${process.env.REACT_APP_API_SERVER}/rm/policy/control/actions/${appTenantId}`
        // );
        // setAllActions(response2.data);

        const response1 = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/action/${appTenantId}/${actionId}`
        ); // Replace with your API endpoint
        setValues(response1.data);
        formik.setValues(response1.data);
        setOldValues(response1.data);
        const response3 = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/b2cpolicies/files?tenantId=${appTenantId}&referenceNumber=${response1.data.id}`
        );
        const response4 = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/${appTenantId}`
        );
        setAdoptedPolicies(response4.data);
        setExistingFiles(response3.data);
        setLoaded(true);
      } catch (error) {
        // setError("Error fetching data");
        setAdoptedPolicies([]);
      }
    };

    fetchData();
  }, []);

  // Define a state to manage the error message visibility
  const [statusChangeError, setStatusChangeError] = useState(false);
  // const role = accounts[0]?.idTokenClaims?.roles[0];

  const isAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes("Tenant.admin");
  const isGlobalAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles[0] === "GlobalAdmin";

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openFile = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeFiles = () => {
    setAnchorEl(null);
  };

  const handleDownloadAll = () => {
    existingFiles.forEach((file: string) => {
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = file; // Assuming file is the URL of the file to be downloaded
      link.download = ""; // Use the actual file name if available
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const [selectedRowId, setSelectedRowId] = useState<string>("");

  const handleItemClick = () => {
    setSelectedRowId(actionId);
    setOpen(false);
    // onClose();
  };

  function isValidUrl(string: any): string | undefined {
    try {
      const url = new URL(string);
      return url.href;
    } catch (_) {
      return undefined;
    }
  }
  return (
    <div>
      <Dialog
        //fullScreen
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        // onClose={handleClose}
        open={open}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography fontSize="16px" fontWeight="600">
                  View Action:
                </Typography>
                <Typography fontSize="16px" fontWeight="600" color="primary">
                  {formik.values && Math.floor(parseFloat(formik.values["actionRefId"])).toString()}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                {/* <Typography fontSize="12px" fontWeight="600" color="#66696C">
                  {dayjs(formik.values["lastUpdateDate"]).format("DD/MMM/YY - hh:mmA")} (Last
                  Updated)
                </Typography> */}
                <Button
                  variant="contained"
                  onClick={() => {
                    handleItemClick();
                  }}
                >
                  Edit Action
                </Button>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            {showAlert && <Alert severity="error">{alertMessage}</Alert>}
            {loaded ? (
              <>
                {isSystemAction && (
                  <>
                    <Stack direction="column" spacing={3}>
                      {/* action title */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          fontSize="14px"
                          color="rgba(17, 24, 39, 0.6)"
                          fontWeight="600"
                          // sx={{ marginLeft: "5px" }}
                        >
                          Action Title
                        </Typography>
                        <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                          {formik.values["title"] || ""}
                        </Typography>
                      </Box>

                      {/* description */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          fontSize="14px"
                          color="rgba(17, 24, 39, 0.6)"
                          fontWeight="600"
                          // sx={{ marginLeft: "5px" }}
                        >
                          Action Description
                        </Typography>
                        <Typography
                          fontSize="14px"
                          color="rgba(17, 24, 39, 1)"
                          fontWeight="400"
                          sx={{ wordWrap: "break-word" }}
                        >
                          {formik.values["description"] || ""}
                        </Typography>
                      </Box>
                      {/* control value  */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          fontSize="14px"
                          color="rgba(17, 24, 39, 0.6)"
                          fontWeight="600"
                          // sx={{ marginLeft: "5px" }}
                        >
                          Control
                        </Typography>
                        <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                          {adoptedPolicies.find(
                            (policy) => policy.id === formik.values["controlId"]
                          )?.control || ""}
                        </Typography>
                      </Box>
                      <Stack
                        direction="row"
                        spacing={20}
                        style={{ marginBottom: "10px", padding: "0px" }}
                      >
                        {/* Priority  */}

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                            width: "150px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            // sx={{ marginLeft: "5px" }}
                          >
                            Priority Level
                          </Typography>
                          <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                            {(importance as { [key: string]: string })[
                              formik.values["importance"]
                            ] || ""}
                          </Typography>
                        </Box>

                        {/* status */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                            width: "150px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            // sx={{ marginLeft: "5px" }}
                          >
                            Action Status
                          </Typography>
                          <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                            {(status as { [key: string]: string })[formik.values["status"]] || ""}
                          </Typography>
                        </Box>
                        {statusChangeError && (
                          <Typography variant="body2" color="error">
                            Status must be changed before saving.
                          </Typography>
                        )}

                        {/* manager */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                            width: "150px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            // sx={{ marginLeft: "5px" }}
                          >
                            Manager
                          </Typography>
                          <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                            {formik.values["manager"] &&
                            users.find((user) => user.userId === formik.values["manager"])
                              ? users.find((user) => user.userId === formik.values["manager"])
                                  ?.givenName +
                                " " +
                                users.find((user) => user.userId === formik.values["manager"])
                                  ?.surname
                              : ""}
                          </Typography>
                        </Box>

                        {/*owner  */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                            width: "150px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            // sx={{ marginLeft: "5px" }}
                          >
                            Action Owner
                          </Typography>
                          <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                            {formik.values["owner"] &&
                            users.find((user) => user.userId === formik.values["owner"])
                              ? users.find((user) => user.userId === formik.values["owner"])
                                  ?.givenName +
                                " " +
                                users.find((user) => user.userId === formik.values["owner"])
                                  ?.surname
                              : ""}
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack direction="row" spacing={20}>
                        {/*due date */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                            width: "150px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            // sx={{ marginLeft: "5px" }}
                          >
                            Due By
                          </Typography>
                          <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                            {new Date(formik.values["dueDate"]).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })}
                          </Typography>
                        </Box>

                        {/* isRecurring */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                            width: "150px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            // sx={{ marginLeft: "5px" }}
                          >
                            Recurring Action
                          </Typography>
                          <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                            {formik.values && formik.values["isRecurring"] === true ? "Yes" : "No"}
                          </Typography>
                        </Box>

                        {/* frequency */}

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                            width: "150px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            // sx={{ marginLeft: "5px" }}
                          >
                            Action Frequency
                          </Typography>
                          <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                            {(frequency as { [key: string]: string })[formik.values["frequency"]] ||
                              ""}
                          </Typography>
                        </Box>
                        {/* )} */}

                        {/* relevant priciple  */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                            width: "150px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            // sx={{ marginLeft: "5px" }}
                          >
                            Relevant Principle
                          </Typography>
                          <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                            {formik.values["principle"] === "G"
                              ? "Govern"
                              : formik.values["principle"] === "P"
                              ? "Protect"
                              : formik.values["principle"] === "D"
                              ? "Detect"
                              : formik.values["principle"] === "R"
                              ? "Respond"
                              : ""}
                          </Typography>
                        </Box>
                      </Stack>

                      {/* comments  and notes */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          gap: "5px",
                          // width: "100%",
                        }}
                      >
                        <Typography
                          fontSize="14px"
                          color="rgba(17, 24, 39, 0.6)"
                          fontWeight="600"
                          // sx={{ marginLeft: "5px" }}
                        >
                          Comments/Notes
                        </Typography>
                        <Typography
                          fontSize="14px"
                          color="rgba(17, 24, 39, 1)"
                          fontWeight="400"
                          sx={{ wordWrap: "break-word" }}
                        >
                          {formik.values["notes"] || ""}
                        </Typography>
                      </Box>
                      <Stack direction="row" spacing={20}>
                        {/* file upload */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            sx={{ marginLeft: "5px" }}
                          >
                            Attachments
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            // label="Action Description"
                            placeholder="Attachments"
                            name="Attachments"
                            multiline
                            type="text" // You may need to determine the type dynamically based on your data
                            value={
                              existingFiles || files
                                ? `${files.length + existingFiles.length} attached`
                                : ""
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            sx={{
                              height: "44px",
                              width: "400px",
                              borderRadius: "5px",
                              paddingY: "0px",
                            }}
                            InputProps={{
                              style: { fontSize: "14px", height: "44px", borderRadius: "5px" }, // Set font size here
                              endAdornment: (
                                <InputAdornment position="end">
                                  {files.length + existingFiles.length > 0 && (
                                    <IconButton onClick={handleClick}>
                                      <ArrowDown />
                                    </IconButton>
                                  )}
                                  <Menu
                                    id="file-menu"
                                    anchorEl={anchorEl}
                                    open={openFile}
                                    onClose={closeFiles}
                                  >
                                    {existingFiles &&
                                      existingFiles.map((file: string, index: number) => (
                                        <MenuItem key={index}>
                                          <div style={{ display: "flex", alignItems: "center" }}>
                                            <Typography>{file.split("/")[1]}</Typography>
                                            {/* <IconButton onClick={() => handleCancelClick(index)}>
                                              <Delete />
                                            </IconButton> */}
                                            <IconButton onClick={() => handleDownloadClick(file)}>
                                              <Download />
                                            </IconButton>
                                          </div>
                                        </MenuItem>
                                      ))}
                                  </Menu>

                                  {/* <IconButton>
                                    <Download />
                                  </IconButton>
                                  <IconButton>
                                    <Delete />
                                  </IconButton> */}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>

                        {/* source */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                            width: "150px",
                          }}
                        >
                          <Typography
                            fontSize="14px"
                            color="rgba(17, 24, 39, 0.6)"
                            fontWeight="600"
                            // sx={{ marginLeft: "5px" }}
                          >
                            Action Source
                          </Typography>
                          <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                            {formik.values["source"] === "M" ? "Manual" : "RRM"}
                          </Typography>
                        </Box>

                        {/* technical action */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            gap: "5px",
                            width: "150px",
                          }}
                        >
                          <FormControl component="fieldset">
                            {/* <FormLabel component="legend">Recurring Action</FormLabel> */}
                            <Typography
                              fontSize="14px"
                              color="rgba(17, 24, 39, 0.6)"
                              fontWeight="600"
                              // sx={{ marginLeft: "5px" }}
                            >
                              Technical Action?
                            </Typography>
                            <Typography
                              fontSize="14px"
                              color="rgba(17, 24, 39, 1)"
                              fontWeight="400"
                            >
                              {formik.values && formik.values["isTechAssistanceRequired"] === true
                                ? "Yes"
                                : "No"}
                            </Typography>
                          </FormControl>
                        </Box>
                      </Stack>

                      {/*  Useful Reference Information */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          fontSize="14px"
                          color="rgba(17, 24, 39, 0.6)"
                          fontWeight="600"
                          // sx={{ marginLeft: "5px" }}
                        >
                          Useful Reference Information
                        </Typography>
                        {formik.values["references"].map(
                          (
                            reference: { [s: string]: unknown } | ArrayLike<unknown>,
                            index: React.Key | null | undefined
                          ) => (
                            <Typography
                              key={index}
                              fontSize="14px"
                              color="rgba(17, 24, 39, 1)"
                              fontWeight="400"
                            >
                              {Object.entries(reference).map(([key, value]) => (
                                <div key={key}>
                                  {(value as string).includes("Policy Manager") ? (
                                    <>
                                      <span style={{ fontWeight: "600" }}>
                                        {key}
                                        {value === "N/A" ? "" : ":"}{" "}
                                      </span>
                                      <a
                                        href="/PolicyBuilder"
                                        style={{ color: "blue", textDecoration: "underline" }}
                                      >
                                        Policy Manager
                                      </a>
                                    </>
                                  ) : (value as string) ===
                                    "<link to Third Party Supplier Register>" ? (
                                    <>
                                      <span style={{ fontWeight: "600" }}>
                                        {key}
                                        {value === "N/A" ? "" : ":"}{" "}
                                      </span>
                                      <a
                                        href="/AssetManagement"
                                        style={{ color: "blue", textDecoration: "underline" }}
                                      >
                                        Registers
                                      </a>
                                    </>
                                  ) : (value as string).includes("Systems Register") ? (
                                    <>
                                      <span style={{ fontWeight: "600" }}>
                                        {key}
                                        {value === "N/A" ? "" : ":"}{" "}
                                      </span>
                                      <a
                                        href="/AssetManagement"
                                        style={{ color: "blue", textDecoration: "underline" }}
                                      >
                                        Registers
                                      </a>
                                    </>
                                  ) : (value as string).includes("Hardware and Device Register") ? (
                                    <>
                                      <span style={{ fontWeight: "600" }}>
                                        {key}
                                        {value === "N/A" ? "" : ":"}{" "}
                                      </span>
                                      <a
                                        href="/AssetManagement"
                                        style={{ color: "blue", textDecoration: "underline" }}
                                      >
                                        Registers
                                      </a>
                                    </>
                                  ) : (value as string).includes("link to Dashboard") ? (
                                    <>
                                      <span style={{ fontWeight: "600" }}>
                                        {key}
                                        {value === "N/A" ? "" : ":"}{" "}
                                      </span>
                                      <a
                                        href="/Dashboard"
                                        style={{ color: "blue", textDecoration: "underline" }}
                                      >
                                        Dashboard
                                      </a>
                                    </>
                                  ) : isValidUrl(value) ? (
                                    <span style={{ fontWeight: "600" }}>
                                      <a
                                        href={value as string}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "blue", textDecoration: "underline" }}
                                      >
                                        {key}
                                      </a>
                                    </span>
                                  ) : (
                                    <span>{value === "N/A" ? "" : value}</span>
                                  )}

                                  {/* {value} */}
                                </div>
                              ))}
                            </Typography>
                          )
                        )}
                      </Box>
                    </Stack>
                  </>
                )}
              </>
            ) : (
              <div> Loading...</div>
            )}
          </DialogContent>
        </form>
      </Dialog>
      {selectedRowId !== "" && (
        <EditAction actionId={selectedRowId} isSystemAction={true} onClose={handleClose} />
      )}
    </div>
  );
};
export default ViewAction;
