/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-comment-textnodes */
import { useMsal } from "@azure/msal-react";
import { Box, Button, Card, CardContent, Divider, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditAction from "../Actions/EditAction";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import "./workflow.css";
import ActionOverview from "./ActionOverview";
import ActionChart from "./ActionChart";
import ActionList from "./ActionList";
type IdTokenClaims = {
  roles: string[];
  idp: string;
  email: string;
  signInName: string;
  appTenantName: string;
  sub: string;
  appTenantId: string;
  questionId: string;
  acr: any;
  allTenants: any[];
};
type PolicyActionEntity = {
  referenceNumber: string;
  id: string;

  policyId: string;

  tenantId: string;

  code: string;

  title: string;

  description: string;

  importance: string;

  suggestedTimePeriod: Map<number, string>;

  owner: string;

  manager: string;

  status: string;

  frequency: string;

  priority: number;

  comments: string;

  dueDate: Date;

  nextDueDate: Date;

  lastUpdateDate: Date;

  isRecurring: boolean;

  source: string;

  isResolved: boolean;

  costExternal: string;

  staffHours: string;

  dependencies: Array<PolicyActionEntity>;

  attachmentDocIds: string[];

  assignedActioner: string;

  isActive: boolean;
};
const statusColors: Record<string, string> = {
  N: "black",
  U: "black",
  O: "red",
  C: "blue",
  default: "gray",
};
type AccumulatorType = {
  item: PolicyActionEntity;
  nextDueDate: Date | null;
  firstAction: boolean;
};

// =============Tabs====================
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      // id={`simple-tabpanel-${index}`}
      // aria-labelledby={`simple-tab-${index}`}
      // {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// ====================================================
const WorkflowManager: React.FC = () => {
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const appTenantId = (accounts[0].idTokenClaims as IdTokenClaims).appTenantId;
  const [data, setData] = useState<any>();
  const [loaded, setLoaded] = useState(false);
  const [actionsData, setActionsData] = useState<PolicyActionEntity[]>();
  const [initialRScoreDate, setInitialRScoreDate] = useState<Date>(new Date());
  const [selectedItemIndex, setSelectedItemIndex] = useState<string | null>(null);
  const [principleGridsP, setPrincipleGridsP] = useState<JSX.Element[]>([]);
  const [principleGridsG, setPrincipleGridsG] = useState<JSX.Element[]>([]);
  const [principleGridsD, setPrincipleGridsD] = useState<JSX.Element[]>([]);
  const [principleGridsR, setPrincipleGridsR] = useState<JSX.Element[]>([]);
  const [principleGridsN, setPrincipleGridsN] = useState<JSX.Element[]>([]);

  const handleItemClick = (index: string, isActive: boolean) => {
    // Toggle the selected item index
    console.log("Called handleItemClick", index, isActive);
    if (isActive) setSelectedItemIndex(selectedItemIndex === index ? null : index);
  };
  const handleClose = () => {
    console.log("Called onclose");
    setSelectedItemIndex(null);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/org/r_scores/${appTenantId}`
        ); // Replace with your API endpoint
        setInitialRScoreDate(new Date(response.data.initialPrincipalRScoreDate));
        setData(response.data);
      } catch (error) {
        setLoaded(false);
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/actions/${appTenantId}`
        ); // Replace with your API endpoint
        setActionsData(response.data);
        const resultG = await renderPrincipleGrids(
          "G",
          data,
          response.data.filter((action: PolicyActionEntity) => action.isActive),
          initialRScoreDate
        );
        setPrincipleGridsG(resultG);
        const resultP = await renderPrincipleGrids(
          "P",
          data,
          response.data.filter((action: PolicyActionEntity) => action.isActive),
          initialRScoreDate
        );
        setPrincipleGridsP(resultP);
        const resultD = await renderPrincipleGrids(
          "D",
          data,
          response.data.filter((action: PolicyActionEntity) => action.isActive),
          initialRScoreDate
        );
        setPrincipleGridsD(resultD);
        const resultR = await renderPrincipleGrids(
          "R",
          data,
          response.data.filter((action: PolicyActionEntity) => action.isActive),
          initialRScoreDate
        );
        setPrincipleGridsR(resultR);
        const resultN = await renderPrincipleGrids(
          "M",
          data,
          response.data.filter((action: PolicyActionEntity) => action.isActive),
          initialRScoreDate
        );
        setPrincipleGridsN(resultN);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoaded(true);
      }
    };
    // setInitialRScoreDate(new Date("2024-01-01"));
    fetchData();
  }, [selectedItemIndex]);
  const getStatusColor = (item: PolicyActionEntity, isActive: boolean) => {
    if (isActive) {
      if (item.status !== "D" && item.dueDate < new Date()) {
        return "red";
      } else if (item.status === "P") {
        return "gray";
      } else if (item.status === "D") {
        return "blue";
      } else return "black";
    } else return "purple";
  };
  async function renderPrincipleGrids(
    principle: any,
    data: any,
    actionsData: PolicyActionEntity[] | undefined,
    initialRScoreDate: Date
  ) {
    const principleGrids = [];

    for (let month = 0; month < 12; month++) {
      const currentMonthDate = new Date(initialRScoreDate);
      currentMonthDate.setMonth(initialRScoreDate.getMonth() + month);

      const startOfMonth = new Date(currentMonthDate.getFullYear(), currentMonthDate.getMonth(), 1);
      const endOfMonth = new Date(
        currentMonthDate.getFullYear(),
        currentMonthDate.getMonth() + 1,
        0
      );
      const asyncReducer = async (accumulator: AccumulatorType[], item: PolicyActionEntity) => {
        const itemDueDate = new Date(item.dueDate);
        const itemDueDateWithoutTime = new Date(
          itemDueDate.getFullYear(),
          itemDueDate.getMonth(),
          itemDueDate.getDate()
        );

        /* if (item.isRecurring) {
          let nextDueDate = new Date(item.dueDate);
          let firstAction = true;
          while (nextDueDate <= endOfMonth) {
            if (
              item.referenceNumber.startsWith(principle) &&
              nextDueDate >= startOfMonth &&
              nextDueDate <= endOfMonth
            ) {
              accumulator.push({ item, nextDueDate, firstAction: firstAction });
            }

            switch (item.frequency) {
              case "Q":
                nextDueDate.setMonth(nextDueDate.getMonth() + 3);
                break;
              case "M":
                nextDueDate.setMonth(nextDueDate.getMonth() + 1);
                break;
              case "H":
                nextDueDate.setMonth(nextDueDate.getMonth() + 6);
                break;
              case "Y" || "A":
                nextDueDate.setMonth(nextDueDate.getMonth() + 12);
                break;
              default:
                // Handle other frequencies if needed
                console.log(
                  "nextDueDate",
                  nextDueDate,
                  endOfMonth,
                  startOfMonth,
                  item.referenceNumber
                );
                nextDueDate.setMonth(nextDueDate.getMonth() + 12);
                break;
            }
            firstAction = false;
          }
        } else */ if (
          item.referenceNumber.startsWith(principle) &&
          itemDueDateWithoutTime >= startOfMonth &&
          itemDueDateWithoutTime <= endOfMonth
        ) {
          accumulator.push({ item, nextDueDate: null, firstAction: true });
        }

        return accumulator;
      };
      const promises: Promise<AccumulatorType[]>[] = (actionsData ?? [])
        .filter((action) => action.referenceNumber.startsWith(principle))
        .map(async (item) => {
          const result = await asyncReducer([], item);
          return result;
        });

      const filteredActions: Array<{
        item: PolicyActionEntity;
        nextDueDate: Date | null;
        firstAction: boolean;
      }> = (await Promise.all(promises)).flat();

      // console.log(filteredActions);
      principleGrids.push(
        <React.Fragment key={month}>
          <Grid item xs={2}>
            {filteredActions.length > 0 &&
              filteredActions.map(({ item, nextDueDate, firstAction }, index) => (
                <React.Fragment key={item.id}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* <div
                      style={{
                        width: "30px",
                        height: "2px",
                        backgroundColor: "black",
                      }}
                    ></div> */}
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        backgroundColor: getStatusColor(item, item.isActive),
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                      onClick={() => handleItemClick(item.id, item.isActive)}
                    >
                      {item?.referenceNumber?.split(".")[2] ?? ""}
                    </div>
                    {selectedItemIndex === item.id && (
                      <EditAction actionId={item.id} isSystemAction={true} onClose={handleClose} />
                    )}
                    {/*                   {index !== actionsData.length - 1 && <div style={{ width: "10px" }}></div>}
                     */}{" "}
                  </div>
                </React.Fragment>
              ))}
          </Grid>
        </React.Fragment>
      );
    }

    return principleGrids;
  }

  // ===================================================

  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };

  return (
    <Box className="main-box">
      <Box
      // sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Action Overview" {...a11yProps(0)} style={{ color: "#233DFF" }} />
          <Tab label="Action Chart" {...a11yProps(1)} style={{ color: "#233DFF" }} />
          <Tab label="Action List" {...a11yProps(2)} style={{ color: "#233DFF" }} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ActionOverview />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ActionChart />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ActionList />
      </CustomTabPanel>
    </Box>
  );
};
export default WorkflowManager;
