import { useMsal } from "@azure/msal-react";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Modal,
  Typography,
  createTheme,
  useTheme,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "./styles.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
//example data type
type Member = {
  id: string;
  roles: string;
  tenantId: string;
  userId: string;
  givenName: string;
  surname: string;
  email: string;
  status: string;
};
function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflow: "hidden",
    height: "100%",
    width: "100%",
  },
  iframe: {
    display: "block",
    "border-style": "none",
    width: "100px",
    height: "50px",
    margin: "auto",
    "background-color": "#777",
  },
}));
const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

const PolicyManager: React.FC = () => {
  const baseTheme = useTheme();
  const newTheme = createTheme(baseTheme, {
    palette: {
      background: { default: "rgba(255,255,255,255)" },
    },
    indicator: {
      backgroundColor: "green",
      height: "10px",
      top: "45px",
    },
  });

  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const appTenantId = (accounts[0].idTokenClaims as IdTokenClaims).appTenantId;
  const [policies, setPolicies] = useState([]);
  const [openViewer, setViewerOpen] = useState(false);
  const [viewerUrl, setViewerUrl] = useState<Blob>();
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageScale, setPageScale] = useState(1);
  const [modalStyle] = useState(getModalStyle);
  const [selectedPolicyRefId, setSelectedPolicyRefId] = useState<string>("");
  const [downloadUrl, setDownloadUrl] = useState<string>();
  const [reLoad, setReLoad] = useState(false);
  const [users, setUsers] = useState<Member[]>([]);
  const classes = useStyles();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handleViewerClose = () => {
    setViewerOpen(false);
  };

  function handleZoomIn() {
    if (pageScale < 3) {
      setPageScale(pageScale + 0.2);
    }
  }

  function handleZoomOut() {
    if (pageScale > 0.3) {
      setPageScale(pageScale - 0.2);
    }
  }

  function handleNext() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  }
  function handlePrevious() {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      const userResponse = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/` + appTenantId
      );
      setUsers((prevValue: Member[]) => [...prevValue, ...userResponse.data]);
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/rm/policy/all/${appTenantId}`
      );

      console.log(response.data);
      setPolicies(response.data);
    };
    fetchData();
  }, [reLoad]);

  const launchPolicyPDF = (policyRefId: string) => async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/rm/policy/adopt/${appTenantId}/${policyRefId}`
    );

    const file = response.data;
    setViewerUrl(file);
    setDownloadUrl(file);
    setViewerOpen(true);
    setSelectedPolicyRefId(policyRefId);
    setPageNumber(1);
  };
  const handleDownload = () => {
    const filename = "policy.pdf";
    const link = document.createElement("a");
    if (!viewerUrl) return;
    link.href = downloadUrl ?? "";
    link.download = `${filename}-${+new Date()}.pdf`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  };

  return (
    <>
      <div>
        <h1>Resolute RM | Policy Manager</h1>
      </div>
      <Box m={10}>
        <Grid container spacing={2}>
          <Grid item xs={2} sx={{ border: "1px solid #000" }}>
            <Typography variant="h4">Policy</Typography>
          </Grid>
          <Grid item xs={2} sx={{ border: "1px solid #000" }}>
            <Typography variant="h4">Details</Typography>
          </Grid>
          <Grid item xs={2} sx={{ border: "1px solid #000" }}>
            <Typography variant="h4">Owner</Typography>
          </Grid>
          <Grid item xs={2} sx={{ border: "1px solid #000" }}>
            <Typography variant="h4">Date & Version</Typography>
          </Grid>
          <Grid item xs={2} sx={{ border: "1px solid #000" }}>
            <Typography variant="h4">Source</Typography>
          </Grid>
          <Grid item xs={2} sx={{ border: "1px solid #000" }}>
            <Typography variant="h4">Link</Typography>
          </Grid>

          {policies &&
            policies
              .sort((policyA: any, policyB: any) => {
                const policyRefIdA = policyA.policyRefId || ""; // handle potential null or undefined
                const policyRefIdB = policyB.policyRefId || "";

                // Use localeCompare for string comparison
                return policyRefIdA - policyRefIdB;
              })
              .map((policy: any, index) => (
                <>
                  <>
                    <Grid item xs={2} sx={{ border: "1px solid #000" }}>
                      <Typography variant="h6">{policy.title}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ border: "1px solid #000" }}>
                      <Typography variant="h6">{policy.detail}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ border: "1px solid #000" }}>
                      <Typography variant="h6">
                        {users.find((user) => user.userId === policy.owner)?.givenName}{" "}
                        {users.find((user) => user.userId === policy.owner)?.surname}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ border: "1px solid #000" }}>
                      <Typography variant="h6">
                        {policy.status === "A" &&
                          new Date(policy.lastUpdateDate).toLocaleDateString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ border: "1px solid #000" }}>
                      <Typography variant="h6">
                        {policy.source === "RRM" ? "Resolute RM" : "Client"}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ border: "1px solid #000" }}>
                      {policy.status == "A" && (
                        <Button>
                          <Typography variant="h6" onClick={launchPolicyPDF(policy.policyRefId)}>
                            View
                          </Typography>
                        </Button>
                      )}
                    </Grid>
                  </>
                  <Dialog
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={openViewer}
                    onClose={handleViewerClose}
                    className={classes.paper}
                    style={modalStyle}
                    maxWidth="lg"
                  >
                    <DialogContent>
                      <Box>
                        <div className="document-container">
                          <div className="footer">
                            <div className="button-container">
                              <Button onClick={handleZoomIn} disabled={pageScale >= 3}>
                                Zoom +
                              </Button>
                              <Button onClick={handleZoomOut} disabled={pageScale <= 0.3}>
                                Zoom -
                              </Button>
                            </div>
                            <div className="page-text">
                              Page {pageNumber} of {numPages}
                            </div>
                            <div className="button-container">
                              <Button onClick={handlePrevious} disabled={pageNumber === 1}>
                                ‹ Previous
                              </Button>
                              <Button onClick={handleNext} disabled={pageNumber === numPages}>
                                Next ›
                              </Button>
                            </div>
                          </div>
                          <div className="page-container">
                            <Document
                              file={viewerUrl}
                              onLoadSuccess={onDocumentLoadSuccess}
                              options={options}
                            >
                              <Page pageNumber={pageNumber} scale={pageScale} />
                            </Document>
                          </div>
                          <div className="footer">
                            <div className="button-container">
                              <Button onClick={handleDownload} disabled={pageScale >= 3}>
                                Download
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </DialogContent>
                  </Dialog>
                </>
              ))}
        </Grid>
      </Box>
    </>
  );
};
export default PolicyManager;
