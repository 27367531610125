/* eslint-disable react/jsx-no-undef */
import { useMsal } from "@azure/msal-react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Snackbar,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import {
  MRT_ColumnDef,
  MRT_EditActionButtons,
  MRT_Row,
  MRT_ShowHideColumnsButton,
  MRT_TableOptions,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import MuiToggleButton from "@mui/material/ToggleButton";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import EditAction from "../Actions/EditAction";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import EditActionForm from "../Actions/EditActionForm";
import AddAction from "../Actions/AddAction";
import ConfirmDialog from "../../helpers/dialog/ConfirmDialog";
import { jsPDF } from "jspdf"; //or use your library of choice here
import autoTable from "jspdf-autotable";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
const queryClient = new QueryClient();
const status: Record<string, string> = {
  N: "NOT STARTED",
  P: "PENDING",
  I: "IN PROGRESS",
  D: "DONE",
  C: "CANCELLED",
  H: "ON HOLD",
};

export const DetailWorkflow = () => {
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const appTenantId = (accounts[0].idTokenClaims as IdTokenClaims).appTenantId;
  const myUserId = (accounts[0].idTokenClaims as IdTokenClaims).sub;
  const [users, setUsers] = React.useState<Member[]>([]);
  const [selectedRowId, setSelectedRowId] = useState<string>("");
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(false);
  const [adoptedPolicies, setAdoptedPolicies] = useState<any[]>([]);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [deleteRowId, setDeleteRowId] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>("");
  const [viewActions, setViewActions] = useState<string>("M");
  const handleItemClick = (index: string) => {
    // Toggle the selected item index
    setSelectedRowId(index);
  };
  const handleAddClick = () => {
    setShowAdd(true);
  };
  useEffect(() => {
    refetchActions();
  }, [selectedRowId, showAdd, deleted]);

  /*   useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/` + appTenantId
        );
        setUsers((prevValue: Member[]) => [...prevValue, ...userResponse.data]);
      } catch (error) {}
    };

    fetchUsers();
  }, []);
 */
  function useAllActions() {
    return useQuery<PolicyActionEntity[]>({
      queryKey: ["actions", appTenantId, myUserId, viewActions],
      queryFn: async ({ queryKey }) => {
        //send api request here
        // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
        const [_, appTenantId, myUserId, viewActions] = queryKey;
        const policyActionsResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/actions/${appTenantId}`
        );
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/` + appTenantId
        );
        setUsers((prevValue: Member[]) => [...prevValue, ...userResponse.data]);

        //for the current user find the user id
        console.log("users", userResponse.data, myUserId);
        const userId = userResponse.data?.find(
          (user: { userId: string }) => user.userId === myUserId
        )?.userId;
        //if view my action is M then filter the actions by owner
        console.log("my user id", userId, viewActions);
        if (viewActions === "M") {
          return policyActionsResponse.data
            .filter((action: PolicyActionEntity) => action.isActive)
            .filter((action: { owner: string | undefined }) => action.owner === userId);
        }

        return policyActionsResponse.data.filter((action: PolicyActionEntity) => action.isActive);
      },
      refetchOnWindowFocus: true,
      staleTime: 0,
      refetchInterval: 0,
    });
  }

  const {
    data: fetchedActions = [],
    isError: isLoadingActionsError,
    isFetching: isFetchingActions,
    isLoading: isLoadingActions,
    refetch: refetchActions,
  } = useAllActions();

  const columns = useMemo<MRT_ColumnDef<PolicyActionEntity>[]>(
    () => [
      {
        accessorKey: "referenceNumber", //access nested data with dot notation
        header: "Action REF",
        size: 5,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "10vh",
              whiteSpace: "pre-wrap",
            }}
          >
            <span>{row.original.referenceNumber}</span>
          </Box>
        ),
      },
      {
        accessorKey: "owner",
        header: "Owner",
        size: 10,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>
              {row.original.owner
                ? users?.find((user) => user.userId === row.original.owner)?.givenName +
                  " " +
                  users?.find((user) => user.userId === row.original.owner)?.surname
                : ""}
            </span>
          </Box>
        ),
      },
      {
        accessorKey: "manager",
        header: "Manager",
        size: 10,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>
              {row.original.owner
                ? users?.find((user) => user.userId === row.original.owner)?.givenName +
                  " " +
                  users?.find((user) => user.userId === row.original.owner)?.surname
                : ""}
            </span>
          </Box>
        ),
      },
      {
        accessorKey: "title", //access nested data with dot notation
        header: "Action",
        size: 10,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "60vh",
              whiteSpace: "pre-wrap",
            }}
          >
            <span>{row.original.title}</span>
          </Box>
        ),
      },
      {
        accessorKey: "dueDate",
        header: "Due Date",
        size: 10,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{new Date(row.original.dueDate).toLocaleDateString("en-AU")}</span>
          </Box>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 10,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "10vh",
              whiteSpace: "pre-wrap",
            }}
          >
            <span>{status[row.original.status]}</span>
          </Box>
        ),
      },
    ],
    [users]
  );
  const handleExportRows = (rows: any) => {
    const doc = new jsPDF();
    //derive column headers and accesorKey from the columns array
    const tableHeaders = columns.map((column) => column.header);
    console.log("rows", rows);
    //create a new structure from columns accessorKey
    const colStruct = [
      { accessorKey: "referenceNumber", header: "Action REF" },
      { accessorKey: "owner", header: "Owner" },
      { accessorKey: "manager", header: "Manager" },
      { accessorKey: "action", header: "Action" },
      { accessorKey: "dueDate", header: "Due Date" },
      { accessorKey: "status", header: "Status" },
    ];

    //from rows.original get the values for each column using colStruct
    const tableData = rows.map((row: any) =>
      colStruct.map((col) => {
        if (col.accessorKey === "dueDate") {
          return new Date(row.original[col.accessorKey]).toLocaleDateString("en-AU");
        }
        if (col.accessorKey === "status") {
          return status[row.original[col.accessorKey]];
        }
        if (col.accessorKey === "owner" || col.accessorKey === "manager") {
          return (
            users?.find((user) => user.id === row.original[col.accessorKey])?.givenName +
            " " +
            users?.find((user) => user.id === row.original[col.accessorKey])?.surname
          );
        }
        return row.original[col.accessorKey];
      })
    );

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save("detail-actions.pdf");
    console.log("tableHeaders", tableHeaders);
    console.log("tableData", tableData);
  };
  //column definitions...
  const handleSaveAction: MRT_TableOptions<PolicyActionEntity>["onEditingRowSave"] = async ({
    values,
    table,
    row,
  }) => {
    console.log("values", values);
    await updateAction(values);
    table.setEditingRow(null); //exit editing mode
  };
  function useUpdateAction() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (action: PolicyActionEntity) => {
        //send api update request here
        console.log("here in mutation Fn");
        await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
        return Promise.resolve();
      },
      //client side optimistic update
      onMutate: (newActionInfo: PolicyActionEntity) => {
        console.log("newActionInfo", newActionInfo);
        queryClient.setQueryData(["actions"], (prevUsers: any) =>
          prevUsers?.map((prevUser: PolicyActionEntity) =>
            prevUser.id === newActionInfo.id ? newActionInfo : prevUser
          )
        );
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
    });
  }
  const { mutateAsync: updateAction, isPending: isUpdatingAction } = useUpdateAction();
  const handleClose = () => {
    console.log("Called onclose");
    setSelectedRowId("");
    setShowAdd(false);
    setAlertText("Action added/edited successfully");
    setShowAlert(true);
  };
  const handleDelete = async () => {
    const id = deleteRowId;
    //get adopted policies
    try {
      // const appTenantId = (accounts[0]?.idTokenClaims as IdTokenClaims)?.appTenantId;
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/rm/policy/control/${appTenantId}`
      );
      setAdoptedPolicies(response.data);
      console.log("id", id);
      //find control actions that are adopted
      const control = response.data.find((policy: { actionIds: string | string[] }) =>
        policy.actionIds.includes(id)
      );

      let request = await axios.delete(
        `${process.env.REACT_APP_API_SERVER}/rm/policy/control/action/${appTenantId}/${control.id}/${id}`
      );
      setDeleted(true);

      setAlertText("Action deleted successfully");
      setShowAlert(true);
    } catch (error) {
      // setError("Error fetching data");
    }
  };
  const table = useMaterialReactTable({
    columns,
    data: fetchedActions, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: true,
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default, //change default background color
      boxShadow: "none",
    }),
    muiTableBodyRowProps: {
      hover: false,
      sx: {
        whiteSpace: "nowrap",
      },
    },
    muiTableProps: {
      sx: {
        // border: "1px solid rgba(81, 81, 81, .5)",
        boxShadow: "none !important",
        whiteSpace: "nowrap",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        border: "1px solid rgba(81, 81, 81, .5)",
        // borderLeft: "1px solid rgba(81, 81, 81, .5)",
        // borderTop: "1px solid rgba(81, 81, 81, .5)",
        // fontStyle: "italic",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        textAlign: "center",
        boxShadow: "none",
        paddingLeft: "20px",
        backgroundColor: "#F9F9FB",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderBottom: "1px solid rgba(81, 81, 81, .5)",
        // whiteSpace: "pre-wrap",
        paddingLeft: "20px",
        paddingRight: "20px",
      },
    },
    state: {
      isLoading: isLoadingActions,
      showAlertBanner: isLoadingActionsError,
      showProgressBars: isFetchingActions,
    },
    initialState: { density: "compact" },
    enableRowActions: true,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "Actions", //change "Actions" to "Edit"
        size: 10,
        //use a text button instead of a icon button
        Cell: ({ row, table }) => (
          <div style={{ width: "61px" }}>
            <Button
              onClick={() => {
                console.log("clicked", row.original.id);
                handleItemClick(row.original.id);
              }}
              style={{ minWidth: "20px", padding: 0 }}
              disabled={row.original.status === "D" || row.original.status === "C"}
            >
              <EditIcon />
            </Button>
            <Button
              onClick={() => {
                console.log("clicked", row.original.id);
                setDeleteRowId(row.original.id);
                setConfirmOpen(true);
              }}
              style={{ width: "10px", padding: 0 }}
            >
              <DeleteIcon />
            </Button>
          </div>
        ),
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        sx={{ backgroundColor: "#010041", textTransform: "none" }}
        onClick={() => {
          handleAddClick();
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Add Action
      </Button>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <Box>
        {/* add custom button to print table  */}
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() => {
            console.log(table);
            handleExportRows(table.getPrePaginationRowModel().rows);
          }}
          startIcon={<FileDownloadIcon />}
        ></Button>
        {/* along-side built-in buttons in whatever order you want them */}
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleDensePaddingButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </Box>
    ),
  });
  const handleChange = (event: React.MouseEvent<HTMLElement>, newView: string) => {
    setViewActions(newView);
  };

  const account = instance.getActiveAccount();
  const isAdmin = (account?.idTokenClaims as IdTokenClaims)?.roles?.includes("Tenant.admin");
  const isGlobalAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles[0] === "GlobalAdmin";

  return (
    <div style={{ padding: "15px", backgroundColor: "#F9F9FB" }}>
      <Box
        style={{
          backgroundColor: "white",
          padding: "15px",
          borderRadius: "4px",
          minHeight: "90vh",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {(isAdmin || isGlobalAdmin) && (
              <Link to="/WorkflowManager">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
            )}
            <Typography variant="h3" sx={{ lineHeight: 1 }}>
              <b>Detailed Workflow Manager</b>
            </Typography>
          </div>
          {/* <ToggleButtonGroup
            value={viewActions}
            exclusive
            onChange={handleChange}
            sx={{ height: "30px", width: "250px" }}
          >
            <ToggleButton
              value="M"
              // selectedColor="#52009b"
              sx={{ textTransform: "capitalize", borderColor: "#010041" }}
            >
              My Actions
            </ToggleButton>
            <ToggleButton
              value="A"
              // selectedColor="#52009b"
              sx={{ textTransform: "capitalize", borderColor: "#010041" }}
            >
              All Actions
            </ToggleButton>
          </ToggleButtonGroup> */}
          <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
            <Button
              variant="contained"
              onClick={() => {
                setViewActions("M");
                refetchActions();
              }}
            >
              My Actions
            </Button>
            {(accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes("Tenant.admin") && (
              <Button
                variant="contained"
                onClick={() => {
                  setViewActions("A");
                  refetchActions();
                }}
              >
                All Actions
              </Button>
            )}
          </div>
        </Box>

        {showAlert && (
          <Snackbar
            open={showAlert}
            autoHideDuration={5000}
            onClose={() => setShowAlert(false)}
            message={alertText}
          />
        )}

        <Divider sx={{ mt: "10px", mb: "15px" }} />
        <Box
        // sx={{ height: "60vh" }}
        >
          <MaterialReactTable table={table} />

          {selectedRowId !== "" && (
            <EditAction actionId={selectedRowId} isSystemAction={true} onClose={handleClose} />
          )}
          {showAdd && <AddAction isSystemAction={true} onClose={handleClose} />}
          <ConfirmDialog
            title="Delete Action?"
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={handleDelete}
          >
            Are you sure you want to delete this action?
          </ConfirmDialog>
        </Box>
      </Box>
    </div>
  );
};

const DetailWorkflowManager: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <DetailWorkflow />
    </QueryClientProvider>
  );
};

export default DetailWorkflowManager;
