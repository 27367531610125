import { useMsal } from "@azure/msal-react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { Link } from "react-router-dom";
import { SetStateAction, useMemo } from "react";
import {
  MRT_Table, //import alternative sub-component if we do not want toolbars
  type MRT_ColumnDef,
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import axios from "axios";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import "./dashboard.css";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import { ReactComponent as QuestionIcon } from "../../assets/help.svg";
import { ReactComponent as LeftArrow } from "../../assets/keyboard_arrow_left.svg";
import { ReactComponent as RightArrow } from "../../assets/chevron_right.svg";
import { ReactComponent as Expand } from "../../assets/expand_content.svg";
import { ReactComponent as Account } from "../../assets/account_circle.svg";
import { ReactComponent as ArrowDown } from "../../assets/keyboard_arrow_down.svg";
import { ReactComponent as Calendar } from "../../assets/calendar_month.svg";
import { ReactComponent as Tab } from "../../assets/tabs.svg";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Cell, Label, Legend, Pie, PieChart } from "recharts";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { log } from "console";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { get } from "lodash";
import ViewAction from "../Actions/ViewAction";

// customised tooltip
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F8F8FF",
    color: "#000706",
    fontSize: 12,
    width: "400px",
    maxWidth: "none",
    height: "340px",
    maxHeight: "none",
    boxShadow: "0px 4px 16px 0px #0000004D",
    padding: "8px",
  },
}));
type IdTokenClaims = {
  roles: string[];
  idp: string;
  email: string;
  signInName: string;
  appTenantName: string;
  sub: string;
  appTenantId: string;
  questionId: string;
  acr: any;
  allTenants: any[];
};
type PolicyActionEntity = {
  action: string;
  id: string;

  policyId: string;

  tenantId: string;

  code: string;

  title: string;

  description: string;

  importance: string;

  suggestedTimePeriod: Map<number, string>;

  owner: string;
  ownerGivenName: string;
  ownerSurname: string;

  isActive: boolean;

  manager: string;

  status: string;

  frequency: string;

  priority: number;

  comments: string;

  dueDate: Date;

  nextDueDate: Date;

  lastUpdateDate: Date;

  isRecurring: boolean;

  source: string;

  isResolved: boolean;

  costExternal: string;

  staffHours: string;

  dependencies: Array<PolicyActionEntity>;

  attachmentDocIds: string[];

  assignedActioner: string;
};
type Policies = {
  id: string;

  tenantId: string;

  control: string;

  controlSource: string;

  subPrinciple: Map<string, string>;

  principle: string;

  context: string;

  externalReferenceLink: string;

  recordInitiatedBy: string;

  recordInitiatedDate: Date;

  lastUpdateDate: Date;

  lastUpdatedBy: string;

  actions: PolicyActionEntity[];
};
const status: Record<string, string> = {
  N: "NOT STARTED",
  P: "PENDING",
  I: "IN PROGRESS",
  D: "DONE",
  C: "CANCELLED",
  H: "ON HOLD",
  U: "Un Assigned",
};
type Member = {
  id: string;
  roles: string;
  tenantId: string;
  userId: string;
  name: string;
  email: string;
  status: string;
};

const queryClient = new QueryClient();
const MainDashboard: React.FC = () => {
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const appTenantId = (accounts[0].idTokenClaims as IdTokenClaims).appTenantId;
  const [data, setData] = useState<any>();
  const [loaded, setLoaded] = useState(false);
  const [policiesLoaded, setPoliciesLoaded] = useState(false);
  const [attentionPoliciesData, setAttentionPoliciesData] = useState<PolicyActionEntity[]>([]);
  const [upcomingPoliciesData, setUpcomingPoliciesData] = useState<PolicyActionEntity[]>([]);
  const [users, setUsers] = useState<Member[]>([]);
  const [selectedRowId, setSelectedRowId] = useState("");
  const handleClose = () => {
    setSelectedRowId(""); // Reset selectedRowId when the EditAction component is closed
  };
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/` + appTenantId
        );
        setUsers((prevValue: Member[]) => [...prevValue, ...userResponse.data]);
      } catch (error) {}
    };

    fetchUsers();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("app tenant id: " + appTenantId);
        const scoresResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/org/r_scores/${appTenantId}`
        );
        setData(scoresResponse.data);
        setLoaded(true);
      } catch (error) {
        console.log(error);
        setLoaded(false);
      }
    };

    fetchData();
  }, []);

  const [totalActions, setTotalActions] = useState(0);
  const [actionsThisMonth, setActionsThisMonth] = useState(0);
  const [actionPieChart, setActionPieChart] = useState<any>([]);
  const [overDueActionPieChart, setOverDueActionPieChart] = useState<any>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalOverdueCount, setTotalOverdueCount] = useState(0);
  const [currMonth, setCurrMonth] = useState<string>(() => {
    const date = new Date();
    return `${date.toLocaleString("default", { month: "long" })} ${date.getFullYear()}`;
  });
  // Function to add givenName and surname to actions based on ownerId
  const getMonthNumber = (monthString: string): number => {
    const [monthName, year] = monthString.split(" "); // Split by space and get the month name and year
    const date = new Date(`${monthName} 1, ${year}`); // Dynamically construct the date with extracted year
    return date.getMonth(); // Returns the month number (0 for January, 1 for February, etc.)
  };
  const getYearNumber = (monthString: string): number => {
    const [monthName, year] = monthString.split(" "); // Split by space and get the month name and year
    const date = new Date(`${monthName} 1, ${year}`); // Dynamically construct the date with extracted year
    return date.getFullYear(); // Returns the month number (0 for January, 1 for February, etc.)
  };
  const [fetchedUpcomingActions, setFetchedUpcomingActions] = useState<PolicyActionEntity[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const policyActionsResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/actions/${appTenantId}`
        );
        let upcomingPolicyActions: PolicyActionEntity[] = [];
        let total = 0;
        let totalOverdueCount = 0; // Counter for overdue actions
        const overDueActions: {
          action?: string;
          id?: string;

          status: any;

          dueDate: string | number | Date;

          lastUpdateDate?: Date;
        }[] = [];
        policyActionsResponse.data
          .filter((action: PolicyActionEntity) => action.isActive)
          .forEach((action: PolicyActionEntity) => {
            if (action.dueDate < new Date() && action.status !== "D" && action.status !== "C") {
              // attentionPolicyActions.push(action);
              total++;
            }
            if (
              //action.status === "N" ||
              //action.assignedActioner === "" ||
              new Date(action.dueDate) <
                new Date(new Date().getFullYear(), new Date().getMonth() + 2, 1) &&
              action.status !== "D" &&
              action.status !== "C"
            ) {
              upcomingPolicyActions.push(action);
            }
            // Check for overdue actions
            const currentDate = new Date();
            console.log("CURRENT DATE", currentDate.toDateString());
            const dueDate = new Date(action.dueDate);
            if (
              action.dueDate != null &&
              dueDate.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)
            ) {
              totalOverdueCount++;
              overDueActions.push(action);
              console.log("Overdue action====", action);
            }
          });
        setTotalActions(policyActionsResponse.data.length);
        console.log("Upcoming actions:", upcomingPolicyActions.length);
        setFetchedUpcomingActions(upcomingPolicyActions);

        // overdue action pie chart data
        setTotalOverdueCount(totalOverdueCount);
        let InProg = 0;
        let onHld = 0;
        let noAssg = 0;
        let noStart = 0;
        overDueActions.forEach((action: { status: any; dueDate: string | number | Date }) => {
          switch (action.status) {
            case "I":
              InProg++;
              break;
            case "P":
              InProg++;
              break;
            case "H":
              onHld++;
              break;
            case "N":
              noStart++;
              break;
            case "U":
              noAssg++;
              break;
            default:
              break;
          }
        });

        if (totalOverdueCount == 0) {
          setOverDueActionPieChart([{ name: "no record", value: 1 }]);
        } else {
          setOverDueActionPieChart([
            { name: "On Hold", value: onHld },
            { name: "Not Assigned", value: noAssg },
            { name: "Not Started", value: noStart },
            { name: "In Progress", value: InProg },
          ]);
        }

        // action status pie chart
        const actionsThisMonthArray = policyActionsResponse.data.filter(
          (action: {
            dueDate: string | number | Date;
            lastUpdateDate: string | number | Date;
            isActive: boolean;
          }) => {
            //const currentDate = new Date();
            //if (action.isActive) {
            if (action.dueDate) {
              const dueDate = new Date(action.dueDate);
              return (
                dueDate.getMonth() === getMonthNumber(currMonth) &&
                dueDate.getFullYear() === getYearNumber(currMonth)
              );
            } else {
              // Check against lastUpdateDate if dueDate is null
              const lastUpdateDate = new Date(action.lastUpdateDate);
              return (
                lastUpdateDate.getMonth() === getMonthNumber(currMonth) &&
                lastUpdateDate.getFullYear() === getYearNumber(currMonth)
              );
            }
            //} else {
            //  return false;
            //}
          }
        );

        const countActionsThisMonth = actionsThisMonthArray.length;
        setActionsThisMonth(countActionsThisMonth);

        console.log("Number of actions due this month:", actionsThisMonthArray);
        let InProgress = 0;
        let doneCount = 0;
        let cancelledCount = 0;
        let onHold = 0;
        let noAssi = 0;
        let notstarted = 0;
        let pending = 0;
        let overdueCount = 0;

        const currentDate = new Date();
        actionsThisMonthArray.forEach(
          (action: { status: any; dueDate: string | number | Date }) => {
            const dueDate = new Date(action.dueDate);
            if (
              action.dueDate != null &&
              dueDate.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)
            ) {
              overdueCount++;
            } else {
              switch (action.status) {
                case "I":
                  InProgress++;
                  break;
                case "P":
                  noAssi++;
                  break;
                case "D":
                  doneCount++;
                  break;
                case "C":
                  cancelledCount++;
                  break;
                case "H":
                  onHold++;
                  break;
                case "N":
                  notstarted++;
                  break;
                case "U":
                  noAssi++;
                  break;
                default:
                  break;
              }
            }
          }
        );
        console.log(" Setting pie chart", actionsThisMonth);
        if (countActionsThisMonth == 0) {
          setActionPieChart([{ name: "no record", value: 1 }]);
        } else {
          setActionPieChart([
            { name: "Done", value: doneCount },
            { name: "In Progress", value: InProgress },
            { name: "On Hold", value: onHold },
            { name: "Overdue", value: overdueCount },
            { name: "Cancelled", value: cancelledCount },
            { name: "Not Assigned", value: noAssi },
            { name: "Not Started", value: notstarted },
            //{ name: "Pending", value: pending },
          ]);
        }
        /*         // Get the month name using toLocaleString
        var monthName = currentDate.toLocaleString("default", { month: "long" });

        // Get the year
        var year = currentDate.getFullYear();

        // Construct the desired format
        var currentMonthAndYear = monthName + " " + year;

        setCurrMonth(currentMonthAndYear); */
        setIsLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here if needed
      }
    };
    fetchData();
  }, [currMonth]);
  // console.log("upp====================", fetchedUpcomingActions);

  function enrichActionsWithUserDetails(users: any[], actions: any) {
    fetchedUpcomingActions.forEach((action) => {
      const user = users.find((user: { userId: string }) => user.userId === action.owner);
      if (user) {
        action.ownerGivenName = user.givenName;
        action.ownerSurname = user.surname;
      }
    });
  }

  useEffect(() => {
    if (users && fetchedUpcomingActions) {
      enrichActionsWithUserDetails(users, fetchedUpcomingActions);
    }
  }, [users, fetchedUpcomingActions]);

  useEffect(() => {
    console.log(
      "actionsThisMonth before load",
      actionsThisMonth,
      actionPieChart,
      totalOverdueCount,
      overDueActionPieChart
    );
  }, [actionPieChart, actionsThisMonth, overDueActionPieChart, totalOverdueCount]);
  // console.log("new action====================", fetchedUpcomingActions);

  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  // get  hardware data
  // Define a color palette
  const ACTIONCOLORS = [
    "#23AF00",
    "#49AEEE",
    "#B9B9B9",
    "#FF6666",
    "#555555",
    "#FF9900",
    "#C2CAFF",
    "#ACADB2",
  ];
  //const OVERDUECOLORS = ["#FF8F3E", "#FF0000", "#989898"];
  const OVERDUECOLORS = ["#B9B9B9", "#FF9900", "#C2CAFF", "#49AEEE"];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [monthOffset, setMonthOffset] = useState(0);

  const handlePrevMonth = () => {
    const currentMonth = new Date(currMonth);
    console.log("current month", currentMonth);
    currentMonth.setMonth(currentMonth.getMonth() - 1); // Increment by 1 month
    console.log("current month", currentMonth);
    setCurrMonth(
      `${currentMonth.toLocaleString("default", { month: "long" })} ${currentMonth.getFullYear()}`
    );
  };
  const navigateToMonth = () => {
    const currentMonth = new Date(currMonth);
    currentMonth.setMonth(currentMonth.getMonth() + monthOffset);
    setCurrMonth(
      `${currentMonth.toLocaleString("default", { month: "long" })} ${currentMonth.getFullYear()}`
    );
  };
  const handleNextMonth = () => {
    const currentMonth = new Date(currMonth);
    console.log("current month", currentMonth);
    currentMonth.setMonth(currentMonth.getMonth() + 1); // Increment by 1 month
    console.log("current month", currentMonth);
    setCurrMonth(
      `${currentMonth.toLocaleString("default", { month: "long" })} ${currentMonth.getFullYear()}`
    );
  };
  const options = { day: "numeric", month: "long", year: "numeric" };
  function formatDate(dateString: string | number | Date) {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-gb", options as Intl.DateTimeFormatOptions);
  }
  // console.log("hardware data: ", hardwareData);
  // console.log("r score: ", data);

  const Pdata = [
    { name: "on hold", value: 0 },
    { name: "in progress", value: 0 },
    { name: "no value", value: 1 },
  ];
  return (
    <Box className="main-box">
      {/* R_Score section */}
      <Box className="r-box">
        <Box className="r-title-box">
          <Typography fontSize="14px" fontWeight="600">
            Your R_Score
          </Typography>
          <Box className="common-box2">
            <LightTooltip
              title={
                <Box style={{ marginLeft: "10px" }}>
                  <Typography fontSize="14px" color="#66696C">
                    Explanation:
                  </Typography>
                  <Typography fontSize="14px" color="#000706" lineHeight="19.12px">
                    <ul className="custom-list">
                      <li>
                        <b> R_Score</b> is based on ACSC’s maturity methodology and range from 1
                        (limited/no cyber controls) to 5 (best-in-class cyber security)
                      </li>
                      {/* <br /> */}
                      <li>
                        <b>Target Score</b> of “3.0” correlates to cyber security principles being
                        adequately implemented on important systems
                      </li>
                      {/* <br /> */}

                      <li>
                        <b>ResoluteRM</b> considers target score “3.0” is a practical 12-month
                        target for most small to medium sized Organisations
                      </li>
                      {/* <br /> */}
                      <li>
                        <b> Detailed R_Scores</b>
                        is a view of each principle activity as categorised by the ACSC (ie. how an
                        Organisation sets up information security management to Govern, Protect,
                        Detect & Respond)
                      </li>
                    </ul>
                  </Typography>
                </Box>
              }
            >
              <Box className="common-box">
                <QuestionIcon />
                <Typography fontSize="14px" fontWeight="600" color="#66696C">
                  What is R_Score
                </Typography>
              </Box>
            </LightTooltip>
            <Box className="common-box">
              <Button className="common-box" onClick={toggleDetails}>
                {!showDetails ? <LeftArrow /> : <RightArrow />}
                <Typography fontSize="14px" fontWeight="600" color="#233DFF">
                  {!showDetails ? "Show Detailed R_Scores" : "Hide Detailed R_Scores"}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className="r-content-box">
          {!showDetails ? (
            <Card className="overall-score">
              <Typography fontSize="16px" fontWeight="600" color="#1E2429">
                Overall Score
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "space-around",
                  marginTop: "11px",
                }}
              >
                {/* initial */}
                <Box className="initial-target-score">
                  <Typography variant="h4" color="#FF8F3E" fontWeight="600">
                    {data?.initialOverallScore}
                  </Typography>
                  <Typography fontSize="12px" marginTop="5px" fontWeight="600">
                    Initial R_Score
                  </Typography>
                  <Typography fontSize="12px" color="#66696C">
                    {/* {data?.initialPrincipalRScoreDate} */}
                    {formatDate(data?.initialPrincipalRScoreDate)}
                  </Typography>
                </Box>
                <Box className="r-line">
                  <hr />
                </Box>
                {/* current */}
                <Box className="current-score">
                  <Typography fontSize="42px" color="#050A30" fontWeight="600">
                    {data?.currentOverallScore}
                  </Typography>
                  <Typography fontSize="12px" fontWeight="600">
                    Current R_Score
                  </Typography>
                  <Typography fontSize="12px" color="#66696C">
                    {formatDate(data?.lastUpdateDate)}
                  </Typography>
                </Box>
                <Box className="r-line">
                  <hr />
                </Box>
                {/* target */}
                <Box className="initial-target-score">
                  <Typography variant="h4" color="#233DFF" fontWeight="600">
                    {data?.projected12MonthOverallScore}
                  </Typography>
                  <Typography fontSize="12px" marginTop="5px" fontWeight="600">
                    Target R_Score
                  </Typography>
                  <Typography fontSize="12px" color="#66696C">
                    {formatDate(data?.targetPrincipalRScoreDate)}
                  </Typography>
                </Box>
              </Box>
            </Card>
          ) : (
            <Box className="common-box">
              {/* Govern */}
              <Card className="detail-other-box">
                <Typography fontSize="14px" fontWeight="600" color="#1E2429">
                  Govern R_Score
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    marginTop: "11px",
                  }}
                >
                  {/* initial */}
                  <Box className="initial-other-detail">
                    <Typography fontSize="16px" color="#FF8F3E" fontWeight="600">
                      {data?.initialScoreByPrinciple["G"]}
                    </Typography>
                    <Typography fontSize="11px" marginTop="5px" fontWeight="600" color="#66696C">
                      Initial
                    </Typography>
                  </Box>

                  {/* current */}
                  <Box className="current-other-detail">
                    <Typography fontSize="28px" color="#050A30" fontWeight="600">
                      {data?.currentScoreByPrinciple["G"]}
                    </Typography>
                    <Typography fontSize="11px" fontWeight="600" color="#66696C">
                      Current
                    </Typography>
                  </Box>

                  {/* target */}
                  <Box className="initial-other-detail">
                    <Typography fontSize="16px" color="#233DFF" fontWeight="600">
                      {data?.projected12MonthScoreByPrinciple["G"]}
                    </Typography>
                    <Typography fontSize="11px" marginTop="5px" fontWeight="600" color="#66696C">
                      Target
                    </Typography>
                  </Box>
                </Box>
              </Card>
              <Box className="r-line-detail">
                <hr />
              </Box>
              {/* protect */}
              <Card className="detail-other-box">
                <Typography fontSize="14px" fontWeight="600" color="#1E2429">
                  Protect R_Score
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    marginTop: "11px",
                  }}
                >
                  {/* initial */}
                  <Box className="initial-other-detail">
                    <Typography fontSize="16px" color="#FF8F3E" fontWeight="600">
                      {data?.initialScoreByPrinciple["P"]}
                    </Typography>
                    <Typography fontSize="11px" marginTop="5px" fontWeight="600" color="#66696C">
                      Initial
                    </Typography>
                  </Box>

                  {/* current */}
                  <Box className="current-other-detail">
                    <Typography fontSize="28px" color="#050A30" fontWeight="600">
                      {data?.currentScoreByPrinciple["P"]}
                    </Typography>
                    <Typography fontSize="11px" fontWeight="600" color="#66696C">
                      Current
                    </Typography>
                  </Box>

                  {/* target */}
                  <Box className="initial-other-detail">
                    <Typography fontSize="16px" color="#233DFF" fontWeight="600">
                      {data?.projected12MonthScoreByPrinciple["P"]}
                    </Typography>
                    <Typography fontSize="11px" marginTop="5px" fontWeight="600" color="#66696C">
                      Target
                    </Typography>
                  </Box>
                </Box>
              </Card>
              <Box className="r-line-detail">
                <hr />
              </Box>
              {/* overall */}
              <Card className="overall-score-detail">
                <Typography fontSize="16px" fontWeight="600" color="#1E2429">
                  Overall R_Score
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    marginTop: "11px",
                  }}
                >
                  {/* initial */}
                  <Box className="initial-target-score-detail">
                    <Typography variant="h4" color="#FF8F3E" fontWeight="600">
                      {data?.initialOverallScore}
                    </Typography>
                    <Typography fontSize="11px" marginTop="5px" fontWeight="600">
                      Initial R_Score
                    </Typography>
                    <Typography fontSize="11px" color="#66696C">
                      {formatDate(data?.initialPrincipalRScoreDate)}
                    </Typography>
                  </Box>

                  {/* current */}
                  <Box className="current-score-detail">
                    <Typography fontSize="42px" color="#050A30" fontWeight="600">
                      {data?.currentOverallScore}
                    </Typography>
                    <Typography fontSize="11px" fontWeight="600">
                      Current R_Score
                    </Typography>
                    <Typography fontSize="11px" color="#66696C">
                      {formatDate(data?.lastUpdateDate)}
                    </Typography>
                  </Box>

                  {/* target */}
                  <Box className="initial-target-score-detail">
                    <Typography variant="h4" color="#233DFF" fontWeight="600">
                      {data?.projected12MonthOverallScore}
                    </Typography>
                    <Typography fontSize="11px" marginTop="5px" fontWeight="600">
                      Target R_Score
                    </Typography>
                    <Typography fontSize="11px" color="#66696C">
                      {formatDate(data?.targetPrincipalRScoreDate)}
                    </Typography>
                  </Box>
                </Box>
              </Card>
              <Box className="r-line-detail">
                <hr />
              </Box>
              {/* Detect */}
              <Card className="detail-other-box">
                <Typography fontSize="14px" fontWeight="600" color="#1E2429">
                  Detect R_Score
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    marginTop: "11px",
                  }}
                >
                  {/* initial */}
                  <Box className="initial-other-detail">
                    <Typography fontSize="16px" color="#FF8F3E" fontWeight="600">
                      {data?.initialScoreByPrinciple["D"]}
                    </Typography>
                    <Typography fontSize="11px" marginTop="5px" fontWeight="600" color="#66696C">
                      Initial
                    </Typography>
                  </Box>

                  {/* current */}
                  <Box className="current-other-detail">
                    <Typography fontSize="28px" color="#050A30" fontWeight="600">
                      {data?.currentScoreByPrinciple["D"]}
                    </Typography>
                    <Typography fontSize="11px" fontWeight="600" color="#66696C">
                      Current
                    </Typography>
                  </Box>

                  {/* target */}
                  <Box className="initial-other-detail">
                    <Typography fontSize="16px" color="#233DFF" fontWeight="600">
                      {data?.projected12MonthScoreByPrinciple["D"]}
                    </Typography>
                    <Typography fontSize="11px" marginTop="5px" fontWeight="600" color="#66696C">
                      Target
                    </Typography>
                  </Box>
                </Box>
              </Card>
              <Box className="r-line-detail">
                <hr />
              </Box>
              {/* Respond */}
              <Card className="detail-other-box">
                <Typography fontSize="14px" fontWeight="600" color="#1E2429">
                  Respond R_Score
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    marginTop: "11px",
                  }}
                >
                  {/* initial */}
                  <Box className="initial-other-detail">
                    <Typography fontSize="16px" color="#FF8F3E" fontWeight="600">
                      {data?.initialScoreByPrinciple["R"]}
                    </Typography>
                    <Typography fontSize="11px" marginTop="5px" fontWeight="600" color="#66696C">
                      Initial
                    </Typography>
                  </Box>

                  {/* current */}
                  <Box className="current-other-detail">
                    <Typography fontSize="28px" color="#050A30" fontWeight="600">
                      {data?.currentScoreByPrinciple["R"]}
                    </Typography>
                    <Typography fontSize="11px" fontWeight="600" color="#66696C">
                      Current
                    </Typography>
                  </Box>

                  {/* target */}
                  <Box className="initial-other-detail">
                    <Typography fontSize="16px" color="#233DFF" fontWeight="600">
                      {data?.projected12MonthScoreByPrinciple["R"]}
                    </Typography>
                    <Typography fontSize="11px" marginTop="5px" fontWeight="600" color="#66696C">
                      Target
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
          )}
        </Box>
      </Box>
      {isLoaded ? (
        <Box className="common-box">
          {/* Action Status Section */}
          <Box className="action-box">
            <Box className="r-title-box">
              <Typography fontSize="14px" fontWeight="600">
                Action Status
              </Typography>
              <Box className="common-box">
                <IconButton
                  onClick={() => {
                    handlePrevMonth();
                  }}
                >
                  <ChevronLeft />
                </IconButton>
                <Calendar />
                <Typography fontSize="14px" color="#66696C">
                  {" "}
                  {currMonth}{" "}
                </Typography>
                <IconButton
                  onClick={() => {
                    handleNextMonth();
                  }}
                >
                  <ChevronRight />
                </IconButton>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "10px",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
                // height: "100%",
              }}
            >
              {actionsThisMonth === 0 ? (
                <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <PieChart width={260} height={260}>
                    <Pie
                      // data={data02}
                      data={actionPieChart}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={75}
                      outerRadius={80}
                      fill="#82ca9d"
                      paddingAngle={0}
                    >
                      {actionPieChart.map((item: any, index: any) => {
                        // console.log(`Index: ${index}, Item:`, item);
                        return (
                          <Cell
                            key={`cell-${index}`}
                            fill={ACTIONCOLORS[index % ACTIONCOLORS.length]}
                          />
                        );
                      })}
                      <Label
                        value={actionsThisMonth}
                        position="center"
                        content={({}) => {
                          return (
                            // <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
                            //   <Typography fontSize="32px" fontWeight="500">
                            //     {total}
                            //   </Typography>
                            // </text>
                            <text x="40%" y="50%" textAnchor="middle" dominantBaseline="middle">
                              <tspan
                                x="50%"
                                dy="-0.5em"
                                style={{ fontSize: "32px", fontWeight: "500" }}
                              >
                                0
                              </tspan>
                              <tspan
                                x="50%"
                                dy="1.6em"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  fill: "#66696C",
                                  marginTop: "5px",
                                }}
                              >
                                Total Actions
                              </tspan>
                            </text>
                          );
                        }}
                      />
                    </Pie>

                    {/* <Legend
  wrapperStyle={{ fontSize: "12px", marginTop: "15px", color: "#000000" }}
  formatter={(value, entry, index) => (
    <span style={{ color: "#66696C" }}>{value}</span>
  )}
/> */}
                  </PieChart>
                </Box>
              ) : (
                <>
                  <Box>
                    <PieChart width={260} height={260}>
                      <Pie
                        // data={data02}
                        data={actionPieChart}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={75}
                        outerRadius={80}
                        fill="#82ca9d"
                        paddingAngle={0}
                      >
                        {actionPieChart.map((item: any, index: any) => {
                          // console.log(`Index: ${index}, Item:`, item);
                          return (
                            <Cell
                              key={`cell-${index}`}
                              fill={ACTIONCOLORS[index % ACTIONCOLORS.length]}
                            />
                          );
                        })}
                        <Label
                          value={actionsThisMonth}
                          position="center"
                          content={({}) => {
                            return (
                              // <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
                              //   <Typography fontSize="32px" fontWeight="500">
                              //     {total}
                              //   </Typography>
                              // </text>
                              <text x="40%" y="50%" textAnchor="middle" dominantBaseline="middle">
                                <tspan
                                  x="50%"
                                  dy="-0.5em"
                                  style={{ fontSize: "32px", fontWeight: "500" }}
                                >
                                  {actionsThisMonth}
                                </tspan>
                                <tspan
                                  x="50%"
                                  dy="1.6em"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    fill: "#66696C",
                                    marginTop: "5px",
                                  }}
                                >
                                  Total Actions
                                </tspan>
                              </text>
                            );
                          }}
                        />
                      </Pie>

                      {/* <Legend
  wrapperStyle={{ fontSize: "12px", marginTop: "15px", color: "#000000" }}
  formatter={(value, entry, index) => (
    <span style={{ color: "#66696C" }}>{value}</span>
  )}
/> */}
                    </PieChart>
                  </Box>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={2}>
                      <Box className="legend-box">
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "#23AF00",
                            marginRight: "7px",
                          }}
                        ></div>
                        <Typography fontSize="12px" color="#66696C">
                          Done
                        </Typography>
                      </Box>
                      <Box className="legend-box">
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "#49AEEE",
                            marginRight: "7px",
                          }}
                        ></div>
                        <Typography fontSize="12px" color="#66696C">
                          In Progress
                        </Typography>
                      </Box>
                      <Box className="legend-box">
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "#B9B9B9",
                            marginRight: "7px",
                          }}
                        ></div>
                        <Typography fontSize="12px" color="#66696C">
                          On Hold
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Box className="legend-box">
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "#FF6666",
                            marginRight: "7px",
                          }}
                        ></div>
                        <Typography fontSize="12px" color="#66696C">
                          Overdue
                        </Typography>
                      </Box>
                      <Box className="legend-box">
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "#555555",
                            marginRight: "7px",
                          }}
                        ></div>
                        <Typography fontSize="12px" color="#66696C">
                          Cancelled
                        </Typography>
                      </Box>
                      <Box className="legend-box">
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "#FF9900",
                            marginRight: "7px",
                          }}
                        ></div>
                        <Typography fontSize="12px" color="#FF9900">
                          Not Assigned
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Box className="legend-box">
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "#C2CAFF",
                            marginRight: "7px",
                          }}
                        ></div>
                        <Typography fontSize="12px" color="#C2CAFF">
                          Not Started
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </>
              )}
            </Box>
          </Box>

          {/* Upcoming Action Section */}
          <Box className="up-action-box">
            <Box className="r-title-box">
              <Typography fontSize="14px" fontWeight="600">
                Upcoming Actions
              </Typography>
              <Link to="/WorkflowManager">
                <Expand />
              </Link>
              {/* <Expand component={Link} to="/DetailWorkflowManager" /> */}
            </Box>
            <Box style={{ height: "344px", overflowY: "hidden" }}>
              <Box
                style={{ overflowY: "auto", height: "100%", width: "100%", scrollbarWidth: "none" }}
              >
                {fetchedUpcomingActions && fetchedUpcomingActions.length > 0 ? (
                  fetchedUpcomingActions
                    .slice() // Create a shallow copy to avoid mutating the original array
                    .sort((a, b) => {
                      // Convert dueDate strings to Date objects, handling null values
                      const dateA = a.dueDate ? new Date(a.dueDate) : null;
                      const dateB = b.dueDate ? new Date(b.dueDate) : null;

                      // Handle null values
                      if (dateA === null && dateB === null) {
                        return 0;
                      } else if (dateA === null) {
                        return 1; // Put null values at the end
                      } else if (dateB === null) {
                        return -1; // Put null values at the end
                      }

                      // Compare dates
                      return dateA.getTime() - dateB.getTime();
                    })
                    .map((item, index) => (
                      <Box
                        key={index}
                        style={{ padding: "10px", borderBottom: "1px solid #EDEDED" }}
                      >
                        <Typography fontSize="12px" color="#66696C" marginBottom="10px">
                          {formatDate(item?.dueDate)}
                        </Typography>
                        <Typography fontSize="14px" color="#1E2429" marginBottom="10px">
                          {item.title}
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                          >
                            <Account />
                            <Typography fontSize="14px" color="#66696C" marginLeft="5px">
                              {item.ownerGivenName} {item.ownerSurname}
                            </Typography>
                          </Box>
                          <Box
                            style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                            onClick={() => setSelectedRowId(item.id)}
                          >
                            <Tab />
                            <Typography
                              fontSize="14px"
                              color="#233DFF"
                              fontWeight="600"
                              marginLeft="5px"
                            >
                              View Details
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))
                ) : (
                  <Box style={{ padding: "12px", textAlign: "center", marginTop: "35%" }}>
                    No Upcoming Actions
                  </Box>
                )}
                {/* Conditional rendering of EditAction component */}
                {selectedRowId !== "" && (
                  <ViewAction
                    actionId={selectedRowId}
                    isSystemAction={true}
                    onClose={handleClose}
                  />
                )}
              </Box>
            </Box>
          </Box>

          {/* overdue Status Section */}
          <Box className="action-box">
            <Box className="r-title-box">
              <Typography fontSize="14px" fontWeight="600">
                Overdue Actions
              </Typography>
              <Link to="/WorkflowManager">
                <Expand />
              </Link>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "10px",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                // height: "100%",
              }}
            >
              <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <PieChart width={260} height={260}>
                  <Pie
                    data={overDueActionPieChart}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={75}
                    outerRadius={80}
                    fill="#82ca9d"
                    paddingAngle={0}
                  >
                    {overDueActionPieChart.map((item: any, index: any) => {
                      // console.log(`Index: ${index}, Item:`, item);
                      return (
                        <Cell
                          key={`cell-${index}`}
                          fill={OVERDUECOLORS[index % OVERDUECOLORS.length]}
                        />
                      );
                    })}
                    <Label
                      value={totalOverdueCount}
                      position="center"
                      content={({}) => {
                        return (
                          // <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
                          //   <Typography fontSize="32px" fontWeight="500">
                          //     {total}
                          //   </Typography>
                          // </text>
                          <text x="40%" y="50%" textAnchor="middle" dominantBaseline="middle">
                            <tspan
                              x="50%"
                              dy="-0.5em"
                              style={{ fontSize: "32px", fontWeight: "500" }}
                            >
                              {totalOverdueCount}
                            </tspan>
                            <tspan
                              x="50%"
                              dy="1.6em"
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                fill: "#66696C",
                                marginTop: "5px",
                              }}
                            >
                              Total OverDue
                            </tspan>
                          </text>
                        );
                      }}
                    />
                  </Pie>

                  {/* <Legend
      wrapperStyle={{ fontSize: "12px", marginTop: "15px", color: "#000000" }}
      formatter={(value, entry, index) => (
        <span style={{ color: "#66696C" }}>{value}</span>
      )}
    /> */}
                </PieChart>
              </Box>
              {totalOverdueCount > 0 ? (
                <Stack direction="row" spacing={3} style={{ marginTop: "20px" }}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: "#B9B9B9",
                        marginRight: "7px",
                      }}
                    ></div>
                    <Typography fontSize="12px" color="#B9B9B9">
                      On Hold
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: "#FF9900",
                        marginRight: "7px",
                      }}
                    ></div>
                    <Typography fontSize="12px" color="#FF9900">
                      Not Assigned
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: "#C2CAFF",
                        marginRight: "7px",
                      }}
                    ></div>
                    <Typography fontSize="12px" color="#C2CAFF">
                      Not Started
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: "#49AEEE",
                        marginRight: "7px",
                      }}
                    ></div>
                    <Typography fontSize="12px" color="#49AEEE">
                      In Progress
                    </Typography>
                  </Box>
                </Stack>
              ) : (
                <Stack></Stack>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box style={{ textAlign: "center", marginTop: "35%" }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
const MainDashboardWithProviders = () => (
  //Put this with your other react-query providers near root of your app
  <QueryClientProvider client={queryClient}>
    <MainDashboard />
  </QueryClientProvider>
);
export default MainDashboardWithProviders;
