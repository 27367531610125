import { useEffect, useState } from "react";
import { Docs } from "../../docs";
import { useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import { b2cPolicies, loginRequest } from "../../authConfig";
import useFetch from "../../helpers/hooks/useFetch";
import axios from "axios";
import React from "react";
import {
  Button,
  Box,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Alert,
  AppBar,
  IconButton,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { styled } from "@mui/material/styles";
import "../Organisation/organisation.css";
import userImage from "../../assets/user.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Tabs, Tab, Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import { set } from "lodash";
import { on } from "events";

//example data type
type Member = {
  id: string;
  roles: string;
  tenantId: string;
  userId: string;
  givenName: string;
  surname: string;
  email: string;
  status: string;
};
type RMOrganisation = {
  id: string;

  tenantId: string;

  name: string;

  abnacn: string;

  logoBase64: ArrayBuffer | string | null | undefined;

  address: string;

  mainContactName: string;

  mainContactMobile: string;

  sendReminderEmailToActionOwner: boolean;

  emailNoDays: string;

  emailCCManager: boolean;

  sendReminderSMSToActionOwner: boolean;

  smsNoDays: string;

  smsCCManager: boolean;

  lastUpdateDate: Date;
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const OrganisationManagement: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  console.log("accounts", accounts);
  const appTenantId = (accounts[0].idTokenClaims as IdTokenClaims).appTenantId;
  const allTenants = (accounts[0].idTokenClaims as IdTokenClaims).allTenants;
  const selectedTenant = (accounts[0].idTokenClaims as IdTokenClaims).appTenantName.toUpperCase();
  console.log("allTenants", allTenants);
  const [data, setData] = useState<RMOrganisation>({} as RMOrganisation);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = React.useState<Member[]>([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const initialSelectedTab =
    allTenants.findIndex((tenant: any) => tenant.toUpperCase() === selectedTenant) || 0;
  const [selectedTab, setSelectedTab] = useState<number>(initialSelectedTab);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const handleTabChange = (event: any, newValue: React.SetStateAction<number>) => {
    setSelectedTab(newValue);
  };

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */

  const [image, setImage] = useState<string | null>(null);

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = event.target.files?.[0];
    const fileInput = event.target;
    if (file.size > 51200) {
      alert("File is too big!. Please upload less than 50KB file.");
      fileInput.value = "";
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setImage(base64String);
        setData((prevData) => ({ ...prevData, logoBase64: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const fetchOrg = async () => {
      // Update the query parameter based on the current step
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/rm/organisation/${appTenantId}`
      );
      setData(response.data);
      console.log(data.mainContactName);
      const userResponse = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/` + appTenantId
      );
      console.log("list of tenant", userResponse.data);

      setUsers((prevValue: Member[]) => [...prevValue, ...userResponse.data]);
      setIsLoading(false);
    };
    // Call the function to fetch the image whenever the step changes
    fetchOrg();
  }, []);

  const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    // Perform validation and update the state accordingly
    setData({ ...data, [field]: event.target.value });

    setErrorMessage("");
  };
  const handleSave = async () => {
    if (!data.name || !data.abnacn || !data.mainContactName || !data.mainContactMobile) {
      setErrorMessage("Please fill in all required fields");
      setShowError(true);
      return;
    }
    if (data.abnacn.length !== 9 && data.abnacn.length !== 11) {
      setErrorMessage("Please enter a valid ABN/ACN");
      setShowError(true);
      return;
    }
    if (data.mainContactMobile.length !== 10) {
      setErrorMessage("Please enter a valid mobile number");
      setShowError(true);
      return;
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_SERVER}/rm/organisation/saveOrganisation`,
        data
      );
      //setShowSuccess(true);
      //setErrorMessage("");
      //setShowError(false);
      onClose();
    } catch (error) {
      setShowError(true);
    }
  };
  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    const fileInput = event.target;
    if (file.size > 51200) {
      alert("File is too big!");
      fileInput.value = "";
      return;
    }

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // The result property contains the base64 encoded strings
        console.log("reader.result", reader.result);
        setData((prevData) => ({ ...prevData, logoBase64: reader.result }));
      };

      // Read the file as a Data URL (base64 encoding)
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      {showSuccess && (
        <Alert severity="success" onClose={() => setShowSuccess(false)}>
          Organisation details saved successfully
        </Alert>
      )}
      {showError && (
        <Alert severity="error">Error saving organisation details - {errorMessage}</Alert>
      )}
      <Box style={{ padding: "10px" }}>
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}
        >
          <Typography fontSize="24px" fontWeight="bold">
            Organisation Settings
          </Typography>
          <div>
            <Box style={{ textAlign: "center", marginTop: "20px", padding: "2px" }}>
              <Button component={Link} to="/get-started" variant="contained" onClick={onClose}>
                Add Organisation
              </Button>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
          </div>
        </div>
        {!isLoading && (
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            {/* Left Side */}
            <Box style={{ width: "240px", borderRight: "1px solid #D3D3D3" }}>
              <Tabs
                orientation="vertical"
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="Vertical tabs"
                style={{ float: "left" }}
              >
                {allTenants.map(
                  (
                    tenant:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined,
                    index: React.Key | null | undefined
                  ) => (
                    <Tab
                      key={index}
                      label={
                        <div
                          style={{
                            display: "flex",
                            color: "blue",
                            fontWeight: "bold",
                            textAlign: "left", // Align text to the left
                            justifyContent: "stretch",
                          }}
                        >
                          <span>{tenant}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <ArrowForwardIosIcon />
                        </div>
                      }
                      sx={{
                        "&.Mui-selected": {
                          color: "blue",
                          backgroundColor: "#D4DAFF",
                        },
                        pointerEvents: selectedTab === index ? "auto" : "none", // Disable pointer events for unselected tabs
                      }}
                      disabled={selectedTab !== index} // Disable unselected tabs
                    />
                  )
                )}
              </Tabs>
            </Box>

            {/* Right Side */}
            <Box style={{ width: "calc(100% - 240px)", paddingLeft: "20px" }}>
              {/* Top section */}
              <Box style={{ textAlign: "center", marginBottom: "20px" }}>
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  <div style={{ position: "relative", display: "inline-block" }}>
                    <label htmlFor="upload-input">
                      <div
                        style={{
                          width: 100,
                          height: 100,
                          overflow: "hidden",
                        }}
                      >
                        {image ? (
                          <img src={image} alt="Uploaded" style={{ maxWidth: "100%" }} />
                        ) : (
                          <>
                            {data.logoBase64 ? (
                              <img
                                src={data.logoBase64 as string}
                                alt="Uploaded Logo"
                                style={{ maxWidth: "100%" }}
                              />
                            ) : (
                              <img
                                src={userImage}
                                alt="Default User"
                                style={{ maxWidth: "100%" }}
                              />
                            )}
                          </>
                        )}
                      </div>
                      <input
                        id="upload-input"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleUpload}
                      />
                    </label>
                    <div
                      style={{
                        bottom: "-40px",
                        left: "50%",
                        transform: "translateX(-7%)",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer", // Set cursor to pointer to indicate clickability
                      }}
                      onClick={() => document.getElementById("upload-input")?.click()} // Trigger file input click when text is clicked
                    >
                      <FileUploadOutlinedIcon style={{ fontSize: 20, marginRight: 5 }} />
                      <span>Upload New</span>
                    </div>
                  </div>
                </div>
              </Box>

              {/* Organisation details */}
              <div style={{ display: "flex", flexDirection: "column", margin: "5px" }}>
                <Typography style={{ marginRight: "10px", minWidth: "250px" }}>
                  Unique Organisation name:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Organisation Name"
                  name="name"
                  value={data.name}
                  onChange={handleChange("name")}
                />

                <Typography style={{ marginRight: "10px", minWidth: "250px", marginTop: "10px" }}>
                  Organisation ABN/ACN:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="ABN/ACN"
                  name="abnacn"
                  value={data.abnacn}
                  onChange={handleChange("abnacn")}
                  inputProps={{
                    maxLength: 11, // Limit the input length to 11 characters (maximum for ACN)
                    pattern: "\\d*", // Allow only numeric input
                    title: "Please enter either 9 or 11 digits", // Tooltip message for invalid input
                  }}
                />

                <Typography style={{ marginRight: "10px", minWidth: "250px", marginTop: "10px" }}>
                  Main Contact:
                </Typography>
                <TextField
                  style={{ marginRight: "10px", minWidth: "400px" }}
                  fullWidth
                  select
                  required
                  variant="outlined"
                  placeholder="Contact Name"
                  name="mainContactName"
                  value={data.mainContactName}
                  onChange={handleChange("mainContactName")}
                >
                  {users
                    .filter((user) => user.status === "Active") // Filter users where status is Active
                    .map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {`${user.givenName} ${user.surname}`}
                      </MenuItem>
                    ))}
                </TextField>
                <Typography style={{ marginRight: "10px", minWidth: "250px", marginTop: "10px" }}>
                  Main Contact Mobile:
                </Typography>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  placeholder="Contact Mobile"
                  name="mainContactMobile"
                  value={data.mainContactMobile}
                  onChange={handleChange("mainContactMobile")}
                  inputProps={{
                    maxLength: 10, // Limit the input length to 10 characters
                    pattern: "\\d*", // Allow only numeric input
                    title: "Please enter exactly 10 digits", // Tooltip message for invalid input
                  }}
                />

                {/* <Typography style={{ marginRight: "10px", minWidth: "250px", marginTop: "10px" }}>
                Organisation Address:
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={2}
                required
                variant="outlined"
                placeholder="Address"
                name="address"
                value={data.address}
                onChange={handleChange("address")}
              /> */}

                {/* <Typography style={{ marginRight: "10px", minWidth: "250px", marginTop: "10px" }}>
                Logo:
              </Typography>
              <Button component="label" role={undefined} variant="contained" tabIndex={-1}>
                <input type="file" onChange={handleFileInputChange} />
              </Button>
              {data.logoBase64 && (
                <img
                  src={data.logoBase64 as string}
                  alt="Uploaded Logo"
                  style={{ maxWidth: "100%", marginTop: "10px" }}
                />
              )} */}
              </div>

              {/* Save button */}
              <Box style={{ textAlign: "center", marginTop: "20px" }}>
                <Button variant="contained" onClick={handleSave}>
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
export default OrganisationManagement;
