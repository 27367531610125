import React, { useState, useEffect, useRef } from "react";

import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";

import { Tenant } from "./tenant";
import { Docs } from "./docs";
import { Navbar, Dropdown, DropdownButton, Nav } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link, Routes, BrowserRouter } from "react-router-dom";
import GetStarted from "./pages/GetStarted";
import { loginRequest, b2cPolicies, deployment } from "./authConfig";
import theme from "./helpers/theme/theme";
import "./styles/App.css";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Modal,
  Select,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import UserManagementWithProviders, { UserManagement } from "./pages/UserManagement";
import DetailledDashboard from "./pages/Dashboard/DetailledDashboard";
import WorkflowManager from "./pages/WorkflowManager";
import DetailWorkflowManager from "./pages/WorkflowManager/DetailWorkflowManager";
import PolicyBuilder from "./pages/PolicyBuilder";
import PolicyManager from "./pages/PolicyManager";
import { NavigationBar } from "./ui";
import { findSigninAccount } from "./helpers/hooks/useAccountFinder";
import { GlobalStateProvider } from "./GlobalStateContext";
import { useGlobalState } from "./GlobalStateContext";
import OrganisationManagement from "./pages/Organisation/OrganisationManagement";
import SystemSettings from "./pages/Organisation/SystemSettings";
import AssetManager from "./pages/AssetManager";
import { SignUpSignIn } from "./pages/Login/SignUpSignIn";
import { LoggedIn } from "./pages/Login/LoggedIn";
import { NewTenantSignUp } from "./pages/Login/NewTenantSignUp";
import { ReactComponent as Profile } from "./assets/Ellipse.svg";
import { ReactComponent as DownArrow } from "./assets/keyboard_arrow_down.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Invitation from "./pages/Login/Invitation";
import { InvitationLoggedIn } from "./pages/Login/InvitationLoggedIn";
import LogoutTimer from "../src/helpers/LogoutTimer";
import JwtInterceptor from "./helpers/jwt.interceptor";
/* import ProfileSettings from "./pages/Organisation/ProfileSettings"; */
import { env } from "process";
import { Logout } from "./pages/Login/Logout";
import OrgListProvider from "./pages/Admin/OrgList";
// export const PageLayout = (props: { children: any }) => {
//   return (
//     <>
//       <Navbar
//         style={{
//           backgroundColor: "#000706",
//           display: "flex",
//           justifyContent: "space-between",
//           paddingLeft: "45px",
//           paddingRight: "45px",
//           height: "60px",
//         }}
//       >
//         <a style={{ color: "white" }} href="/">
//           <div className="logo" />
//         </a>
//         <div>
//           <NavigationBar />
//         </div>
//       </Navbar>

//       {props.children}
//     </>
//   );
// };
type IdTokenClaims = {
  roles: string[];
  idp: string;
  email: string;
  signInName: string;
  appTenantName: string;
  sub: string;
  appTenantId: string;
  questionId: string;
  acr: any;
  allTenants: any[];
};
const useStyles = makeStyles((theme: { spacing: (arg0: number) => any }) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(20),
    borderBottom: "1px solid transparent",
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
  select: {
    "&:before": {
      borderColor: "red",
    },
    "&:after": {
      borderColor: "red",
    },
  },
  icon: {
    fill: "red",
  },
}));
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  minHeight: "300px",
};

export const PageLayout = (props: { children: any }) => {
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const account = instance.getActiveAccount();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const appTenantId = (accounts[0]?.idTokenClaims as IdTokenClaims)?.appTenantId;
  const tenantList = (accounts[0]?.idTokenClaims as IdTokenClaims)?.allTenants;

  const location = useLocation();
  const routeName = location.pathname.substring(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClickOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const [isSystemDialogOpen, setIsSystemDialogOpen] = useState(false);

  const handleClickOpenSystemDialog = () => {
    setIsSystemDialogOpen(true);
  };

  const handleCloseSystemDialog = () => {
    setIsSystemDialogOpen(false);
  };
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);

  const handleClickOpenProfileDialog = () => {
    setIsProfileDialogOpen(true);
  };

  const handleCloseProfileDialog = () => {
    setIsProfileDialogOpen(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // get users
  const [users, setUsers] = useState<Member[]>([]);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/` + appTenantId
        );
        setUsers((prevValue: Member[]) => [...prevValue, ...userResponse.data]);
      } catch (error) {}
    };

    fetchUsers();
  }, [appTenantId]);

  const [userName, setUserName] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const tenantName = (accounts[0]?.idTokenClaims as IdTokenClaims)?.appTenantName;
  const isAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes("Tenant.admin");
  const isGlobalAdminTabRequired = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes(
    "GlobalAdmin"
  );
  const isGlobalAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles[0] === "GlobalAdmin";
  const [loadingIframe, setLoadingIframe] = useState(true);

  useEffect(() => {
    function getUserName() {
      const matchingUser = users.find((user) => user.userId === accounts[0].localAccountId);
      if (matchingUser) {
        // return {
        //   givenName: matchingUser.givenName,
        //   surname: matchingUser.surname,
        // };
        setUserName(matchingUser.givenName + " " + matchingUser.surname);
        setRole(matchingUser.roles);
        // console.log("matching user=========", matchingUser);
      }
    }

    // accounts.forEach((account) => {
    //   const userName = getUserName();
    //   if (userName) {
    //     console.log(`Given Name: ${userName.givenName}, Surname: ${userName.surname}`);
    //   } else {
    //     console.log("No matching user found for account:", account.localAccountId);
    //   }
    // });
    getUserName();
  }, [users, accounts]);

  useEffect(() => {
    const handler = (ev: any) => {
      console.log("ev", ev);
      if (ev.data === "SignIn") {
        console.log("Got Sign In event");
        window.top?.location.assign(`${process.env.REACT_APP_BASE_URL}` + "/");
      }

      //setMessage(ev.data.message);
    };

    window.addEventListener("message", handler, false);

    // Don't forget to remove addEventListener
    return () => window.removeEventListener("message", handler);
  }, []);

  return (
    <>
      <UnauthenticatedTemplate>
        {/* <Navbar
          // style={{
          //   backgroundColor: "#000706",
          //   display: "flex",
          //   justifyContent: "space-between",
          //   paddingLeft: "45px",
          //   paddingRight: "45px",
          //   // height: "60px",
          // }}
          // expand="lg"
          className="navbar"
        >
          <a style={{ color: "white" }} href="/">
            <div className="logo" />
          </a>
          <div>
            <NavigationBar />
          </div>
        </Navbar>

      */}
        <NavigationBar />
        {props.children}
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ position: "fixed" }}>
            <NavigationBar />
          </div>

          <div style={{ width: "100%", paddingLeft: "80px" }}>
            <div
              style={{
                boxShadow: " 0px 4px 16px 0px #00000014",
                height: "60px",
                width: "100%",
                // backgroundColor: "pink",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: "15px",
                paddingLeft: "30px",
                // marginBottom: "70px",
              }}
            >
              <SwitchTenant />

              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Profile />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  <Typography fontSize="14px" color="#000706">
                    {userName}
                  </Typography>
                  <Typography fontSize="12px" color="#66696C">
                    {role[0] === "Tenant.admin"
                      ? "Admin"
                      : role[0] === "Tenant.manager"
                      ? "Manager"
                      : role[0] === "Tenant.member"
                      ? "Member"
                      : "Global"}
                  </Typography>
                </Box>
                <DownArrow
                  onClick={handleClick}
                  style={{
                    cursor: "pointer", // Change cursor to hand on hover
                  }}
                />

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {(isAdmin || isGlobalAdmin) && (
                    <>
                      <MenuItem onClick={handleClickOpenSystemDialog}>System Settings</MenuItem>
                      {/* <MenuItem onClick={handleClickOpenProfileDialog}>Profile Settings</MenuItem> */}
                      <MenuItem onClick={handleClickOpenDialog}>Organisation Settings</MenuItem>
                    </>
                  )}
                  <MenuItem
                    onClick={() => {
                      sessionStorage.clear();
                      instance.logoutRedirect({
                        postLogoutRedirectUri: `${process.env.REACT_APP_MAIN_WEBSITE_URL}` + "/",
                      });
                    }}
                  >
                    Log Out
                  </MenuItem>
                </Menu>
              </div>
              {/* <Button
                // variant="warning"
                variant="outlined"
                sx={{ color: "black" }}
                className="ml-auto"
                onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}
              >
                <Typography variant="h6" noWrap component="div">
                  Sign out
                </Typography>
              </Button> */}
            </div>
            {props.children}
          </div>
        </div>
      </AuthenticatedTemplate>
      <Dialog
        open={isSystemDialogOpen}
        onClose={handleCloseSystemDialog}
        maxWidth="lg"
        PaperProps={{ style: { maxHeight: "md" } }}
      >
        <SystemSettings onClose={handleCloseSystemDialog} />
      </Dialog>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="lg"
        PaperProps={{ style: { maxHeight: "md" } }}
      >
        <OrganisationManagement onClose={handleCloseDialog} />
      </Dialog>
      <Modal
        open={isProfileDialogOpen}
        onClose={handleCloseProfileDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <>
            <iframe
              src="/account/ProfileSettings"
              width="100%"
              height="300px"
              onLoad={() => setLoadingIframe(false)}
              loading="lazy"
              scrolling="no"
              frameBorder="0"
            ></iframe>
            {loadingIframe ? <p> Loading ...</p> : null}
          </>
        </Box>
      </Modal>
    </>
  );
};

/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
 * only render their children if a user is authenticated or unauthenticated, respectively.
 */
const MainContent = () => {
  const { instance } = useMsal();
  const { state, setState } = useGlobalState();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const isAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes("Tenant.admin");
  const isGlobalAdminTabRequired = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes(
    "GlobalAdmin"
  );
  const isGlobalAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles[0] === "GlobalAdmin";
  // const [isAd, setIsAd] = useState(false);
  // console.log("isAdmin", isAdmin);

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  let homeAccountId = null; // Initialize global accountId (can also be localAccountId or username) used for account lookup later, ideally stored in app state

  // console.log("isAdmin", isAdmin);
  // console.log("account", account);

  // This callback is passed into `acquireTokenPopup` and `acquireTokenRedirect` to handle the interactive auth response
  function handleResponse(resp: any) {
    console.log("in handle res", resp);
    setState({ account: resp.account });
    instance.setActiveAccount(resp.account);
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id_token");
    const domain_hint = queryParams.get("domain_hint");
    const tenant = queryParams.get("tenant");
    const login_hint = queryParams.get("login_hint");
    // console.log("id_token:" + id);
    // setIsAd((account?.idTokenClaims as IdTokenClaims)?.roles?.includes("Tenant.admin"));
    const callbackId = instance.addEventCallback((event: any) => {
      console.log("event", event.eventType);
      if (event.eventType === EventType.LOGIN_FAILURE) {
        console.log("sign in failed:" + event.error.errorMessage);
        if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
          console.log("Forgot password");
        } else {
          console.log("login failed");
        }
      }

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        window.top?.location.reload();
        if (event?.payload) {
          handleResponse(event?.payload);
          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          /*if (event.payload.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword) {
                    window.alert("Password has been reset successfully. \nPlease sign-in with your new password");
                    return instance.logout();
                }*/
        }
      }
      if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        if (event.interactionType === InteractionType.Redirect) {
          handleResponse(event?.payload);
        }
      }
    });
    if (domain_hint || login_hint || tenant) {
      instance.loginRedirect({
        scopes: loginRequest.scopes,
        loginHint: login_hint ?? "",
        extraQueryParameters: {
          domain_hint: domain_hint ?? "",
          tenant: tenant ?? "",
        },
      });
    }

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <AuthenticatedTemplate>
        {/* {isAdmin !== undefined && ( */}
        <LogoutTimer />
        <JwtInterceptor />
        <Routes>
          {isAdmin || isGlobalAdmin ? (
            <>
              <Route path="/" element={<Dashboard />} />
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/DetailledDashboard" element={<DetailledDashboard />} />
              <Route path="/UserManagement" element={<UserManagementWithProviders />} />
              <Route path="/AssetManagement" element={<AssetManager />} />
              <Route path="/WorkflowManager" element={<WorkflowManager />} />
              <Route path="/DetailWorkflowManager" element={<DetailWorkflowManager />} />
              <Route path="/PolicyManager" element={<PolicyManager />} />
              <Route path="/PolicyBuilder" element={<PolicyBuilder />} />
              {/*               <Route path="/account/ProfileSettings" element={<ProfileSettings />} />
               */}{" "}
              {/*<Route path="/Organisation" element={<OrganisationManagement />} />*/}
              {/* <Route path="/SystemSettings" element={<SystemSettings />} /> */}
              <Route path="/get-started" element={<GetStarted />} />
            </>
          ) : (
            <>
              <Route path="/" element={<WorkflowManager />} />
              <Route path="/WorkflowManager" element={<WorkflowManager />} />
              <Route path="/PolicyBuilder" element={<PolicyBuilder />} />
            </>
          )}
          {isGlobalAdminTabRequired && (
            <>
              <Route path="/GlobalAdmin" element={<OrgListProvider />} />
            </>
          )}
        </Routes>
        {/* )} */}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/account/SignUpSignIn" element={<SignUpSignIn />} />
          <Route path="/account/NewTenantSignUp" element={<NewTenantSignUp />} />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />

          <Route path="/get-started" element={<GetStarted />} />
        </Routes>
      </UnauthenticatedTemplate>
    </div>
  );
};
export const SwitchTenant = () => {
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState("");

  useEffect(() => {
    if (accounts.length > 0 && accounts[0].idTokenClaims) {
      const firstTenant = (accounts[0].idTokenClaims as IdTokenClaims).appTenantName.toUpperCase();
      setSelectedTenant(firstTenant.toUpperCase());
    }
  }, [accounts]);

  const handleOpenMenu = (event: { currentTarget: React.SetStateAction<null> }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleTenantChange = (tenant: string) => {
    console.log("tenant", tenant);
    setSelectedTenant(tenant.toUpperCase());
    handleCloseMenu();
    instance.setActiveAccount(null);
    instance.loginRedirect({
      authority: b2cPolicies.authorities.signIn.authority,
      scopes: loginRequest.scopes,
      account: accounts[0],
      redirectUri: deployment.baseUrl + "/loggedin",
      extraQueryParameters: { tenant: encodeURIComponent(tenant) },
    });
  };

  return (
    <div>
      <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography fontSize="16px" color="#1E2429" fontWeight="600">
          {selectedTenant}
        </Typography>
        {(accounts[0].idTokenClaims as IdTokenClaims).allTenants.length > 1 && (
          <DownArrow
            onClick={handleOpenMenu}
            style={{
              cursor: "pointer", // Change cursor to hand on hover
            }}
          />
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {(accounts[0].idTokenClaims as IdTokenClaims).allTenants.length > 1 &&
          (accounts[0].idTokenClaims as IdTokenClaims).allTenants.map((tenant, index) => (
            <MenuItem key={index} onClick={() => handleTenantChange(tenant)}>
              <Box
                sx={{
                  padding: "8px",
                  backgroundColor:
                    selectedTenant === tenant.toUpperCase() ? "#f0f0f0" : "transparent",
                  cursor: "pointer",
                }}
              >
                <Typography variant="subtitle2">{tenant.toUpperCase()}</Typography>
              </Box>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};
/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more,
 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const isSignUpSignInRoute = window.location.pathname === "/account/NewTenantSignUp";
const isInvitationRoute = window.location.pathname === "/Invitation";
export default function App({ msalInstance }: { msalInstance: any }) {
  return (
    <GlobalStateProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <MsalProvider instance={msalInstance}>
            <Routes>
              <Route path="/account/SignUpSignIn" element={<SignUpSignIn />} />
              <Route path="/account/NewTenantSignUp/:questionId" element={<NewTenantSignUp />} />
              <Route path="/Invitation" element={<Invitation />} />
              <Route path="/Invitationloggedin" element={<InvitationLoggedIn />} />

              <Route path="/loggedin" element={<LoggedIn />} />
              <Route path="/logout" element={<Logout />} />
              {/* Wrap PageLayout and MainContent conditionally */}
              {isSignUpSignInRoute || isInvitationRoute ? (
                <Route path="/*" element={<MainContent />} />
              ) : (
                <Route
                  path="/*"
                  element={
                    <>
                      <PageLayout>
                        <MainContent />
                      </PageLayout>
                    </>
                  }
                />
              )}
            </Routes>
          </MsalProvider>
        </ThemeProvider>
      </BrowserRouter>
    </GlobalStateProvider>
  );
}
