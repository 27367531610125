import { Box, Chip, IconButton, Stack, Typography, Menu, MenuItem } from "@mui/material";
import { ReactComponent as Calendar } from "../../assets/calendar_month.svg";
import { SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import { Link } from "react-router-dom";
import { Cell, Label, Legend, Pie, PieChart } from "recharts";

import { ReactComponent as Expand } from "../../assets/expand_content.svg";
import { ReactComponent as Account } from "../../assets/account_circle.svg";
import { ReactComponent as Tab } from "../../assets/tabs.svg";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from "recharts";
import ViewAction from "../Actions/ViewAction";

type PolicyActionEntity = {
  action: string;
  id: string;

  policyId: string;

  tenantId: string;

  code: string;

  title: string;

  description: string;

  importance: string;

  suggestedTimePeriod: Map<number, string>;

  owner: string;
  ownerGivenName: string;
  ownerSurname: string;

  isActive: boolean;

  manager: string;

  status: string;

  frequency: string;

  priority: number;

  comments: string;

  dueDate: Date;

  nextDueDate: Date;

  lastUpdateDate: Date;

  isRecurring: boolean;

  source: string;

  isResolved: boolean;

  costExternal: string;

  staffHours: string;

  dependencies: Array<PolicyActionEntity>;

  attachmentDocIds: string[];

  assignedActioner: string;
  totalOverdueDays: number;
};

// Define interface for monthly status counts
interface MonthlyStatusCounts {
  month: string;
  notStarted: number;
  pending: number;
  inProgress: number;
  completed: number;
  cancelled: number;
  onHold: number;
  overdue: number;
  unassigned: number;
}
const ActionOverview = () => {
  const { instance } = useMsal();
  const accounts = findSigninAccount(instance.getAllAccounts());
  const appTenantId = (accounts[0].idTokenClaims as IdTokenClaims).appTenantId;
  const myUserId = (accounts[0].idTokenClaims as IdTokenClaims).sub;
  const isAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles?.includes("Tenant.admin");
  const isGlobalAdmin = (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles[0] === "GlobalAdmin";
  const [fetchedUpcomingActions, setFetchedUpcomingActions] = useState<PolicyActionEntity[]>([]);
  const [overDueActions, setOverDueActions] = useState<PolicyActionEntity[]>([]);
  const [users, setUsers] = useState<Member[]>([]);
  const [totalAction, setTotalActions] = useState<number>(0);
  const [actionPieChart, setActionPieChart] = useState<any>([]);
  const [displayYear, setDisplayYear] = useState<number>(new Date().getFullYear());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [onboardedDate, setOnboardedDate] = useState<Date>(new Date());
  const handleCalendarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (year: SetStateAction<number>) => {
    setDisplayYear(year);
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const [monthlyStatusCounts, setMonthlyStatusCounts] = useState<MonthlyStatusCounts[]>([]);
  const [selectedRowId, setSelectedRowId] = useState("");
  const handleClose = () => {
    setSelectedRowId(""); // Reset selectedRowId when the EditAction component is closed
  };
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        console.log("app tenant id: " + appTenantId);
        const scoresResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/org/r_scores/${appTenantId}`
        );
        setOnboardedDate(new Date(scoresResponse.data.initialPrincipalRScoreDate));
      } catch (error) {
        console.log(error);
      }
      try {
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/` + appTenantId
        );
        setUsers((prevValue: Member[]) => [...prevValue, ...userResponse.data]);
      } catch (error) {}
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const policyActionsResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/policy/control/actions/${appTenantId}`
        );
        let upcomingPolicyActions: PolicyActionEntity[] = [];
        let overDueAction: PolicyActionEntity[] = [];

        // console.log("policyActionsResponse", policyActionsResponse.data);

        policyActionsResponse.data
          .filter((action: PolicyActionEntity) => action.isActive)
          .forEach((action: PolicyActionEntity) => {
            const currentDate = new Date();
            // const dueDate = new Date(action.dueDate);
            if (
              //  check for upcoming actions and that have owner assigned
              new Date(action.dueDate) <
                new Date(new Date().getFullYear(), new Date().getMonth() + 2, 1) &&
              action.status !== "D" &&
              action.status !== "C" &&
              /*               action.hasOwnProperty("owner") &&
              action.owner !== "" */

              (action.owner === myUserId || action.manager === myUserId)
            ) {
              upcomingPolicyActions.push(action);
            }

            // Define a variable to hold the total number of overdue actions
            let totalOverdueDays = 0;
            // Check for overdue actions

            const dueDate = new Date(action.dueDate);
            if (
              action.dueDate != null &&
              dueDate.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)
            ) {
              // Calculate the number of overdue days
              const overdueDays = Math.ceil(
                (currentDate.getTime() - dueDate.getTime()) / (1000 * 3600 * 24)
              );

              // Update the total number of overdue days
              totalOverdueDays += overdueDays;

              // Add the number of overdue days to the action
              action.totalOverdueDays = overdueDays;

              overDueAction.push(action);
            }
          });

        // action bar chart code

        // Initialize an array to store monthly counts
        //calculate fromDt and toDate . From Date is from 1st of day of onboardedDate month and year
        const fromDt = new Date(onboardedDate.getFullYear(), onboardedDate.getMonth(), 1);
        // To Date is 1st of current date plus 12 months
        const toDt = new Date(new Date().getFullYear(), new Date().getMonth() + 13, 1);
        toDt.setDate(toDt.getDate() - 1);
        console.log("onboardedDate", onboardedDate);
        console.log("fromDt", fromDt);
        console.log("toDt", toDt);
        // Calculate the difference in months between fromDt and toDt
        const diffMonths =
          (toDt.getFullYear() - fromDt.getFullYear()) * 12 + (toDt.getMonth() - fromDt.getMonth());

        const monthlyCounts: MonthlyStatusCounts[] = Array.from(
          { length: diffMonths },
          (_, index) => {
            const monthDate = new Date(fromDt.getFullYear(), fromDt.getMonth() + index);
            const monthName = monthDate.toLocaleString("default", { month: "short" }); // Get month name
            const year = monthDate.getFullYear(); // Get year
            return {
              month: `${monthName}-${year}`, // Concatenate month name and year
              notStarted: 0,
              pending: 0,
              inProgress: 0,
              completed: 0,
              cancelled: 0,
              onHold: 0,
              overdue: 0,
              unassigned: 0,
            };
          }
        );
        console.log("monthlyCounts", monthlyCounts);
        policyActionsResponse.data &&
          policyActionsResponse.data
            .filter((action: PolicyActionEntity) => {
              return action.isActive || action.status === "D" || action.status === "C";
            })
            .forEach(
              (action: {
                lastUpdateDate: string | number | Date;
                dueDate: string | number | Date;
                status: any;
              }) => {
                // Extract the month and year from the action's last update date
                const month = action.dueDate
                  ? new Date(action.dueDate).getMonth()
                  : new Date(action.lastUpdateDate).getMonth();
                const year = action.dueDate
                  ? new Date(action.dueDate).getFullYear()
                  : new Date(action.lastUpdateDate).getFullYear();
                const currentDate = new Date();
                const dueDate = action.dueDate
                  ? new Date(action.dueDate)
                  : new Date(action.lastUpdateDate);
                if (action.dueDate === null) {
                  console.log(
                    "action.dueDate",
                    action.dueDate,
                    "action.lastUpdateDate",
                    action.lastUpdateDate
                  );
                  console.log("dueDate", dueDate);
                }

                // const monthYear = `${year}-${month}`;
                if (
                  dueDate.setHours(0, 0, 0, 0) >= fromDt.setHours(0, 0, 0, 0) &&
                  dueDate.setHours(0, 0, 0, 0) <= toDt.setHours(0, 0, 0, 0)
                ) {
                  const month = dueDate.toLocaleString("default", { month: "short" }); // Get month name
                  const year = dueDate.getFullYear(); // Get year

                  // Construct the target month and year string
                  const targetMonth = `${month}-${year}`;

                  // Find the index of the object in the monthlyCounts array that matches the target month and year
                  const index = monthlyCounts.findIndex((item) => item.month === targetMonth);

                  if (index !== -1) {
                    // If the index is found, increment the corresponding count for the month
                    if (
                      action.dueDate != null &&
                      dueDate.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)
                    ) {
                      monthlyCounts[index].overdue++;
                    } else {
                      switch (action.status) {
                        case "I":
                          monthlyCounts[index].inProgress++;
                          break;
                        case "P":
                        case "U":
                          monthlyCounts[index].unassigned++;
                          break;
                        case "D":
                          monthlyCounts[index].completed++;
                          break;
                        case "C":
                          monthlyCounts[index].cancelled++;
                          break;
                        case "H":
                          monthlyCounts[index].onHold++;
                          break;
                        case "N":
                          monthlyCounts[index].notStarted++;
                          break;
                        default:
                          break;
                      }
                    }
                  }
                }
              }
            );

        // Set the monthly status counts in state
        setMonthlyStatusCounts(monthlyCounts);
        console.log("monthlyCounts", monthlyCounts);

        // action pie chart
        setTotalActions(
          policyActionsResponse.data.filter((action: PolicyActionEntity) => {
            return action.isActive || action.status === "D" || action.status === "C";
          }).length
        );

        let Govern = 0;
        let Protect = 0;
        let Detect = 0;
        let Respond = 0;

        policyActionsResponse.data &&
          policyActionsResponse.data
            .filter((action: PolicyActionEntity) => {
              return action.isActive || action.status === "D" || action.status === "C";
            })
            .forEach((action: { principle: any }) => {
              switch (action.principle) {
                case "G":
                  Govern++;
                  break;
                case "P":
                  Protect++;
                  break;
                case "D":
                  Detect++;
                  break;
                case "R":
                  Respond++;
                  break;
                default:
                  break;
              }
            });

        setActionPieChart([
          { name: "Govern", value: Govern },
          { name: "Protect", value: Protect },
          { name: "Detect", value: Detect },
          { name: "Respond", value: Respond },
        ]);
        console.log("actionPieChart", actionPieChart);
        // console.log("Upcoming actions:", upcomingPolicyActions);
        setFetchedUpcomingActions(upcomingPolicyActions);
        setOverDueActions(overDueAction);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here if needed
      }
    };

    fetchData();
  }, [onboardedDate]);

  function enrichActionsWithUserDetails(users: any[], actions: any) {
    fetchedUpcomingActions.forEach((action) => {
      const user = users.find((user: { userId: string }) => user.userId === action.owner);
      if (user) {
        action.ownerGivenName = user.givenName;
        action.ownerSurname = user.surname;
      }
    });
  }

  useEffect(() => {
    if (users && fetchedUpcomingActions) {
      enrichActionsWithUserDetails(users, fetchedUpcomingActions);
    }
  }, [users, fetchedUpcomingActions]);

  // console.log("fetchedUpcomingActions", fetchedUpcomingActions);

  function formatDate(dateString: string | number | Date) {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-gb", options as Intl.DateTimeFormatOptions);
  }

  const ACTIONCOLORS = ["#233DFF", "#000000", "#49AEEE", "#D7D9DF"];

  return (
    <>
      {/* view year */}
      {/* <Box
        sx={{
          display: "flex",
          gap: "8px",
          alignItems: "center",
          justifyContent: "flex-end",
          marginRight: "20px",
          marginBottom: "10px",
          position: "relative",
          top: "-35px",
          marginLeft: "428px",
        }}
      >
        <Typography fontSize="12px" color="#66696C">
          Year:
        </Typography>
        <Typography fontSize="14px" color="#1E2429">
          {displayYear}
        </Typography>
        <IconButton sx={{ cursor: "pointer", padding: 0 }} onClick={handleCalendarClick}>
          <Calendar />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
          <MenuItem onClick={() => handleMenuItemClick(displayYear - 1)}>
            {displayYear - 1}
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick(displayYear)}>{displayYear}</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick(displayYear + 1)}>
            {displayYear + 1}
          </MenuItem>
        </Menu>
      </Box> */}

      <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
        {/* action overview box */}
        <Box sx={{ display: "flex", flexDirection: "column", width: "75%" }}>
          {/* action graph */}
          <Box className="action-graph-box">
            <ResponsiveContainer width="100%" height={240}>
              <BarChart data={monthlyStatusCounts} margin={{ left: 0 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="month"
                  minTickGap={0}
                  interval={0}
                  tick={{ fontSize: 12, fill: "#333333", fontWeight: 600 }}
                />
                <YAxis
                  domain={[0, 50]}
                  ticks={[0, 25, 50]}
                  tick={{ fontSize: 12, fill: "#333333", fontWeight: 600 }}
                />
                <Tooltip />
                {/* <Legend /> */}
                <Bar
                  dataKey="notStarted"
                  stackId="status"
                  fill="#C2CAFF"
                  barSize={20}
                  name="Not Started"
                />
                {/*<Bar dataKey="pending" stackId="status" fill="#D7D9DF" barSize={20} />*/}
                <Bar
                  dataKey="unassigned"
                  stackId="status"
                  fill="#FF9900"
                  barSize={20}
                  name="Not Assigned"
                />
                <Bar
                  dataKey="inProgress"
                  stackId="status"
                  fill="#49AEEE"
                  barSize={20}
                  name="In Progress"
                />
                <Bar dataKey="completed" stackId="status" fill="#23AF00" barSize={20} name="Done" />
                <Bar
                  dataKey="cancelled"
                  stackId="status"
                  fill="#555555"
                  barSize={20}
                  name="Cancelled"
                />
                <Bar dataKey="onHold" stackId="status" fill="#B9B9B9" barSize={20} name="On Hold" />
                <Bar
                  dataKey="overdue"
                  stackId="status"
                  fill="#FF6666"
                  barSize={20}
                  name="Overdue"
                />
              </BarChart>
            </ResponsiveContainer>
            <Stack direction="row" spacing={3} sx={{ ml: "43px", mt: "10px" }}>
              <Box className="lege-box">
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "#FF9900",
                    marginRight: "7px",
                  }}
                ></div>
                <Typography fontSize="12px" color="#FF9900">
                  Not Assigned
                </Typography>
              </Box>
              <Box className="lege-box">
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "#C2CAFF",
                    marginRight: "7px",
                  }}
                ></div>
                <Typography fontSize="12px" color="#C2CAFF">
                  Not Started
                </Typography>
              </Box>

              <Box className="lege-box">
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "#49AEEE",
                    marginRight: "7px",
                  }}
                ></div>
                <Typography fontSize="12px" color="#49AEEE">
                  In Progress
                </Typography>
              </Box>
              <Box className="lege-box">
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "#23AF00",
                    marginRight: "7px",
                  }}
                ></div>
                <Typography fontSize="12px" color="#23AF00">
                  Done
                </Typography>
              </Box>
              <Box className="lege-box">
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "#555555",
                    marginRight: "7px",
                  }}
                ></div>
                <Typography fontSize="12px" color="#555555">
                  Cancelled
                </Typography>
              </Box>
              <Box className="lege-box">
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "#B9B9B9",
                    marginRight: "7px",
                  }}
                ></div>
                <Typography fontSize="12px" color="#B9B9B9">
                  On Hold
                </Typography>
              </Box>
              <Box className="lege-box">
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "#FF6666",
                    marginRight: "7px",
                  }}
                ></div>
                <Typography fontSize="12px" color="#FF6666">
                  Overdue
                </Typography>
              </Box>
            </Stack>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {/* action category pie chart */}
            <Box className="action-cat-box">
              <Box className="action-title-box">
                <Typography fontSize="14px" fontWeight="600" color="#66696C">
                  Action Category
                </Typography>
                {/* <Expand /> */}
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // paddingTop: "10px",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                  // height: "100%",
                }}
              >
                {totalAction === 0 ? (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PieChart width={260} height={250}>
                      <Pie
                        // data={data02}
                        data={actionPieChart}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={75}
                        outerRadius={80}
                        fill="#82ca9d"
                        paddingAngle={0}
                      >
                        {actionPieChart.map((item: any, index: any) => {
                          // console.log(`Index: ${index}, Item:`, item);
                          return (
                            <Cell
                              key={`cell-${index}`}
                              fill={ACTIONCOLORS[index % ACTIONCOLORS.length]}
                            />
                          );
                        })}
                        <Label
                          value={totalAction}
                          position="center"
                          content={({}) => {
                            return (
                              <text x="40%" y="50%" textAnchor="middle" dominantBaseline="middle">
                                <tspan
                                  x="50%"
                                  dy="-0.5em"
                                  style={{ fontSize: "32px", fontWeight: "500" }}
                                >
                                  0
                                </tspan>
                                <tspan
                                  x="50%"
                                  dy="1.6em"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    fill: "#66696C",
                                    marginTop: "5px",
                                  }}
                                >
                                  Total Actions
                                </tspan>
                              </text>
                            );
                          }}
                        />
                      </Pie>
                    </PieChart>
                  </Box>
                ) : (
                  <>
                    <Box>
                      <PieChart width={260} height={250}>
                        <Pie
                          // data={data02}
                          data={actionPieChart}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          innerRadius={75}
                          outerRadius={80}
                          fill="#82ca9d"
                          paddingAngle={0}
                        >
                          {actionPieChart.map((item: any, index: any) => {
                            // console.log(`Index: ${index}, Item:`, item);
                            return (
                              <Cell
                                key={`cell-${index}`}
                                fill={ACTIONCOLORS[index % ACTIONCOLORS.length]}
                              />
                            );
                          })}
                          <Label
                            value={totalAction}
                            position="center"
                            content={({}) => {
                              return (
                                <text x="40%" y="50%" textAnchor="middle" dominantBaseline="middle">
                                  <tspan
                                    x="50%"
                                    dy="-0.5em"
                                    style={{ fontSize: "32px", fontWeight: "500" }}
                                  >
                                    {totalAction}
                                  </tspan>
                                  <tspan
                                    x="50%"
                                    dy="1.6em"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      fill: "#66696C",
                                      marginTop: "5px",
                                    }}
                                  >
                                    Total Actions
                                  </tspan>
                                </text>
                              );
                            }}
                          />
                        </Pie>
                      </PieChart>
                    </Box>

                    <Stack direction="row" spacing={1}>
                      <Box className="lege-box">
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "#233DFF",
                            marginRight: "7px",
                          }}
                        ></div>
                        <Typography fontSize="12px" color="#66696C">
                          Govern
                        </Typography>
                      </Box>
                      <Box className="lege-box">
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "#000000",
                            marginRight: "7px",
                          }}
                        ></div>
                        <Typography fontSize="12px" color="#66696C">
                          Protect
                        </Typography>
                      </Box>
                      <Box className="lege-box">
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "#49AEEE",
                            marginRight: "7px",
                          }}
                        ></div>
                        <Typography fontSize="12px" color="#66696C">
                          Detect
                        </Typography>
                      </Box>
                      <Box className="lege-box">
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "#D7D9DF",
                            marginRight: "7px",
                          }}
                        ></div>
                        <Typography fontSize="12px" color="#66696C">
                          Respond
                        </Typography>
                      </Box>
                    </Stack>
                  </>
                )}
              </Box>
            </Box>
            {/* your action */}
            <Box className="your-action-box">
              <Box className="action-title-box">
                <Typography fontSize="14px" fontWeight="600" color="#66696C">
                  Your Actions
                </Typography>
                {/* <Expand /> */}
              </Box>
              <Box>
                <Box style={{ height: "275px", overflowY: "hidden" }}>
                  <Box
                    style={{
                      overflowY: "auto",
                      height: "100%",
                      width: "100%",
                      scrollbarWidth: "none",
                    }}
                  >
                    {fetchedUpcomingActions && fetchedUpcomingActions.length > 0 ? (
                      fetchedUpcomingActions.map((item, index) => (
                        <Box
                          key={index}
                          style={{ padding: "10px", borderBottom: "1px solid #EDEDED" }}
                        >
                          <Typography fontSize="12px" color="#66696C" marginBottom="10px">
                            {formatDate(item?.dueDate)}
                          </Typography>
                          <Typography fontSize="14px" color="#1E2429" marginBottom="10px">
                            {item.title}
                          </Typography>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Account />
                              <Typography fontSize="14px" color="#66696C" marginLeft="5px">
                                {item.ownerGivenName} {item.ownerSurname}
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                              onClick={() => setSelectedRowId(item.id)}
                            >
                              <Tab />
                              <Typography
                                fontSize="14px"
                                color="#233DFF"
                                fontWeight="600"
                                marginLeft="5px"
                              >
                                View Details
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Box style={{ padding: "12px", textAlign: "center", marginTop: "35%" }}>
                        No Upcoming Actions
                      </Box>
                    )}
                    {selectedRowId !== "" && (
                      <ViewAction
                        actionId={selectedRowId}
                        isSystemAction={true}
                        onClose={handleClose}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* action overdue */}
        <Box className="action-overdue-box">
          <Box className="action-title-box">
            <Typography fontSize="14px" fontWeight="600" color="#66696C">
              Overdue Actions
            </Typography>
            {/* <Expand /> */}
          </Box>
          <Box
            style={{
              overflowY: "auto",
              height: "100%",
              width: "100%",
              scrollbarWidth: "none",
            }}
          >
            {overDueActions && overDueActions.length > 0 ? (
              overDueActions.map((item, index) => (
                <Box key={index} style={{ padding: "10px", borderBottom: "1px solid #EDEDED" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      color="#66696C"
                      fontWeight="700"
                      marginBottom="10px"
                    >
                      {formatDate(item?.dueDate)}
                    </Typography>
                    <Chip
                      label={`Overdue- ${item.totalOverdueDays} Days`}
                      style={{
                        backgroundColor: "#FF6666",
                        color: "white",
                        // width: "117px",
                        height: "22px",
                        // padding: "3px 5x",
                        fontSize: "12px",
                        fontWeight: "700",
                      }}
                    />
                  </Box>
                  <Typography fontSize="14px" color="#1E2429" marginBottom="10px">
                    {item.title}
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Account />
                      <Typography fontSize="14px" color="#66696C" marginLeft="5px">
                        {item.ownerGivenName} {item.ownerSurname}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      onClick={() => setSelectedRowId(item.id)}
                    >
                      <Tab />
                      <Typography fontSize="14px" color="#233DFF" fontWeight="600" marginLeft="5px">
                        View Details
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Box style={{ padding: "12px", textAlign: "center", marginTop: "35%" }}>
                No Overdue Actions
              </Box>
            )}
            {selectedRowId !== "" && (
              <ViewAction actionId={selectedRowId} isSystemAction={true} onClose={handleClose} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ActionOverview;
