// GetStarted.tsx
import React from "react";
import DynamicForm from "./DynamicForm";
import { Question } from "./DynamicForm"; // Import the Question type
import useFetch from "../../helpers/hooks/useFetch";
import { Divider, Typography } from "@mui/material";

const GetStarted: React.FC = () => {
  /*     const questions: Question[] = [
        { questionid: 'Q1', question: 'Type of Organisation', type: 'dropdown', options: ["Private Company", "Listed Company", "Sole Trader", "Trust", "Partnership", "Other Organisation Type"],
        references: {
          "Private Company": ["Ref1_PrivateCompany", "Ref2_PrivateCompany"],
          "Listed Company": ["Ref1_ListedCompany", "Ref2_ListedCompany"],
          // Add references for other options
        },
        obligations: {
          "Private Company": ["Ob1_PrivateCompany", "Ob2_PrivateCompany"],
          "Listed Company": ["Ob1_ListedCompany", "Ob2_ListedCompany"],
          // Add obligations for other options
        }, },
        {
          questionid: 'Q2',
            question: 'Your role in the Organisation',
            type: 'dropdown',
            options: ["Director","Sole Trader","Corporate Trustee","CEO","Manager","External Advisor"],
            dependsOn: 'Q2',
            conditionalValues: {
              "External Advisor": [
                { questionid: 'Q21', question: 'What type of Advisor are you', type: 'dropdown', options:["Accountant","Broker","Tax Agent","IT Consultant","Other Consultant"] },
                { questionid: 'Q22', question: 'Are you a member of a professional body', type: 'dropdown', options:["CPA","CA","TPA","Financial Advisor","Other"] },
              ]} 
          },
        // Add more questions as needed
      ]; */
  const { loading, error, estate } = useFetch(
    `${process.env.REACT_APP_API_SERVER}/rm/org/onboarding/qa`
  );

  return (
    <div>
      {/* <div style={{ textAlign: "center", marginBottom: "20px", marginTop: "12px" }}>
        <Typography variant="h2" fontWeight="bold">
          Step 1: General Organisation Q&A
        </Typography>
        <Typography variant="body1">
          Identify likely regulatory & legal obligations currently applicable to your organisation.
        </Typography>
        <Divider
          sx={{ marginTop: "15px", marginBottom: "15px", marginLeft: "40px", marginRight: "40px" }}
        />
      </div> */}
      {loading ? <div>Loading...</div> : <DynamicForm originalQuestions={estate || []} />}
    </div>
  );
};

export default GetStarted;
