import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { findSigninAccount } from "../../helpers/hooks/useAccountFinder";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { ReactComponent as Close } from "../../assets/close.svg";

interface EditActionProps {
  action: any;
  type: string;
  onClose: () => void;
}

const ViewAction: React.FC<EditActionProps> = ({ action, type, onClose }) => {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const [open, setOpen] = React.useState(false);
  const { instance } = useMsal();
  const [users, setUsers] = useState<Member[]>([]);
  const accounts = findSigninAccount(instance.getAllAccounts());
  const [values, setValues] = useState([]);
  const [loaded, setLoaded] = useState(false);

  console.log("actionId", action, type);

  useEffect(() => {
    setOpen(true);
    const fetchData = async () => {
      try {
        const appTenantId = (accounts[0]?.idTokenClaims as IdTokenClaims)?.appTenantId;
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/rm/user/getAllUsersForTenant/` + appTenantId
        );
        setUsers(response.data);
        // const response2 = await axios.get(
        //   `${process.env.REACT_APP_API_SERVER}/rm/policy/control/actions/${appTenantId}`
        // );
        // setAllActions(response2.data);

        setLoaded(true);
      } catch (error) {
        // setError("Error fetching data");
      }
    };

    fetchData();
  }, []);

  const handleClose = () => {
    setOpen(false);
    onClose();
    // setSelectedRowId("");
  };

  return (
    <Dialog
      //fullScreen
      fullWidth={fullWidth}
      maxWidth={type === "service" ? "md" : maxWidth}
      // onClose={handleClose}
      open={open}
    >
      {action && type === "system" && (
        <>
          <DialogTitle>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography fontSize="16px" fontWeight="600">
                View System:
              </Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={2}>
              {/* system name */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  gap: "5px",
                }}
              >
                <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                  System Name
                </Typography>
                <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                  {action?.systemName || ""}
                </Typography>
              </Box>
              {/* system summary */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  gap: "5px",
                }}
              >
                <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                  System Summary
                </Typography>
                <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                  {action?.systemSummary || ""}
                </Typography>
              </Box>
              <Stack direction="row" spacing={20} style={{ marginBottom: "10px", padding: "0px" }}>
                {/* system owner */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    System Owner
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {users.find((user) => user.id === action?.systemOwner)?.givenName +
                      " " +
                      users.find((user) => user.id === action?.systemOwner)?.surname || ""}
                  </Typography>
                </Box>
                {/* inclusion date */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Added on
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.inclusionDate || ""}
                  </Typography>
                </Box>
                {/* status */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Status
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.status || ""}
                  </Typography>
                </Box>
                {/*  System Value */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    System Value
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.systemValue || ""}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" spacing={20} style={{ marginBottom: "10px", padding: "0px" }}>
                {/*  systemAvailability */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Availability
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.systemAvailability || ""}
                  </Typography>
                </Box>
                {/* dataConfidentiality*/}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Confidentiality
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.dataConfidentiality || ""}
                  </Typography>
                </Box>
                {/* dataIntegrity */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Integrity
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.dataIntegrity || ""}
                  </Typography>
                </Box>
                {/*  dataLocation */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Data Location
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.dataLocation || ""}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" spacing={20} style={{ marginBottom: "10px", padding: "0px" }}>
                {/*  suppliedVendorName */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Supplier/Vendor Name
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.suppliedVendorName || ""}
                  </Typography>
                </Box>
                {/* suppliedVendorContactPhone*/}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Supplier/Vendor Contact Phone
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.suppliedVendorContactPhone || ""}
                  </Typography>
                </Box>
                {/* suppliedVendorContactEmail */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Supplier/Vendor Contact Email
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.suppliedVendorContactEmail || ""}
                  </Typography>
                </Box>
              </Stack>
              {/*  otherVendorInformation */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  gap: "5px",
                  width: "100%",
                }}
              >
                <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                  Other Vendor Information
                </Typography>
                <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                  {action?.otherVendorInformation.substring(0, 100)}
                </Typography>
              </Box>
            </Stack>
          </DialogContent>
        </>
      )}

      {action && type === "hardware" && (
        <>
          <DialogTitle>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography fontSize="16px" fontWeight="600">
                View Hardware:
              </Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={6.8}>
                {/* Device Type */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Device Type
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.deviceType || ""}
                  </Typography>
                </Box>
                {/* Unique Item id */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Unique Item id
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.uniqueItemId || ""}
                  </Typography>
                </Box>
              </Stack>

              <Stack direction="row" spacing={20} style={{ marginBottom: "10px", padding: "0px" }}>
                {/* Manager */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Manager
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {users.find((user) => user.id === action?.manager)?.givenName +
                      " " +
                      users.find((user) => user.id === action?.manager)?.surname || ""}
                  </Typography>
                </Box>
                {/* assignee*/}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    User
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.assignee || ""}
                  </Typography>
                </Box>
                {/* inclusionDate */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Added on
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.inclusionDate || ""}
                  </Typography>
                </Box>
                {/*  deviceLocation */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Device Location
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.deviceLocation || ""}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" spacing={20} style={{ marginBottom: "10px", padding: "0px" }}>
                {/*  status */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Status
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.status || ""}
                  </Typography>
                </Box>
                {/* statusLastChanged*/}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Last Changed
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action.statusLastChanged
                      ? new Date(action.statusLastChanged).toLocaleDateString()
                      : ""}
                  </Typography>
                </Box>
                {/* suppliedVendorName */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Supplier/Vendor Name
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.suppliedVendorName || ""}
                  </Typography>
                </Box>
                {/*  suppliedVendorContactEmail */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Supplier/Vendor Contact Information
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.suppliedVendorContactEmail || ""}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" spacing={20} style={{ marginBottom: "10px", padding: "0px" }}>
                {/*  decomissionDate */}
                {action?.status === "Decommissioned" && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                        gap: "5px",
                        width: "270px",
                      }}
                    >
                      <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                        Decomission Date
                      </Typography>
                      <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                        {action?.decomissionDate || ""}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                        gap: "5px",
                        width: "270px",
                      }}
                    >
                      <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                        Securely Erased
                      </Typography>
                      <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                        {action?.securelyErased || ""}
                      </Typography>
                    </Box>
                  </>
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Encrypted
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.encrypted || ""}
                  </Typography>
                </Box>

                {/*  protected */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Protected
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.protected || ""}
                  </Typography>
                </Box>
              </Stack>
              {/*  notes */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  gap: "5px",
                  width: "270px",
                }}
              >
                <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                  Other Device Information
                </Typography>
                <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                  {action?.notes || ""}
                </Typography>
              </Box>
            </Stack>
          </DialogContent>
        </>
      )}

      {action && type === "service" && (
        <>
          <DialogTitle>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography fontSize="16px" fontWeight="600">
                View I.T Service Provider :
              </Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={6.8}>
                {/* supplierName */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Supplier Name
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.supplierName || ""}
                  </Typography>
                </Box>
                {/* serviceType */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Service Type
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.serviceType || ""}
                  </Typography>
                </Box>
                {action?.serviceType === "Other" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      gap: "5px",
                      width: "270px",
                    }}
                  >
                    <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                      Other Service Type
                    </Typography>
                    <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                      {action?.otherServiceType || ""}
                    </Typography>
                  </Box>
                )}
              </Stack>
              {/* serviceDetails */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  gap: "5px",
                  width: "270px",
                }}
              >
                <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                  Supplier Service Details
                </Typography>
                <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                  {action?.serviceDetails || ""}
                </Typography>
              </Box>
              <Stack direction="row" spacing={6.8}>
                {/* contactName */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Contact Name
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.contactName || ""}
                  </Typography>
                </Box>
                {/* contactEmail */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Contact Email
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.contactEmail || ""}
                  </Typography>
                </Box>
                {/* contactPhone */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Contact Phone
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.contactPhone || ""}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" spacing={6.8}>
                {/* dateServicesCommenced */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Date Services Commenced
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.dateServicesCommenced || ""}
                  </Typography>
                </Box>
                {/* dateContractSigned */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Date Contract Signed
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.dateContractSigned || ""}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" spacing={6.8}>
                {/* otherVendorInformation */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "5px",
                    width: "270px",
                  }}
                >
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 0.6)" fontWeight="600">
                    Other Vendor Information
                  </Typography>
                  <Typography fontSize="14px" color="rgba(17, 24, 39, 1)" fontWeight="400">
                    {action?.otherVendorInformation.substring(0, 100)}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default ViewAction;
